import React, { useState, useEffect } from "react";
import firebase from 'firebase'
import MapVisits from './MapVisits'
import PacmanLoader from "react-spinners/PacmanLoader";
import moment from "moment";






const store= firebase.firestore()




export default function MapModalDay() {
  const [file, setFile] = useState([]);
  const [nelson, setNelson] = useState([]);
  const [jeffrey, setJeffrey] = useState([]);
  const [yunior, setYunior] = useState([]);
  const [nestor, setNestor] = useState([]);
  const [blanca, setBlanca] = useState([]);
  const [maria, setMaria] = useState([]);
  const [leslie, setLeslie] = useState([]);
  const [genesis, setGenesis] = useState([]);
  const [vielka, setVielka] = useState([]);
  
   const getCheckIns = async()=> {
    const newCheckIns = await store.collection("checkins").get();
  
      setFile(newCheckIns)
    return newCheckIns;
  }

 

  const dayFilter = (x) => {
    return x["date"].substr(0, 10) === moment().format("YYYY-MM-DD") ;
 }

 const nelsonTotals = (arr) => {   
  return arr["rep"]=== "Nelson Zavala" && arr["date"].substr(0,10)===moment().format("YYYY-MM-DD") 
   }
  
  const jeffreyTotals = (arr) => { 
    return arr["rep"]=== "Jeffrey Zavala" && arr["date"].substr(0,10)===moment().format("YYYY-MM-DD")
     }
  const yuniorTotals = (arr) => {
      return arr["rep"]=== "Yunior Alcantara" && arr["date"].substr(0,10)===moment().format("YYYY-MM-DD")
       }
  const nestorTotals = (arr) => {
        return arr["rep"]=== "Nestor Vides"&& arr["date"].substr(0,10)===moment().format("YYYY-MM-DD")
         }
    const blancaTotals = (arr) => {
    return arr["rep"]=== "Blanca Vallejo"&& arr["date"].substr(0,10)===moment().format("YYYY-MM-DD")
           }
    const mariaTotals = (arr) => {
            return arr["rep"]=== "Maria Hernandez"&& arr["date"].substr(0,10)===moment().format("YYYY-MM-DD")
                   }
    const leslieTotals = (arr) => {
                    return arr["rep"]=== "Leslie Vides"&& arr["date"].substr(0,10)===moment().format("YYYY-MM-DD")
                           }               
     const genesisTotals = (arr) => {
      return arr["rep"]=== "Christopher Snowden"&& arr["date"].substr(0,10)===moment().format("YYYY-MM-DD")
            }                             
    const vielkaTotals = (arr) => {
              return arr["rep"]=== "Vielka Fuentes"&& arr["date"].substr(0,10)===moment().format("YYYY-MM-DD")
                    } 









  useEffect(() => {
      getCheckIns().then((snapshot) => {
        const checkIns = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        // setFile(checkIns)
        setFile(checkIns.filter(dayFilter))
        
        setNelson(checkIns.filter(nelsonTotals))
        setJeffrey(checkIns.filter(jeffreyTotals))
        setYunior(checkIns.filter(yuniorTotals))
        setNestor(checkIns.filter(nestorTotals))
        setBlanca(checkIns.filter(blancaTotals))
        setMaria(checkIns.filter(mariaTotals))
        setLeslie(checkIns.filter(leslieTotals))
        setGenesis(checkIns.filter(genesisTotals))
        setVielka(checkIns.filter(vielkaTotals))
      });
  }, []);









{console.log(moment().format("YYYY-MM-DD"))}
  

  return (
   
    <div>


<div style={{
  flexWrap: "wrap",
        display: "flex",
        margin: "auto",
        marginTop: "10px"
      }}>




{nelson.length<=0  ? 
null : 
<div style={{
  marginLeft: "10px",
  fontSize: "20px",
  color: "#0a0091", 
fontFamily: "barlow",
fontWeight: "bold"}}>NZ: { nelson.length }  </div>  }


{jeffrey.length<=0  ? 
null : 
<div style={{
  marginLeft: "10px",
  fontSize: "20px",
  color: "red",
fontFamily: "barlow",
fontWeight: "bold"}}>JZ: {jeffrey.length }  </div> }



{yunior.length<=0  ? 
null : 
<div style={{
  marginLeft: "10px",
  fontSize: "20px",
  color: "purple",
fontFamily: "barlow",
fontWeight: "bold"}}>YA: {yunior.length }  </div>}

{nestor.length<=0  ? 
null : 
<div style={{
  marginLeft: "10px",
  fontSize: "20px",
  color: "#3ba100",
fontFamily: "barlow",
fontWeight: "bold"}}>NV: {nestor.length }  </div>}


{blanca.length<=0  ? 
null : 
<div style={{
  marginLeft: "10px",
  fontSize: "20px",
  color: "#f5954c",
fontFamily: "barlow",
fontWeight: "bold"}}>BV: { blanca.length }  </div>}

{maria.length<=0  ? 
null : 
<div style={{
  marginLeft: "10px",
  fontSize: "20px",
  color: "#65f54c",
fontFamily: "barlow",
fontWeight: "bold"}}>MH: { maria.length }  </div>}

{leslie.length<=0  ? 
null : 
<div style={{
  marginLeft: "10px",
  fontSize: "20px",
  color: "#ec49f5",
fontFamily: "barlow",
fontWeight: "bold"}}>LV: {leslie.length }  </div>}

{genesis.length<=0  ? 
null : 
<div style={{
  marginLeft: "10px",
  fontSize: "20px",
  color: "#11bdb4",
fontFamily: "barlow",
fontWeight: "bold"}}>GG: {genesis.length }  </div>}


{vielka.length<=0  ? 
null : 
<div style={{
  marginLeft: "10px",
  fontSize: "20px",
  color: "#425af5",
fontFamily: "barlow",
fontWeight: "bold"}}>VF: {vielka.length }  </div>}




</div>


{
        

        file.length<=0 || !Array.isArray(file) ? 
        <PacmanLoader style={{color: "#182028"}}/>
        
        : 
       
        <MapVisits visits={file}/>
        
    }




    
    </div>
  );
}
