import React, { useState, useContext, useEffect } from "react";
import productsArray from './productsArray'
import { AppContext } from "./AppContext";
import { useHistory } from "react-router-dom";
import ReactPlayer from 'react-player/youtube'
import DescriptionIcon from '@material-ui/icons/Description';
import { ShakeLittle} from 'reshake'
import Link from "@material-ui/core/Link";
const ProductPage = () => {
 


    
    const [dimesinoW, setDimensionW] = useState(window.innerWidth);
    const langContext = useContext(AppContext);
    const [lang, setLang] = useState(localStorage.getItem("lex"));
    const [titlesLang, setTitlesLang] = useState(0);
    const history = useHistory();
  const [product, setProduct]= useState([])









const filterProducts2 = (x) => {
const noDimensionArray=[]


Object.keys(x).map((item) => {
  
  x[item]["products"].map(  (i) => {
     i.map((item) => {
      //  console.log(item)
       noDimensionArray.push(item)
     })
  })
        })

return noDimensionArray.filter(function(item){
  const x = item.title.toLowerCase().replace(/\s/g, '') 
  // console.log("x", x)
  //  console.log("path", window.location.pathname.replace("/", "").toLowerCase().replace(/\s/g, ''))
return item.title.toLowerCase().replace(/\s/g, '')===window.location.pathname.replace("/", "").toLowerCase().replace(/\s/g, '')

})


 }


console.log(filterProducts2(productsArray)[0])

    const chooseLang = () => {
      if (chooselll(langContext.engEsp)) {
        setTitlesLang(0);
        
      } else {
        setTitlesLang(1);
      }
    };
  
    const chooselll = (x) => {
      if (x === "trueee") {
        return true;
      } else if (x === "falseee");
      {
        return false;
      }
    };





useEffect(() => {
  console.log(langContext.engEsp);
  chooseLang();
  // setPath(window.location.pathname.replace("/", "").toLowerCase().replace(/\s/g, ''))
  const eng= filterProducts2(productsArray)[0]
  const esp =filterProducts2(productsArray)[1]
setProduct([eng, esp])


  // setLang(lang === "falseee" ? "trueee" : "falseee");
}, [langContext.engEsp]);

const updateWidthAndHeight = () => {
  setDimensionW(window.innerWidth);
};

useEffect(() => {
  window.addEventListener("resize", updateWidthAndHeight);
  return () => window.removeEventListener("resize", updateWidthAndHeight);
});





    return ( 


product.length<1 ? null 
: 
<div className="w3-row w3-padding-64" id="about" >
<div className="w3-col m6 w3-padding-large "style={{fontFamily: "Barlow", }} >

 <img src={`${product[titlesLang]["photo"]}`}  
 style={{
   width: window.innerWidth>390 ? 280 : 150,
   transform: window.innerWidth>390 ? "translate(0%)" : "translate(66%)",
  marginLeft: window.innerWidth>390 ? 100 : 0,
  marginTop: window.innerWidth>390 ? 0 : -5

}}alt={product[titlesLang]["photo"]}    />

</div>

<div className="w3-col m6  w3-padding-large-luis" >
  <h1 className="w3-center w3-xxxlarge" style={{fontFamily: "Barlow", color: "#182028" }}>{product[titlesLang].title}</h1>
  {/* <h5 className="w3-center w3-xxlarge" style={{fontFamily: "Barlow", }}>Tradition since 1889</h5> */}
 
  <hr style={{borderColor: "#182028"}}/>
  {product[titlesLang]["description"].map((item) => {
    return <p style={{fontFamily: "Barlow", marginBottom: "-20px" }} className="w3-xlarge">{item}</p>
  })}
  <br/>
  <p className="w3-xlarge  ">
    <h5 className="w3-xxlarge  " style={{fontFamily: "Barlow", color: "#182028"}}>{chooselll(langContext.engEsp)
          ? "Directions"
          : "Instrucciones"}: </h5>
 <ol>
      {product[titlesLang]["bullets"].map(  (item) => {
        return  <li style={{fontFamily: "Barlow", color: "#182028"}}>{item}</li>
      })}

    </ol>
  </p>
  <p 
className="w3-xlarge  " 
style={{fontFamily: "Barlow", color: "#182028"}}
>
  
  {chooselll(langContext.engEsp)
          ? "Presentations"
          : "Presentaciones"}: {" "} 
{product[titlesLang]["presentation"]}</p>
{product[titlesLang]["dataSheet"] ? 
// {console.log(product[titlesLang]["dataSheet"])}
<p 
className="w3-xlarge  " 
style={{fontFamily: "Barlow", color: "#182028",
}}
>
{chooselll(langContext.engEsp)
          ? "Data sheet"
          : "Ficha técnica"}: {" "} 
      <Link target="_blank"
      
      href={product[titlesLang]["dataSheet"]}>

       <ShakeLittle>
            <DescriptionIcon
            style={{   fontSize: 40,
            
         
            color: "#182028",
           
            textDecoration: "none",
            }}
            />
       </ShakeLittle>
           </Link>
</p> : null }


<ReactPlayer url={product[titlesLang]["video"]} controls="true"  width= "320px"  height= "200px"
style={{
marginLeft: "auto",
marginRight: "auto",
}}

/>
</div>



</div>

    );
}

export default ProductPage;
