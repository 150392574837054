import React, {useState} from 'react';
import ModalOneButton from '../src/ModalOneButton';
import PacmanLoader from "react-spinners/PacmanLoader";
const Color = (props) => {




    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(!open)
     }




    return (
        <div className="row" style={{margin: "5px"}}>
            <ModalOneButton open={open} fc1={handleOpen} ruta={props.ruta} />
        <div className="col-sm-6" 
        
 
        >
        
     
  <div  style={{

    color:"#182028",
    fontSize: "30px",
    fontFamily: "Barlow",
    textJustify: "auto",
    top: "500px"

  }} >{


props.name ? props.name.split('.').slice(0, -1).join('.') :  <PacmanLoader style={{color: "#182028"}}/>
  
  } </div>
            <img

           
            src={props.ruta}
            className="card shadow"
            style={{
              width: "16em",
              flexBasis: "15em",

      
    
                WebkitBoxShadow: "2px 2px 2px 0px rgba(0,0,0,0.75)",
                MozBoxShadow: "2px 2px 2px 0px rgba(0,0,0,0.75)",
                BoxShadow: "2px 2px 2px 0px rgba(0,0,0,0.75)",
             
                    
              
            }}
            onClick={handleOpen}
             />  
         
        
</div>
</div>
    );
}

export default Color;