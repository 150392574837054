
import React, { useState, useContext, useEffect } from "react";
import { useSpring, animated } from "react-spring";
import { AppContext } from "../src/AppContext";
import Product from '../src/Product'
import "../src/aboutStylesheet.css"
import ImageFadeIn from "react-image-fade-in"
import productsArray from './productsArray'


import Flip from 'react-reveal/Flip';

const Products = () => {


// debugger
// console.log(productsArray[window.location.pathname.replace("/", "")]["title"])


  const props = useSpring({ opacity: 1, from: { opacity: 0 } });
  const [dimesinoW, setDimensionW] = useState(window.innerWidth);
  const langContext = useContext(AppContext);
  const [titlesLang, setTitlesLang] = useState(0);

  const chooseLang = () => {
    if (chooselll(langContext.engEsp)) {
      setTitlesLang(0);
      // console.log("titles", titles);
    } else {
      setTitlesLang(1);
    }
  };

  const chooselll = (x) => {
    if (x === "trueee") {
      return true;
    } else if (x === "falseee");
    {
      return false;
    }
  };

  useEffect(() => {
    console.log(langContext.engEsp);
    chooseLang();
  
  }, [langContext.engEsp]);

  const updateWidthAndHeight = () => {
    setDimensionW(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  });








  return (
    <div>

<header
        className="w3-display-container w3-content w3-wide"
        style={{
          maxWidth: "3000px",
          // maxHeight: "1000px"
          // zIndex:-10
        }}
        id="home"
      >
   
        <animated.div style={props}>
          <ImageFadeIn
            className="w3-image"
            src={productsArray[window.location.pathname.replace("/", "")]["bg"]}
     
          />
            {/* <img
            className="w3-image"
            src={productsArray[window.location.pathname.replace("/", "")]["bg"]}

            /> */}
          <h1
            style={{
              textAlign: "center",
              position: "absolute",
              top: dimesinoW < 680 ? "40%" : "50%",
              left: "50%",
              fontFamily: "Barlow",
              fontSize: dimesinoW < 750 ? "50px" : "120px",
              color: "white",
              transform: "translate(-50%, -50%)",
              textShadow: "2px 2px 4px black",
             
            }}
          >
            <Flip left>
        {productsArray[window.location.pathname.replace("/", "")]["title"]}
        </Flip>
          </h1>
          </animated.div>
      <div 
      className="w3-content"
      style={{  position: "absolute",
                marginTop: "10px",
                left: "50%",
                transform: "translate(-50%)",  zIndex: "1" }}
          >
            <div>
            
          <div
                style={{
 
                  margin: "auto",

                  display: "inline-flex",
                  justifyContent: "space-around",
                  flexWrap: "wrap",
                }}
              >
   {productsArray[window.location.pathname.replace("/", "")]["products"][titlesLang].map((prod, i)=>{
    return(
      <div  style={{ maxWidth: "1100px" , position: "relative"}}>
      <div className="w3-row padding-recipeCards"  id="about">
    <Product product={prod}  
    productAll={productsArray[window.location.pathname.replace("/", "")]["products"]} 
    index={i}
    dimension={window.location.pathname.replace("/", "")}
    
    
    />
    
    </div>
       </div>
    
    )

          
           
    
    
   
   
   })}
   
              </div>
    </div>
   </div>





   </header>
  
    </div>
  );
}

export default Products;
