const productsArray={

    "professional": {
        title: "Professional",
        bg: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/BG%2Fporbanner.jpg?alt=media&token=35fe7063-a1d2-42cb-be33-774026970798",
        products: [
         //// Ingles:::::
            [    
                 {
            title: "Designer Color",
            description: [
                "Cream hair color.",
                "The most advanced professional cream hair color. Its technology provides direct gray hair coverage.",
                "Its exclusive formula guarantees an excellent performance with more intense, deep and longer-lasting results."
            ],
            bullets:[
                    "Direct gray hair coverage.",
                    "Facilitates the colorist’s work.",
                    "Radiant color with maximum reflection.",
                    "Respects the hair structure."
                ],
        
            presentation:[
                    "90 ml."
                ],
            photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2Fnueva_caja_tinte.png?alt=media&token=12e6a520-bdf6-4a97-9c0c-7f126b4c90be",
            video: "https://www.youtube.com/watch?v=0uazlNlQTWw",
            dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Ftec_italy_productos_ficha_tecnica_DCD_designer_color_infinite_ing.pdf?alt=media&token=b74592b1-d65a-40a7-a02a-2f4d349350f8"

            },

            // {
            //     title: "Colori Pazzi",
            //     description: [
            //         "Fantasy hair color cream. Direct color application.",
            //         "Fantasy hair colors that create modern and vibrating designs. Its pigments ensure higher intensity.",
            //         "We have 20 colors plus the new NEUTRAL tone that words as a diluent to create softer tones."
            //     ],
            //     bullets:[
            //             "The COLORI PAZZI may be intermixed, offering total creative freedom to the hair stylist.",
            //             "PAZZI NEUTRO avoids color contamination, as long as the right technique is being used.",
            //             "Enriched with Keratin, Wheat and Silk amino acids that help restore the hair fiber.",
            //             "After a hair coloring service, you can use COLORI PAZZI to intensify or deepen the tone."
            //         ],
            
            //     presentation:[
            //             "90 ml."
            //         ],
            //     photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2FColori%20Pazzi.webp?alt=media&token=43bd7005-ab27-4301-a34f-8364dafec40b",
            //     video: "https://www.youtube.com/watch?v=rVYAGsEOIM0"    
            //     },


                // {
                //     title: "Estrattore Trattante",
                //     description: [
                //         "Hair color extractor for oxidative colors.",
                //         "Use ESTRATTORE TRATTANTE if the artificial hair color is too dark or simply to remove any artificial hair pigments without altering the natural hair color base.",
                        
                //     ],
                //     bullets:[
                            
                //         "Helps reduce the concentration of oxidative pigments in hair.",
                //         "Does not affect hair’s natural pigmentation.",
                //         "Aids in the application of a color with a different shade.",
                //         "Easy to mix and apply.",
                //         "Does not contain Ammonia, bleach or Formaldehyde."


                //         ],
                
                //     presentation:[
                //             "Kit"
                //         ],
                //     photo: "https://www.tecitaly.com/wp-content/uploads/2017/10/3AD-ESTRATTORE-TRATTANTE-2.png",
                //     video: "https://www.youtube.com/watch?v=60RtFkYCZ1s",
                //     dataSheet: "https://www.tecitaly.com/wp-content/fichas/tec_italy_productos_ficha_tecnica_AD1_estrattore_tratante_ing.pdf"
        
                //     },



                {
                    title: "Serum Plex",
                    description: [
                        "Helps protect the hair strand during lightening as it increases the hair resistance by counteracting the effects of intense chemical processes without stopping levels of lifting.",
                        "For every two caps of bleaching powder add a complete vial of Serum Plex.",
                        
                    ],
                    bullets:[
                            "Pour the contents of the vial over the mixture of powder and peroxide that you prepared for bleaching.",
                            "Dilute until a homogeneous mixture is obtained.",
                            "Apply to hair as you normally would.",
                       
                        ],
                
                    presentation:[
                            "10 ampoules with 10 ml. Each"
                        ],
                    photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2FSerum%20Plex_1.png?alt=media&token=cfe1e6ca-f3b7-47f0-8781-ef613b59c844",
                        video: "https://www.youtube.com/watch?v=OpRhk_cKuh0"
                    },

                    {
                        title: "S3",
                        description: [
                            "Transforms the hair texture giving a smooth result, with shine reducing frizz. Improves the manageability and silkiness of the hair strand.",
                            "Developed with amino acids that provide strength and resistance to the hair structure. It is effective for all hair types: colored, bleached, fine or unruly. Facilitates styling with heat tools.",
                            "In naturally straight hair, a much smoother texture is obtained. On curly hair, it helps smooth waves. Later applications are recommended to gradually relax the texture.",
                            "Deep cleansing shampoo for hair and scalp. Its formula eliminates the accumulation of fats, cosmetic residues, chlorine and minerals from the hair that can interfere with the progressive straightening treatment."
                            
                        ],
                        bullets:[
                                "Wash the hair 3 to 4 times with purifying shampoo (Step 1). For color treated hair 3 times.",
                                "Remove 70% of excess water with a towel.",
                                "Divide the hair and apply smoothing treatment (Step 2) from roots to ends.",
                                "Wrap the hair around the head like a doobie or turban style, cover the head with an aluminum cap, and place underneath the dryer for 1 hour.",
                                "After 1 hour remove the product with plenty of water making sure there is no product left in the hair strand.",
                                " Since the hair has memory, it is essential to blow dry the hair properly as this will dictate how straight the hair will remain after the initial wash. (Do not air dry the hair, as skipping this process will prevent you from achieving ultimate results.)",
                                "Use a ceramic flatiron at 430 degrees.",
                                "Do not wash the hair for 48 hours. Also, don’t tie, clip or fold hair. This will cause indention marks that will affect the resulting hair pattern.",

                           
                            ],
                    
                        presentation:[
                                "1 lt."
                            ],
                        photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2FTEC-ITALY_S3_duo-3.webp?alt=media&token=9592c22d-2a1a-4d2f-b330-0fa812513df4",
                            video: "https://www.youtube.com/watch?v=0oOGnqCFzN4"
                        },

                        {
                            title: "Rivelatore",
                            description: [
                                "Cream activator for hair.",
                                "Color activator that does not lighten hair. It is used to tone both natural and processed hair.",
                                
                                
                            ],
                            bullets:[
                                    "Use non-metallic containers, plastic brushes and protective gloves.",
                                    "Mix using the following hair color instructions.",
                                    "Designer color semi-permanent: mix using a 1:1 1/2 ratio (60 ml of color + 90 ml of activator).",
                                    ],
                        
                            presentation:[
                                    "1 lt."
                                ],
                            photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F3AD-RIVELATORE-339x700.webp?alt=media&token=c18798f4-b7b2-412d-be9c-1915b8e6347e",
                               video: "https://www.youtube.com/watch?v=-ECvWJcBIzc&t" 
                            },

                            // {
                            //     title: "Clear 000",
                            //     description: [
                            //         "Semi-permanent shine treatment. Its formula is enriched with silk amino acids.",
                            //         "Gives shine and silkiness, improving hair appearance.",
                            //         "Designed to complement in-salon services.",
                            //         "It can be used for shine treatments or with semi-permanent hair color to lighten any shade."
                                    
                            //     ],
                            //     bullets:[
                            //             "Mix 20 ml of CLEAR 000 + 80 ml of RIVELATORE.",
                            //             "Apply on clean damp hair, from mid-lengths to ends.",
                            //             "Process for 5 minutes and rinse.",

                            //             ],
                            
                            //     presentation:[
                            //             "300 ml."
                            //         ],
                            //     photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F3AD-CLEAR-000.webp?alt=media&token=f38cbb47-ad87-4331-8303-8a8ff438688e",
                            //         video: "https://www.youtube.com/watch?v=-ECvWJcBIzc&t",
                            //         dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Ftec_italy_productos_ficha_tecnica_clear000_ing.pdf?alt=media&token=5561206f-9e10-4940-b6af-ce7933153d08"
                            //     },

                                {
                                    title: "A.O.E Peroxide",
                                    description: [
                                        "Specially formulated to be used alongside TEC ITALY products.",
                                        "Vol. 10, 20, 30 y 40",
        
                                 
                                        
                                        
                                    ],
                                    bullets:[
                                            "Use non-metallic containers, plastic brushes and protective gloves.",
                                            "Mix following the hair color or lightener instructions.",
                                            "Designer color infinite:  mix using a 1:1½ ratio (90 ml of color + 135 ml of peroxide).",
                                            "Luminous:  mix using a 1:3 ratio (30 g of lightener + 90 ml of peroxide). The process time will depend on the desired result and the condition of the hair."

                                            
                                            ],
                                
                                    presentation:[
                                            "1 lt."
                                        ],
                                    photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F3AD-A.O.E.webp?alt=media&token=a94694a7-a4c5-45ec-ac2e-445a88a6b5cd"
                        
                                    },

                                    {
                                        title: "Luminous",
                                        description: [
                                            "Microencapsulated lightening powder for hair. Provides efficient hair lightening, it’s easy to use and non-volatile.",

                                            "Its violet pigments help neutralize yellow reflects. Enriched with TRICOSIL, silicon based complex that provides softness to hair.",
                                            "It can reach up to 6 levels of lift in natural hair with the right choice of peroxide."
            
                                     
                                            
                                            
                                        ],
                                        bullets:[
                                                "Use non-metallic container, plastic brushes and protective gloves.",
                                                "Mix 30 g of LUMINOUS with 90 ml of A.O.E. Cream Developer.",
                                                "Obtain a homogenous cream.",
                                                "The process time depends on the desired result and the condition of the hair."
    
                                                
                                                ],
                                    
                                        presentation:[
                                                "50 gr. / 350 gr. / 680 gr."
                                            ],
                                        photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2Fluminous%202021.png?alt=media&token=af075aea-dc74-4c00-978a-fe4a3d05ddc6",
                                        dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Ftec_italy_productos_ficha_tecnica_luminous_ing.pdf?alt=media&token=cf0d938f-cc70-4d3b-95f1-d8e19111d471" 

                                        },
                                        {
                                            title: "Shampoo Profondo",
                                            description: [
                                                "Deep cleansing shampoo for hair.",
    
                                                "Helps eliminate oil build-up, cosmetic residue, chlorine and other minerals from hair.",
        
                                                "We recommend using it before a salon service or after lightening hair.",
                                                "Enriched with TRICONE and TRICOERBA, protein and botanical compounds that help improve hair’s appearance."
                
                                         
                                                
                                                
                                            ],
                                            bullets:[
                                                    "Apply a small amount, massaging head lightly.",
                                                    "Rinse.",
                                                     ],
                                        
                                            presentation:[
                                                    "10 ml. / 300 ml. / 1lt. / 2 lt."
                                                ],
                                            photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F3AD-SHAMPOO-PROFONDO.webp?alt=media&token=9643cd42-ab61-439b-a3fe-8a6f8854d267",
                                            dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Ftec_italy_productos_ficha_tecnica_shampoo_profondo_ing.pdf?alt=media&token=9756e2f4-0ecb-4563-815d-46c19517c32c"
                                
                                            },

                                            {
                                                title: "Proteggi",
                                                description: [
                                                    "Complex hair protector protein for chemical processes.",
                                                    "An advanced hair protector that works as an additive in chemical processes.",
                                                    "PROTEGGI helps protect the hair strand and scalp from peroxide, bleach, dye and other chemicals, minimizing itchiness on sensitive scalp and hair damage.",
                                                    "Enriched with TRICONE and TRICOERBA, protein and botanical complexes that help improve the appearance of hair.",
                                                    "Contains TRICEL-R, a complex that protects hair against UV rays."
                                                    
                                                    
                                                ],
                                                bullets:[
                                                                                        
                                                "Before applying with dye or bleach, distribute a PROTEGGI vial evenly.",
                                                "Proceed with the application.",
                                                 "At the end it is recommended to rinse the dye with POST COLOR SHAMPOO and the bleach with SHAMPOO PROFONDO.",
                                                "Apply DUE FACCETTA MASSIMO or DUE FACCETTA LUNGA DURATA depending on the process performed."
                                    
                                                         ],
                                            
                                                presentation:[
                                                        "12 ampoules 10 ml. each"
                                                    ],
                                                photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F5BD-PROTEGGI-AMPOLLETAS.png?alt=media&token=90c98748-52d8-4c1a-8fdd-e91a9793b271",
                                                    dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Ftec_italy_productos_ficha_tecnica_CD1_ampolletas_proteggi_ing.pdf?alt=media&token=ba7f2900-f529-41b6-b713-e572ffe4298f"
                                                },
        
    





    ],
    
    
    



    //Espanol::::::
    
    [   
        
        {
        title: "Designer Color",
        description: [
            "Tinte para el cabello. La coloración profesional más avanzada.",
            "Su tecnología brinda cobertura directa de cana.",
            "Su exclusiva fórmula garantiza un excelente desempeño con resultados de mayor intensidad, profundidad y larga duración."
        ],
        bullets:[
                "Cobertura directa de cana.",
                "Facilita el trabajo del colorista.",
                "Color radiante de máxima reflexión.",
                "Respeta la estructura capilar."
            ],
    
        presentation:[
                "90 ml."
            ],
        photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2Fnueva_caja_tinte.png?alt=media&token=12e6a520-bdf6-4a97-9c0c-7f126b4c90be",
        video: "https://www.youtube.com/watch?v=Y_Xg8Dz-A5w",
        dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_DCD_designer_color_infinite_esp.pdf?alt=media&token=6ad5924e-be2a-4da9-840b-2522799a3b04"


        },

        // {
        //     title: "Colori Pazzi",
        //     description: [
        //         "Tinte de fantasía para el cabello.",
        //         "Coloración de aplicación directa.",
        //         "Colores de fantasía para crear diseños mordernos y vibrantes. Sus pigmentos aseguran un resultado de mayor intensidad.",
        //         "Ofrece una gama de 20 colores más el nuevo tono NEUTRO que funciona como diluyente para crear tonalidades más tenues."
        //     ],
        //     bullets:[
        //             "Los COLORI PAZZI pueden ser mezclados entre sí, ofreciendo total libertad creativa al colorista.",
        //             "PAZZI NEUTRO evita la contaminación de colores, siempre que se use la técnica adecuada.",
        //             "Enriquecido con aminoácidos de Queratina, Trigo y Seda, que ayudan a restaurar la hebra capilar.",
        //             "Después de un servicio de color, puede usar COLORI PAZZI para intensificar o profundizar el tono."
        //         ],
        
        //     presentation:[
        //             "90 ml."
        //         ],
        //     photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2FColori%20Pazzi.webp?alt=media&token=43bd7005-ab27-4301-a34f-8364dafec40b",
        //     video: "https://www.youtube.com/watch?v=lXjFG6n7irg",
        //     dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_DCD_designer_color_pazzi_esp.pdf?alt=media&token=ba85f39c-4e73-4fff-8bfd-a35457c99ef8"

        //     },

            // {
            //     title: "Estrattore Trattante",
            //     description: [
            //         "Extractor de color para tintes de oxidación para el cabello.",
            //         "Utilice ESTRATTORE TRATTANTE si la coloración del cabello es demasiado oscura o simplemente desea eliminar cualquier pigmento artificial de cabello sin dañar el pigmento natural.",
                    
            //     ],
            //     bullets:[
                        
            //         "Ayuda a reducir la concentración de pigmentos de oxidación en el cabello.",
            //         "No afecta la pigmentación natural del cabello.",
            //         "Facilita la aplicación de tono con un reflejo diferente.",
            //         "Fórmula fácil de mezclar y aplicar.",
            //         "No contiene Amoníaco, decolorante, ni Formaldehído."


            //         ],
            
            //     presentation:[
            //             "Kit"
            //         ],
            //     photo: "https://www.tecitaly.com/wp-content/uploads/2017/10/3AD-ESTRATTORE-TRATTANTE-2.png",
            //     video: "https://www.youtube.com/watch?v=_yT9Om34O2k",
            //     dataSheet: "https://www.tecitaly.com.mx/wp-content/fichas/tec_italy_productos_ficha_tecnica_AD1_estrattore_tratante_esp.pdf"
    
            //     },







            {
                title: "Serum Plex",
                description: [
                    "Serum protector en procesos de decoloración.",
                    "SERUM PLEX  ayuda a proteger la hebra capilar durante la decoloración y aumenta la resistencia del cabello contrarrestando los efectos de los proceso químico intensos sin detener los niveles de aclaración.",
                    
                ],
                bullets:[
                        "Verter el contenido de la ampolleta sobre la mezcla de polvo y peróxido que preparó para la aclaración.",
                        "Diluir hasta obtener una mezcla homogénea.",
                        "Aplicar sobre el cabello como normalmente lo haría.",
                   
                    ],
            
                presentation:[
                        "10 ampolletas 10 ml. c/u"
                    ],
                photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2FSerum%20Plex_1.png?alt=media&token=cfe1e6ca-f3b7-47f0-8781-ef613b59c844",
                video: "https://www.youtube.com/watch?v=s-nh8nGQBo8",
                dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_serum-plex_esp.pdf?alt=media&token=5fe4e056-741f-47f7-9f2d-2e31ea622caf"
                    
                },

                {
                    title: "S3",
                    description: [
                        "Transforma la textura del cabello dando un resultado suave, con brillo que reduce el encrespamiento. Mejora la manejabilidad y la sedosidad del cabello.",
                        "Desarrollado con aminoácidos que proporcionan fuerza y resistencia a la estructura del cabello. Es efectivo para todo tipo de cabello: teñido, decolorado, fino o rebelde. Facilita el peinado con herramientas de calor.",
                        "En el cabello naturalmente liso, se obtiene una textura mucho más suave",
                        "En el cabello rizado, ayuda a suavizar las ondas. Se recomiendan aplicaciones posteriores para relajar gradualmente la textura.",
                        "Champú de limpieza profunda para cabello y cuero cabelludo. Su fórmula elimina la acumulación de grasas, residuos cosméticos, cloro y minerales del cabello que pueden interferir con el tratamiento de alisado progresivo."
                        
                    ],
                    bullets:[
                            "Lave el cabello de 3 a 4 veces con champú purificante (Paso 1). Para cabello teñido 3 veces.",
                            "Retire el 70% del exceso de agua con una toalla.",
                            "Divida el cabello y aplique el tratamiento alisador (Paso 2) desde la raíz hasta las puntas.",
                             "Envuelva el cabello alrededor de la cabeza como un peinado o un turbante, cubra la cabeza con un gorro de aluminio y colóquelo debajo de la secadora durante 1 hora.",                            " Since the hair has memory, it is essential to blow dry the hair properly as this will dictate how straight the hair will remain after the initial wash. (Do not air dry the hair, as skipping this process will prevent you from achieving ultimate results.)",
                            "Utilice una plancha de cerámica a 430 grados",
                            "No lave el cabello durante 48 horas. Además, no ate, corte o doble el cabello. Esto causará marcas de sangría que afectarán el patrón de cabello resultante",

                       
                        ],
                
                    presentation:[
                            "1 lt."
                        ],
                    photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2FTEC-ITALY_S3_duo-3.webp?alt=media&token=9592c22d-2a1a-4d2f-b330-0fa812513df4",
                    video: "https://www.youtube.com/watch?v=IoVXspY-0kY&t=3s",
                    dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_s3_esp.pdf?alt=media&token=97f3d5ea-55e8-4de0-98c0-b5dbac868f0e"
        
                    },

                    {
                        title: "Rivelatore",
                        description: [
                            "Activador en crema para el cabello.",
                            "Activador de color sin poder de aclaración. Se utiliza para tonalizar tanto cabello natural, como cabello procesado.",
                            
                            
                        ],
                        bullets:[
                                "Utilice recipientes no metálicos, brochas plásticas y guantes protectores.",
                                "Mezcle siguiendo las instrucciones del tinte. Designer color semi-permanente: mezcle utilizando una fórmula 1+1½ (60 ml de color + 90 ml de activador).",
                                "Designer color infinite: mezcle utilizando una fórmula 1+1½ (90 ml de color + 135 ml de activador). El tiempo de proceso dependerá del resultado deseado y de la condición del cabello.",
                                ],
                    
                        presentation:[
                                "1 lt."
                            ],
                        photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F3AD-RIVELATORE-339x700.webp?alt=media&token=c18798f4-b7b2-412d-be9c-1915b8e6347e",
                        video: "https://www.youtube.com/watch?v=vRcBjkoNZAY",
                        dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_AD1_rivelatore_esp.pdf?alt=media&token=c75e5e9e-7a41-4d8f-9727-4d58c7cb3150"
                        },

                        // {
                        //     title: "Clear 000",
                        //     description: [
                        //         "Tratamiento de brillo semi-permanente. Fórmula enriquecida con aminoácidos de seda.",
                        //         "Aporta brillo y sedosidad, mejorando la apariencia del cabello.",

                        //         "Diseñado para complementar los servicios en salón; puede usarse para dar un servicio de brillo o con coloraciones semi-permanentes como diluyente para reducir la intensidad de los tonos.",
                                
                                
                        //     ],
                        //     bullets:[
                        //             "Mezclar 20 ml de CLEAR 000 + 80 ml de RIVELATORE.",
                        //             "Aplicar sobre cabello limpio y húmedo, de medios a puntas.",
                        //             "Deje actuar por 5 minutos y enjuague.",
                        //             ],
                        
                        //     presentation:[
                        //             "300 ml."
                        //         ],
                        //     photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F3AD-CLEAR-000.webp?alt=media&token=f38cbb47-ad87-4331-8303-8a8ff438688e",
                        //     dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_AD1_clear000_esp.pdf?alt=media&token=646aad3d-75fa-4100-b230-68e5da073edb"

                
                        //     },

                            {
                                title: "A.O.E Peroxide",
                                description: [
                                    "Especialmente formulado para su uso con los productos de la familia TEC ITALY.",
                                    "Vol. 10, 20, 30 y 40",
    
                             
                                    
                                    
                                ],
                                bullets:[
                                        "Utilice recipientes no metálicos, brochas plásticas y guantes protectores.",
                                        "Mezcle siguiendo las instrucciones del tinte o decolorante.",
                                        "Designer color infinite: mezcle utilizando una fórmula 1+1½ (90 ml de color + 135 ml de peróxido).",
                                        "Luminous:  mezcle utilizando una fórmula 1+3 (30 g de decolorante + 90 ml de peróxido). El tiempo de proceso dependerá del resultado deseado y de la condición del cabello."
                                        ],
                            
                                presentation:[
                                        "1 lt."
                                    ],
                                photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F3AD-A.O.E.webp?alt=media&token=a94694a7-a4c5-45ec-ac2e-445a88a6b5cd",
                                dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_AD1_aoe_esp.pdf?alt=media&token=1fdb355f-34ac-43ea-ace8-0115e13fb04d"
                    
                                },

                                {
                                    title: "Luminous",
                                    description: [
                                        "Polvo decolorante microencapsulado para el cabello.",

                                        "Brinda una decoloración eficaz, es fácil de usar y no es volátil.",
                                        "Sus pigmentos violetas ayudan a neutralizar los reflejos amarillos."
        
                                 
                                        
                                        
                                    ],
                                    bullets:[
                                            "Utilice recipientes no metálicos, brochas plásticas y guantes protectores.",
                                            "Mezcle con movimientos suaves una medida de 30 g de LUMINOUS más 90 ml de A.O.E. Peróxido en crema.",
                                            "Debe obtener una crema homogénea.",
                                            "El tiempo de proceso dependerá del resultado deseado y de la condición del cabello."

                                            
                                            ],
                                
                                    presentation:[
                                            "50 gr. / 350 gr. / 680 gr."
                                        ],
                                    photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2Fluminous%202021.png?alt=media&token=af075aea-dc74-4c00-978a-fe4a3d05ddc6",
                                    video: "https://www.youtube.com/watch?v=emaTIExx-js",
                                        dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_AD1_luminous_esp.pdf?alt=media&token=939a2dac-9155-4aae-b704-644929b2ef8f"
                                },

                                    {
                                        title: "Shampoo Profondo",
                                        description: [
                                            "Shampoo de limpieza profunda para el cabello.",

                                            "Ayuda a eliminar la acumulación de grasa, residuos cosméticos, cloro y minerales del cabello.",
    
                                            "Se recomienda usarlo antes de aplicar un tratamiento o después de una decoloración.",
                                            "Enriquecido con TRICONE y TRICOERBA, complejos proteínicos y botánicos que ayudan a mejorar la apariencia del cabello."
            
                                     
                                            
                                            
                                        ],
                                        bullets:[
                                                "Aplique una pequeña cantidad dando un ligero masaje.",
                                                "Enjuague.",
                                                 ],
                                    
                                        presentation:[
                                                "10 ml. / 300 ml. / 1lt. / 2 lt."
                                            ],
                                        photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F3AD-SHAMPOO-PROFONDO.webp?alt=media&token=9643cd42-ab61-439b-a3fe-8a6f8854d267",
                                        dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_AD1_shampoo_profondo_esp.pdf?alt=media&token=1ee30a72-a93f-4a66-a3f6-b4e658a86135"

                            
                                        },

                                        {
                                            title: "Proteggi",
                                            description: [
                                             "Protector de cabello con complejo proteínico para procesos químicos.",
                                             "Un avanzado protector del cabello que funciona como aditivo en los procesos químicos.",
                                             "PROTEGGI ayuda a proteger la hebra capilar y cuero cabelludo del peróxido, decolorante, tinte y otros productos químicos, minimizando el escozor en el cuero cabelludo sensible y el daño en el cabello.",
                                             "Enriquecido con TRICONE y TRICOERBA, complejos proteínicos y botánicos que ayudan a mejorar la apariencia del cabello.",
                                             "Contiene TRICEL-R, complejo que protege el cabello contra los rayos UV."
                                         
                                                
                                                
                                            ],
                                            bullets:[
                                                "Antes de aplicar con tinte o decolorante, distribuya una ampolleta PROTEGGI uniformemente.",
                                                "Proceda con la aplicación.",
                                                "Al finalizar se recomienda enjuagar el tinte con POST COLOR SHAMPOO y el decolorante con SHAMPOO PROFONDO.",
                                                "Aplique DUE FACCETTA MASSIMO o DUE FACCETTA LUNGA DURATA dependiendo del proceso realizado."
                                
                                
                                                     ],
                                        
                                            presentation:[
                                                    "12 ampolletas 10 ml. c/u"
                                                ],
                                            photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F5BD-PROTEGGI-AMPOLLETAS.png?alt=media&token=90c98748-52d8-4c1a-8fdd-e91a9793b271",
                                            video: "https://www.youtube.com/watch?v=QzCx8V37iD8",
                                            dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_CD1_ampolletas_proteggi_esp.pdf?alt=media&token=88f8c062-9f41-4dd4-9c78-832d3c1b2f80"
                            
                                            },
                            
    





        ]







        ]
    },


    "omnirestore": {
        title: "Omni Restore",
        bg: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/BG%2FOmni%20restore%20banner%20inside.jpg?alt=media&token=4dd0171b-7073-46bb-b4fc-c899fe67dd7f",
        products: [
         //// Ingles:::::
            [    

                {
                    title: "Omni Restore Shampoo",
                    description: [
                        "NEW TECHNOLOGY SHAMPOO THAT CLEANS, PROTECTS AND STARTS THE MAINTENANCE AND RESTORATION PROCESS FOR HAIR.",
                        "Its exclusive technology, developed free of SLS / SLES* provides hair with integral cleaning and protection, preparing it for the restoration process.",
                        "Its innovative formula incorporates a selection of multi-purpose ingredients, quinoa, moringa oil, vitamin E , l-arginine and wheat Protein, which help to protect and restore hair strand structure before hair color processes OMNI RESTORE contains ingredients that promote instant, intensive restoration that results in shiny, resistant, and soft hair from the first application."

                    ],
                    bullets:[
                            "On moist hair, place a small amount on the palm of your hand, giving a light massage, let it work for 1 to 2 min.",
                            "Rinse and continue with OMNI RESTORE CONDITIONER.",
                            "Daily use is recommended for optimal results.",       
                        ],
                    presentation:[
                            "300 ml."
                        ],
                    photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2Fomni-restore-shampoo.png?alt=media&token=f8d14cea-ba27-48ae-bc02-03e06a2cdb01",
                    video: "https://www.youtube.com/watch?v=FAn8E45y0IM",
                    dataSheet: "https://www.tecitaly.com/wp-content/uploads/2021/09/tec_italy_productos_ficha_tecnica_SH_OMIRESTORE_ING.pdf"
                    },

                    {
                        title: "Omni Restore Conditioner",
                        description: [
                            "CONDITIONER THAT PROTECTS AND PROMOTESTHE RESISTANCE AND RESTORATIONOF HAIR. THANKS TO ITS SUNSCREENS, PROLONGS HAIR COLOR LIFE.",
                            "Its exclusive technology conditions and provides hair integral protection, preparing it for the restoration process. Its innovative formula incorporates a selection of multi-purpose ingredients, quinoa, moringa oil, vitamin E, and l-arginine, which help to protect and restore hair strand structure before hair color processes.",
                            "OMNI RESTORE contains ingredients that condition and promote intensive restoration, resulting in shiny, resistant, and soft hair from the first application."
                        ],
                        bullets:[
                                "Place a small amount on the palm of your hand and apply evenly on hair from the middle to the ends, let it work for 1 to 2 min.",
                                "Then rinse.",
                                "Daily use."
                            ],
                        presentation:[
                                "300 ml."
                            ],
                        photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2Fomni-restore-conditioner.png?alt=media&token=5ee0a5be-567f-4a28-bcf2-d79bafae2293",
                            video: "https://www.youtube.com/watch?v=FAn8E45y0IM",
                            dataSheet: "https://www.tecitaly.com/wp-content/uploads/2021/09/tec_italy_productos_ficha_tecnica_CON_OMIRESTORE_ING_.pdf"
                        },



                        {
                            title: "Omni Restore Protector",
                            description: [
                               "HAIR PROTECTOR. REDUCES STATIC, MAKES STYLING EASIER, CONDITIONS AND HELPS TO RESIST THE STYLING PROCESS.",
                               "THANKS TO ITS SUNSCREENS, PROLONGS HAIR COLOR LIFE.",
                               "Its exclusive technology provides hair with integral protection.",
                               "It contains ingredients with antioxidant properties and UV protection.",
                               "It is ideal for use with heat tools, to reduce frizz, and seal ends.",
                               "OMNI RESTORE contains ingredients that promote intensive PROTECTION, resulting in shiny, resistant, soft hair, which untangles, from the first application."
            
                            ],
                            bullets:[
                                "LEAVE-IN Protector.",
                                "Apply a small amount on moist hair, distribute from the middle to the ends.",
                                "It may be used with heat tools.",
                                "No need to rinse."
                                 
                                ],
                            presentation:[
                                    "125 ml."
                                ],
                            photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2Fomni-restore-protector.png?alt=media&token=79359f04-0f62-4a47-9293-9bc0ba1d9c3a",
                                video: "https://www.youtube.com/watch?v=FAn8E45y0IM",
                                dataSheet: "https://www.tecitaly.com/wp-content/uploads/2021/09/tec_italy_productos_ficha_tecnica_PROTECTOR_OMIRESTORE_ING.pdf"
                            },

                            {
                                title: "Omni Restore Treatment",
                                description: [
                                   "RECOVER SHINE AND SILKINESS, CONDITIONS, REDUSES STATIC AND PROMOTES HYDRATION.",
                                   "THANKS TO ITS SUNSCREENS, PROLONGS HAIR COLOR LIFE.",
                                   "Its exclusive technology promotes integral hair protection and restoration.",
                                   "Its innovative formula incorporates wheat Protein, a selection of multi-purpose ingredients, quinoa, moringa oil, vitamin E , l-arginine, which protect and restore the structure of the hair strand before hair color processes.",
                                   "OMNI RESTORE contains ingredients that promote intensive restoration, resulting in shiny, resistant, and soft hair from the first application."
                
                                ],
                                bullets:[
                                    "On moist hair, apply enough on the palm of your hand and distribute from the middle to the ends, let the product work for 2 to 5 min.",
                                    "Then rinse.",
                                    "The use OMNI RESTORE TREATMENT.",
                                    "For best results on very processed hair, it is recommended to use it twice a week.",
                                    "When hair has improved, it is recommended to use it once a week."
                                    ],
                                presentation:[
                                        "280 gr."
                                    ],
                                photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2Fomni-restore-treatment.png?alt=media&token=51ec6596-1396-4280-9e9b-e815f88c87b2",
                                    video: "https://www.youtube.com/watch?v=FAn8E45y0IM",
                                    dataSheet: "https://www.tecitaly.com/wp-content/uploads/2021/09/tec_italy_productos_ficha_tecnica_TREAT_OMIRESTORE_ING_.pdf"
                                },
            

    ],

    //Espanol::::::  
    [   
        {
        title: "Omni Restore Shampoo",
        description: [
            "SHAMPOO DE NUEVA TECNOLOGÍA QUE LIMPIA, PROTEGE Y COMIENZA EL PROCESO DE MANTENIMIENTO Y RESTAURACIÓN DEL CABELLO.",
            "Su exclusiva tecnología desarrollada libre de SLS / SLES* brinda una limpieza y protección integral al cabello, preparándolo para el proceso de restauración.",
            "Su innovadora fórmula integra Proteína de trigo y una selección de ingredientes multipropósito Quinoa, Aceite de Moringa, Vitamina E y L-Arginina que ayudan a proteger y restaurar la estructura de la hebra capilar aún antes de recibir el proceso de coloración.",
            "OMNI RESTORE contiene los ingredientes que promueven una restauración intensiva instantánea, obteniendo como resultado un cabello con brillo, resistencia y suavidad desde la primera aplicación."
        ],
        bullets:[
               "Sobre cabello húmedo, aplique una pequeña cantidad en la palma de su mano dando un ligero masaje deje actuar por 1 a 2 min.",
               "Enjuague y continúe con OMNI RESTORE CONDITIONER.",
               "Se recomienda el uso diario para óptimos resultados."
            ],
    
        presentation:[
                "300 ml."
            ],
        photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2Fomni-restore-shampoo.png?alt=media&token=f8d14cea-ba27-48ae-bc02-03e06a2cdb01",
        video: "https://www.youtube.com/watch?v=FAn8E45y0IM",
        dataSheet: "https://www.tecitaly.com.mx/wp-content/uploads/2021/09/ficha-tecnica-shampoo-omni-restore.pdf"
        },

        {
            title: "Omni Restore Conditioner",
            description: [
                "ACONDICIONADOR QUE PROTEGE Y PROMUEVE LA RESISTENCIA Y RESTAURACIÓN PARA EL CABELLO.",
                "GRACIAS A SUS PROTECTORES SOLARES PROLONGA LA VIDA DEL COLOR POR MÁS TIEMPO.",
                "Su exclusiva tecnología acondiciona y brinda una protección integral al cabello, preparándolo para el proceso de restauración.",
                "Su innovadora fórmula integra una selección de ingredientes multipropósito Quinoa, Aceite de Moringa, Vitamina E y L-Arginina que ayudan a proteger y restaurar la estructura de la hebra capilar aún antes de recibir el proceso de coloración OMNI RESTORE contiene ingredientes que acondicionan y promueven una restauración intensiva, obteniendo como resultado un cabello con brillo, resistencia y suavidad desde la primera aplicación."
            ],
            bullets:[
                 "Sólo uso externo.",
                 "Mantener fuera del alcance de los niños.",
                 "Evite el contacto con los ojos. En caso de contacto con los ojos lave inmediatamente con abundante agua.",
                 "Si presenta irritación, suspenda su uso y consulte a su médico."
                ],
            presentation:[
                    "300 ml."
                ],
            photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2Fomni-restore-conditioner.png?alt=media&token=5ee0a5be-567f-4a28-bcf2-d79bafae2293",
                video: "https://www.youtube.com/watch?v=FAn8E45y0IM",
                dataSheet: "https://www.tecitaly.com.mx/wp-content/uploads/2021/09/tec_italy_productos_ficha_tecnica_CON_OMIRESTOREV2R_esp.pdf"
            },

            {
                title: "Omni Restore Protector",
                description: [
                    "LÍNEA DE PROTECCIÓN RESTAURACIÓN Y PROLONGACIÓN DE COLOR INTENSIVA",
                    "Reduce la estática, facilita el peinado acondiciona y ayuda a resistir los procesos de peinado.",
                    "Gracias a sus protectores solares, prolonga la vida del color por mas tiempo.",
                    "Su exclusiva tecnología brinda una protección integral al cabello.",
                    "Contiene  Ingredientes con propiedades antioxidantes y protección UV.",
                    "Es Ideal para  utilizar con herramientas de calor, ayuda a disminuir el frizz y sellar las puntas.",
                    "OMNI RESTORE contiene ingredientes que promueven una PROTECCIÓN intensi-  va, obteniendo como resultado un cabello con brillo, resistencia, suavidad y  desenredo desde la primera aplicación."

                ],
                bullets:[
                     "Después de aplicación OMNI  RESTORE SHAMPOO Y CONDITIONER , aplique una pequeña cantidad, distribuya  de medios a puntas y estilice como desee. Puede utilizar con herramientas de  calor.",
                     "No es necesario enjuagar.",
                     
                    ],
                presentation:[
                        "125 ml."
                    ],
                photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2Fomni-restore-protector.png?alt=media&token=79359f04-0f62-4a47-9293-9bc0ba1d9c3a",
                    video: "https://www.youtube.com/watch?v=FAn8E45y0IM",
                    dataSheet: "https://www.tecitaly.com.mx/wp-content/uploads/2021/09/tec_italy_productos_ficha_tecnica_PROTECTOR_OMIRESTOREV2_esp-r.pdf"
                },
                {
                    title: "Omni Restore Treatment",
                    description: [
                       "TRATAMIENTO EN CREMA RESTAURADOR Y PROTECTOR DEL CABELLO.",
                       "RECUPERA EL BRILLO Y LA SEDOSIDAD, ACONDICIONA, REDUCE LA ESTÁTICA, PROMUEVE LA HIDRATACIÓN.",
                       "GRACIAS A SUS PROTECTORES SOLARES, PROLONGA LA VIDA DEL COLOR POR MÁS TIEMPO."
                    ],
                    bullets:[
                       "Su exclusiva tecnología promueve una protección y restauración integral al cabello.",
                       "Su innovadora fórmula integra Proteína de trigo una selección de ingredientes multipropósito Quinoa, Aceite de Moringa, Vitamina E y L-Arginina que ayudan a proteger y restaurar la estructura de la hebra capilar aún antes del proceso de coloración.recibir el daño.",
                       "OMNI RESTORE contiene ingredientes que promueven una restauración intensiva, obteniendo como resultado un cabello con brillo, resistencia y suavidad desde la primera aplicación."
                        ],
                    presentation:[
                            "280 gr."
                        ],
                    photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2Fomni-restore-treatment.png?alt=media&token=51ec6596-1396-4280-9e9b-e815f88c87b2",
                        video: "https://www.youtube.com/watch?v=FAn8E45y0IM",
                        dataSheet: "https://www.tecitaly.com.mx/wp-content/uploads/2021/09/tec_italy_productos_ficha_tecnica_trat_OMIRESTOREV2R_esp.pdf"
                    },



    ]

        ]
    },




    "blondeplex": {
        title: "Blonde Plex",
        bg: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/BG%2FBLONDE%20PLEX%20SYSTEM%20BANNER.JPG?alt=media&token=100fc905-8090-4f7f-ba3b-513786b43c91",
        products: [
         //// Ingles:::::
            [    
                {
                    title: "System Shampoo",
                    description: [
                    "Strengthening hair strand shampoo for hair bleached by intense lightening processes.",
                    "Formula designed for bleached and extremely damaged hair, with oligopeptide technology that repairs, protects, hydrates and nourishes. Contains Proteins and vitamin E that strengthens the hair essential fatty acids and PRO-VITAMIN B5.",
                    ],
                    bullets:[
                        "Apply a small amount on damp hair, giving a light massage, distributing perfectly.",
                        "Leave on for 2 to 3 minutes.",
                        "Rinse.",
                        "DAILY USE",
                             ],
                
                    presentation:[
                            "300 ml."
                        ],
                    photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2FSystem%20shampoo.png?alt=media&token=8d82de39-362b-4391-84e8-a836341679f8",
                   video: "https://www.youtube.com/watch?v=E97BaHj72Hw",
                    dataSheet: "https://www.tecitaly.com/wp-content/uploads/2020/11/tec_italy_productos_ficha_tecnica_Blonde-Plex_System-shampoo_ing_c1.pdf"
                    },

                    {
                        title: "System Conditioner",
                        description: [
                        "Hair strand protective conditioner for hair bleached by intense lightening processes.",
                        "Concentrated formula for bleached hair that contains proteins with strengthening anti-breakage properties, integrates HYDROLYZED PEANUT PROTEIN that protects the cuticle and prevents breakage, we add HYDROLYZED VEGETABLE PROTEIN that strengthens the strand and improves resistance.",
                        ],
                        bullets:[
                            "Apply a small amount on damp hair, giving a light massage, distributing perfectly.",
                            "Leave on for 2 to 3 minutes.",
                            "RInse.",
                            "DAILY USE",
                                 ],
                    
                        presentation:[
                                "300 ml."
                            ],
                        photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2FSystem%20Conditioner.png?alt=media&token=c89fd450-7571-4f99-9ed7-b40ae4f29729",
                      video: "https://www.youtube.com/watch?v=E97BaHj72Hw",
                        dataSheet: "https://www.tecitaly.com/wp-content/uploads/2020/11/tec_italy_productos_ficha_tecnica_Blonde-Plex_System-Acondicionador_ing_c1.pdf"
                        },
        
                        {
                            title: "System Treatment",
                            description: [
                            "Intensive treatment that strengthens and protects the hair strand for hair bleached by intense lightening processes.",
                            "For hair bleached and damaged by extreme processes, it restores hair dynamics by reducing friction between fibers and recovers the elasticity of the strand.",
                            "With a high concentration of STRENGTHENING ANTI-BREAKAGE PROTEINS such as HYDROLYZED PEANUT PROTEIN and HYDROLYZED VEGETABLE PROTEIN that strengthen the strand and improve resistance."
                            ],
                            bullets:[
                                "Apply from medium to ends, on clean and damp hair the amount necessary for a good distribution.",
                                "Leave on for 20 to 30 minutes. (Heat can be applied depending on the case)",
                                "Rinse only with water.",
                                "Recommended use once a week.",
                                     ],
                        
                            presentation:[
                                    "280 gr."
                                ],
                            photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2FSystem%20Treatment.png?alt=media&token=0034e032-dc22-4e9d-bff1-7f575dd1cba0",
                           video: "https://www.youtube.com/watch?v=E97BaHj72Hw",
                            dataSheet: "https://www.tecitaly.com/wp-content/uploads/2020/11/tec_italy_productos_ficha_tecnica_Blonde-Plex_System-Tratamiento_ING_c1.pdf"
                            },
        

                            {
                                title: "System Powder",
                                description: [
                                "Bleaching powder with protective action for the hair strand.",
                                "New generation formula with Plex technology, which potentiates the level of lightning, protecting and strengthening hair bonds, avoiding split ends.",
                                "Improves the shine and smoothness of the strand."
                                ],
                                bullets:[
                                    "Mix with gentle movements a measure of 30 g of BLONDE PLEX SYSTEM POWDER DECOLORANTE plus 90 ml of A.O.E. Peroxide. Formula: 1 + 3",
                                    "The process time will depend on the desired result and the condition of the hair.",
                                    "Rinse with water until the product is removed.",
                                   
                                         ],
                            
                                presentation:[
                                        "680 gr."
                                    ],
                                photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2FSystem%20Powrder.png?alt=media&token=b27df907-bb4e-408d-ab61-5d1f7ad9eecb",
                                video: "https://www.youtube.com/watch?v=E97BaHj72Hw",
                                dataSheet: "https://www.tecitaly.com/wp-content/uploads/2020/11/tec_italy_productos_ficha_tecnica_Blonde-Plex_System-Powder_ING_c1.pdf"
                                },
        
    





    ],
    
    //Espanol::::::
    
    [   
        
        {
            title: "System Shampoo",
            description: [
            "Shampoo fortalecedor de la hebra capilar para cabellos decolorados por procesos de aclaración intensos.",
            "Fórmula diseñada para cabello decolorado y con daño extremo, con tecnología de oligopéptidos que repara, protege, hidrata y nutre. Contiene Proteínas y vitamina E que fortalece el cabello ácidos grasos esenciales y PRO-VITAMINA B5.",
            ],
            bullets:[
                "Aplique una pequeña cantidad sobre cabello húmedo, dando un ligero masaje, distribuyendo perfectamente.",
                "Dejar actuar de 2 a 3 minutos.",
                "Enjuagar.",
                "USO DIARIO",
                     ],
        
            presentation:[
                    "300 ml."
                ],
            photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2FSystem%20shampoo.png?alt=media&token=8d82de39-362b-4391-84e8-a836341679f8",
            video: "https://www.youtube.com/watch?v=fEkjtzRqOwo",
            dataSheet: "https://www.tecitaly.com.mx/wp-content/uploads/2020/11/tec_italy_productos_ficha_tecnica_Blonde-Plex_System-Shampoor_esp_c1.pdf"
            },

            {
                title: "System Conditioner",
                description: [
                "Acondicionador protector de la hebra capilar para cabellos decolorados por procesos de aclaración intensos.",
                "Fórmula concentrada para cabello decolorado que contiene proteínas con propiedades fortalecedoras anti-quiebre, integra PROTEÍNA HIDROLIZADA DE CHÍCHARO que protege la cutícula y previene la rotura, agregamos PROTEÍNA VEGETAL HIDROLIZADA que fortalece la hebra y mejora la resistencia.",
                ],
                bullets:[
                    "Aplique una pequeña cantidad sobre cabello húmedo, dando un ligero masaje, distribuyendo perfectamente.",
                    "Dejar actuar de 2 a 3 minutos.",
                    "Enjuagar.",
                    "USO DIARIO",
                         ],
            
                presentation:[
                        "300 ml."
                    ],
                photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2FSystem%20Conditioner.png?alt=media&token=c89fd450-7571-4f99-9ed7-b40ae4f29729",
                video: "https://www.youtube.com/watch?v=fEkjtzRqOwo",
                dataSheet: "https://www.tecitaly.com.mx/wp-content/uploads/2020/11/tec_italy_productos_ficha_tecnica_Blonde-Plex_System-Acondicionador_esp_c1.pdf"
                },

        

                {
                    title: "System Treatment",
                    description: [
                    "Tratamiento intensivo fortalecedor y protector de la hebra capilar para cabellos decolorados por procesos de aclaración intensos.",
                    "Para cabello decolorado y danado por procesos extremos, restaura la dinámica del cabello reduciendo la fricción entre fibras y recupera la elasticidad de la hebra.",
                    "Con alta concentración de PROTEÍNAS FORTALECEDORAS ANTI-QUIEBRE como la  PROTEÍNA HIDROLIZADA DE CHÍCHARO y PROTEÍNA VEGETAL HIDROLIZADA que fortalecen la hebra y mejoran la resistencia."
                    ],
                    bullets:[
                        "Aplique de medios a puntas, sobre cabello limpio y húmedo la cantidad necesaria para una buena distribución.",
                        "Dejar actuar de 20 a 30 minutos. (Se puede aplicar calor según el caso)",
                        "Enjuagar solo con agua.",
                        "Uso recomendado una vez por semana.",
                             ],
                
                    presentation:[
                            "280 gr."
                        ],
                    photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2FSystem%20Treatment.png?alt=media&token=0034e032-dc22-4e9d-bff1-7f575dd1cba0",
                     video: "https://www.youtube.com/watch?v=fEkjtzRqOwo",
                    dataSheet: "https://www.tecitaly.com.mx/wp-content/uploads/2020/11/tec_italy_productos_ficha_tecnica_Blonde-Plex_System-Tratamiento_esp_c1.pdf"
                    },

                    {
                        title: "System Powder",
                        description: [
                        "Polvo decolorante con acción protectora para la hebra capilar.",
                        "Fórmula de nueva generación con tecnología Plex, que potencializa el nivel de aclaración protegiendo y fortaleciendo los puentes del cabello, evitando las puntas abiertas.",
                        "Mejora el brillo y la suavidad de la hebra."
                        ],
                        bullets:[
                            "Mezclar con movimientos suaves una medida de 30 g de BLONDE PLEX SYSTEM POLVO DECOLORANTE mas 90 ml de A.O.E. Peroxido. Formula: 1 + 3",
                            "El tiempo de proceso dependerá del resultado deseado y de la condición del cabello.",
                            "Enjuagar con agua hasta retirar el producto.",
                           
                                 ],
                    
                        presentation:[
                                "680 gr."
                            ],
                        photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2FSystem%20Powrder.png?alt=media&token=b27df907-bb4e-408d-ab61-5d1f7ad9eecb",
                        video: "https://www.youtube.com/watch?v=fEkjtzRqOwo",
                        dataSheet: "https://www.tecitaly.com.mx/wp-content/uploads/2020/11/tec_italy_productos_ficha_tecnica_Blonde-Plex_System-Powder_esp_c1.pdf"
                        },




        ]







        ]
    },


    "repair": {
        title: "Repair",
        bg: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/BG%2Frepair%20banner.jpg?alt=media&token=540249ff-d9a5-4c25-ba9d-6d2287210b1a",
        products: [
         //// Ingles:::::
            [    
               
         {
                                            title: "Olio Vital",
                                            description: [
                                                "Revitalizing treatment concentrate for hair.",
    
                                                "Enriched with natural oils which restore hair’s moisture levels, providing greater hydration and shine.",
        
                                                "Ideal for dry hair. Its light and silky texture is easily absorbed. Contains TRICEL-R complex to protect hair against UV rays.",
                                   
                                            ],
                                            bullets:[
                                                    "Apply on clean, humid hair.",
                                                    "Distribute from mid-lengths to the tips.",
                                                    "Style as desired.",
                                                    "Do not apply before using heat styling."
                                                     ],
                                        
                                            presentation:[
                                                    "125 ml."
                                                ],
                                            photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F1HD-olio-vital.webp?alt=media&token=fc5286b7-eebd-4aa0-bc58-f51c3ebf67ef",
                                            video: "https://www.youtube.com/watch?v=pz5joACG7GI",
                                            dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Ftec_italy_productos_ficha_tecnica_HD1_olio_vital_ing.pdf?alt=media&token=02e33b88-4087-44e6-b891-2722e747a972"

                                            },
        
                                            {
                                                title: "Ristruttura",
                                                description: [
                                                    "Repairing treatment for damaged hair.",
                                    
                                                    "An intensive and progressive treatment for damaged hair.",
                                    
                                                    "Its advanced formula contains flax seed concentrate to help repair hair, restoring its shine, strength and silkiness.",
                                                    "Enriched with TRICONE and TRICOERBA, protein and botanical compounds that help improve hair’s appearance.",
                                                    "Contains TRICEL-R, a complex that provides protection against UV rays."
                                       
                                                ],
                                                bullets:[
                                                        "Shampoo hair with SHAMPOO PROFONDO.",
                                                        "Towel-dry hair and distribute a RISTRUTTURA vial throughout the hair.",
                                                        "Leave on for 5-10 minutes.",
                                                        "Rinse.",
                                                        "Apply once a week."
                                                         ],
                                            
                                                presentation:[
                                                        "12 ampoules 10 ml. c/u"
                                                    ],
                                                photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F1HD-RISTRUTTTURA-AI-SEMI-DI-LINO2.webp?alt=media&token=3de789e3-4f49-4945-8df5-a56b11f83979",
                                                dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Ftec_italy_productos_ficha_tecnica_HD1_ristruttura_ai_semi_di_lino_ing.pdf?alt=media&token=a8dd2230-7845-4736-a7bc-35704167d732"
                                    
                                                },

                                                        {
                                                            title: "Amino Keratin",
                                                            description: [
                                                              "Repairing treatment for over-processed hair.",
                                                              "Provides proteins that help fix and reinforce the hair strand.",
                                                              "Ideal for lightened or over-processed hair. Enriched with TRICEL-R complex which protects hair against UV rays."
                                                   
                                                            ],
                                                            bullets:[
                                                                "Apply on clean, humid hair.",
                                                                "Cover with a plastic cap or use an external heat source.",
                                                                "Leave in for 20 minutes and rinse.",
                                                                "For optimal softness, condition with BALSAMI TOTALE.",
                                                                     ],
                                                        
                                                            presentation:[
                                                                    "10 ml. / 280 gr."
                                                                ],
                                                            photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2Famino%20keratin.png?alt=media&token=01ccf18c-73e6-48fd-ab37-0058c4509d0d",
                                                                video:  "https://www.youtube.com/watch?v=wtJK0YCdVKg",
                                                                dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Ftec_italy_productos_ficha_tecnica_HD1_amino_keratin_ing.pdf?alt=media&token=7a4dd462-b984-450c-9bcf-a00cc88363dd"
                                                            },

                                                            {
                                                                title: "Shampoo Massimo",
                                                                description: [
                                                             "Repairing shampoo for over-processed hair.",
                                                             "Recommended for lightened or over-processed hair.",
                                                             "Developed with TRICONE and TRICOERBA, protein and botanical compounds that help repair the damage caused by chemical processes.",
                                                             "Restores strength to prevent hair breakage. Its TRICEL-R complex protects the hair from UV rays."
                                                    
                                                       
                                                                ],
                                                                bullets:[
                                                                    "Apply a small amount and massage gently on hair.",
                                                                    "Leave on for 2-3 minutes and rinse."
                                                                         ],
                                                            
                                                                presentation:[
                                                                    "10 ml. / 300 ml. / 1 lt. / 2 lt."
                                                                    ],
                                                                photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2FSHAMPOOMASSIMO.png?alt=media&token=1d6bf8be-b6c6-46cb-98e7-4034f7e009a8",
                                                                dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Ftec_italy_productos_ficha_tecnica_HD1_shampoo_massimo_ing.pdf?alt=media&token=ffd5c532-e2fc-4733-ac70-4d45d9bac53d"
                                                    
                                                                },


                                                                {
                                                                    title: "Due Faccetta Massimo",
                                                                    description: [
                                                                 "Moisturizing and repairing treatment for over-processed hair.",
                                                                 "Two phase treatment that moisturizes, conditions and repairs lightened or over-processed hair.",
                                                                 "Helps regulate the porosity of the hair.",
                                                                 "Untangles easier and improves manageability.",
                                                                 "Developed with TRICONE and TRICOERBA, protein and botanical compounds that help improve hair’s appearance.",
                                                                 "Contains TRICOSIL, which provides softness, and TRICEL-R, which protects hair from UV rays."
                                                        
                                                           
                                                                    ],
                                                                    bullets:[
                                                                        "Shake the contents until a homogenous mixture is achieved.",
                                                                        "Apply on clean, slightly humid hair.",
                                                                        "Style as desired.",
                                                                        "Daily use."
                                                                        
                                                                             ],
                                                                
                                                                    presentation:[
                                                                        "300 ml."
                                                                        ],
                                                                    photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2Fnew%20dfm%20final.png?alt=media&token=d6764e64-7540-4921-8d90-f68969f92437",
                                                                        video: "https://www.youtube.com/watch?v=gSQ2VNA8fKk",
                                                                        dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Ftec_italy_productos_ficha_tecnica_HD1_due_facceta_massimo_ing.pdf?alt=media&token=129e0891-e0a4-4e7b-9480-97f43c885135"

                                                                    },
    
                                                                    {
                                                                        title: "Vitalita",
                                                                        description: [
                                                                            
                                                                            "Instant leave-on restorative treatment for damaged, dry or porous hair.",
                                                                            "Formulated with Hydrolyzed Keratin and Panthenol that help instantly repair weakened and excessively treated hair, increasing hydration and shine."
                                                               
                                                                        ],
                                                                        bullets:[
                                                                            "Apply to clean, slightly damp hair.",
                                                                            "Do not rinse.",
                                                                            "Style as you like."
                                                                            
                                                                                 ],
                                                                    
                                                                        presentation:[
                                                                            "6 ampoules 10 ml. each"
                                                                            ],
                                                                        photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2FVitalita-imagen-prueba-portal-1-1-e1582573272289-485x700.png?alt=media&token=84553a14-c16f-4ec9-a1b6-4d7e8b45b9da"
                                                            
                                                                        },

                                                                        {
                                                                            title: "T.R.I",
                                                                            description: [
                                                                                
                                                                            "FASE A: Intensive repairing treatment with proteins for hair.",
                                                                            "Formula developed with the protein complex TRICONE, a complex that helps strengthen the hair.",
                                                                            "Helps improve elasticity in the hair strand and minimize its breakage.",
                                                                            "Contains TRICEL-R complex that protects the hair against UV rays.",
                                                                            "FASE B: Intensive repairing treatment with moisturizers for hair.",
                                                                            "Formula developed with the botanical complex TRICOERBA, which provides moisture, shine and softness.",
                                                                            "Helps protect the hair strand from free radicals and other environmental factors.",
                                                                            "Contains TRICEL-R complex to protect the hair from UV rays."

                                
                                                                   
                                                                            ],
                                                                            bullets:[
                                                                                "Wash hair with SHAMPOO PROFONDO.",
                                                                                "Mix R.I ULTRA TRICONE FASE A and T.R.I ULTRA TRICOERBA FASE B (consult the product data sheet to see proportions).",
                                                                                "Apply with heat and give it a pose time of 20 minutes.",
                                                                                "Rinse completely.",
                                                                                "We do not recommend using this product before applying hair color or lightening hair.",
                                                                                
                                                                                     ],
                                                                        
                                                                            presentation:[
                                                                                "10 ml. / 300 ml."
                                                                                ],
                                                                            photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F1HD-TRI-ULTRA-TRICONE.png?alt=media&token=e79dfd88-57db-4c1f-83ba-af84a66bd76a",
                                                                                video: "https://www.youtube.com/watch?v=KA0QuKM6rjs&list=PLMdkq-YPQq-ksb0-pSsX1nDKNz66S6WPV",
                                                                                dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Ftec_italy_productos_ficha_tecnica_HD1_TRI_ing.pdf?alt=media&token=39faffd3-1083-420e-82ec-6195ec9e4aba"
                                                                            },





    ],
    
    
    



    //Espanol::::::
    
    [   
        
        {
            title: "Olio Vital",
            description: [
                "Tratamiento concentrado revitalizante para el cabello.",

                "Enriquecido con aceites de origen natural que restauran los niveles de hidratación en el cabello, aportando mayor humectación y suavidad. ",

                "Ideal para cabello seco. Su textura ligera y sedosa se absorbe con facilidad. Contiene TRICEL-R, complejo que protege el cabello contra los rayos UV.",
   
            ],
            bullets:[
                    "Aplique una pequeña cantidad sobre cabello limpio y húmedo.",
                    "Distribuya de medios a puntas.",
                    "Estilice como desee.",
                    "No se aplique antes de usar herramientas de calor."
                     ],
        
            presentation:[
                    "125 ml."
                ],
            photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F1HD-olio-vital.webp?alt=media&token=fc5286b7-eebd-4aa0-bc58-f51c3ebf67ef",
            video: "https://www.youtube.com/watch?v=hEGiM5f3B9E",
            dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_HD1_olio_vital_esp.pdf?alt=media&token=4f99f37c-f232-4e01-86a5-6a0aa7dee6a2"
            },

            {
                title: "Ristruttura",
                description: [
                    "Tratamiento reparador para cabello maltratado.",
    
                    "Es un tratamiento progresivo para cabello maltratado.",
    
                    "Su avanzada fórmula con activos de semilla de lino es auxiliar en la reparación del cabello, devolviéndole el brillo, fortaleza y sedosidad.",
                    "Enriquecido con TRICONE y TRICOERBA, complejos proteínicos y botánicos que ayudan a mejorar la apariencia del cabello.",
                    "Contiene TRICEL-R, complejo que protege el cabello contra los rayos UV."
       
                ],
                bullets:[
                        "Lave el cabello con SHAMPOO PROFONDO.",
                        "Seque con una toalla y distribuya una ampolleta RISTRUTTURA en todo el cabello.",
                        "Deje actuar de 5-10 minutos.",
                        "Enjuague.",
                        "Aplique una vez a la semana."
                         ],
            
                presentation:[
                        "12 ampolletas 10 ml. c/u"
                    ],
                photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F1HD-RISTRUTTTURA-AI-SEMI-DI-LINO2.webp?alt=media&token=3de789e3-4f49-4945-8df5-a56b11f83979",
                video: "https://www.youtube.com/watch?v=IKlyN4-KnQA",
                dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_HD1_ristruttura_ai_semi_di_lino_esp.pdf?alt=media&token=0bc65368-2ae4-4c16-b970-34e4932ef3bb"

                },

                        {
                            title: "Amino Keratin",
                            description: [
                              "Tratamiento reparador para cabello sobreprocesado.",
                              "Aporta proteínas que ayudan a reparar y reforzar la hebra capilar.",
                              "Ideal para cabello decolorado o sobreprocesado. Enriquecido con TRICEL-R, complejo que protege el cabello contra los rayos UV."
                   
                            ],
                            bullets:[
                                "Aplique sobre cabello limpio y húmedo.",
                                "Cubra con una gorra de plástico o use una fuente de calor externa.",
                                "Deje actuar por 20 minutos y enjuague.",
                                "Para optimizar la suavidad, se recomienda acondicionar con BALSAMI TOTALE.",
                                     ],
                        
                            presentation:[
                                    "10 ml. / 280 gr."
                                ],
                            photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2Famino%20keratin.png?alt=media&token=01ccf18c-73e6-48fd-ab37-0058c4509d0d",
                            video: "https://www.youtube.com/watch?v=lzobJlb52sw",
                            dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_HD1_amino_keratin_esp.pdf?alt=media&token=99a1f6dc-838a-47b6-a8dd-b6658a7f8cd4"

                            },
                            {
                                title: "Shampoo Massimo",
                                description: [
                             "Shampoo reparador para cabello sobreprocesado.",
                             "Ideal para cabello decolorado o sobreprocesado.",
                             "Desarrollado con TRICONE y TRICOERBA, complejos proteínicos y botánicos que ayudan a reparar el daño causado por procesos químicos.",
                             "Devuelve la fuerza para evitar el rompimiento de la hebra. Protege el cabello de los rayos UV gracias a su complejo TRICEL-R."
                    
                       
                                ],
                                bullets:[
                                    "Aplique una pequeña cantidad dando un ligero masaje.",
                                    "Deje actuar de 2-3 minutos y enjuague."
                                         ],
                            
                                presentation:[
                                    "10 ml. / 300 ml. / 1 lt. / 2 lt."
                                    ],
                                photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2FSHAMPOOMASSIMO.png?alt=media&token=1d6bf8be-b6c6-46cb-98e7-4034f7e009a8",
                                video: "https://www.youtube.com/watch?v=4YndphUc0BU",
                                dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_HD1_shampoo_massimo_esp.pdf?alt=media&token=41400e95-730b-47ea-b655-77141334768a"
                                },

                                {
                                    title: "Due Faccetta Massimo",
                                    description: [
                         "Tratamiento reparador hidratante para cabello sobreprocesado.",
                         "Tratamiento en dos fases que hidrata, acondiciona y repara el cabello decolorado o sobreprocesado.",
                         "Ayuda a regular la porosidad del cabello.",
                         "Facilita el desenredo y mejora la manejabilidad.",
                         "Desarrollado con TRICONE y TRICOERBA, complejos proteínicos y botánicos que ayudan a mejorar la apariencia del cabello.",
                         "Contiene TRICOSIL, que aporta suavidad, y TRICEL-R, que protege el cabello de los rayos UV."
                        
                           
                                    ],
                                    bullets:[
                                        "Agite el contenido hasta obtener una mezcla homogénea.",
                                        "Aplique sobre cabello limpio y ligeramente húmedo.",
                                        "Estilice como desee.",
                                        "Uso diario."
                                        
                                             ],
                                
                                    presentation:[
                                        "300 ml."
                                        ],
                                    photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2Fnew%20dfm%20final.png?alt=media&token=d6764e64-7540-4921-8d90-f68969f92437",
                                    video: "https://www.youtube.com/watch?v=8x_lYJkoUVw&t=98s",
                                    dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_HD1_due_facceta_massimo_esp.pdf?alt=media&token=674c2df6-87dd-48a6-960a-6f1540a53bae"
                                    },

                                    {
                                        title: "Vitalita",
                                        description: [
                                            
                                            "Tratamiento reparador instantáneo que se deja puesto para cabello dañado, seco o poroso.",
                                            "Formulado con Queratina Hidrolizada y Pantenol que ayudan a reparar instantáneamente el cabello debilitado y excesivamente tratado, aumentando la hidratación y brillo."
                               
                                        ],
                                        bullets:[
                                            "Aplicar sobre cabello limpio y ligeramente húmedo.",
                                            "No enjuagar.",
                                            "Estilizar como desee."
                                            
                                                 ],
                                    
                                        presentation:[
                                            "6 ampolletas 10 ml. c/u"
                                            ],
                                        photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2FVitalita-imagen-prueba-portal-1-1-e1582573272289-485x700.png?alt=media&token=84553a14-c16f-4ec9-a1b6-4d7e8b45b9da",
                                        video: "https://www.youtube.com/watch?v=bE3c7jNaFN8&t=1s",
                                        dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_vitalita%CC%81_esp.pdf?alt=media&token=ef52fb94-b7b7-4169-87f6-49e8e709c6ae"
                                        },
    
                                        {
                                            title: "T.R.I",
                                            description: [
                                                
                                               "FASE A: Tratamiento reparador intensivo con proteínas para el cabello.",
                                               "Fórmula desarrollada con complejo proteínico TRICONE, que contribuye al fortalecimiento del cabello.",
                                               "Ayuda a mejorar la elasticidad de la hebra capilar y minimizar su rompimiento.",
                                               "Contiene TRICEL-R, complejo que protege el cabello contra los rayos UV.",
                                                "FASE B: Tratamiento reparador intensivo con hidratantes para el cabello.",
                                                "Fórmula desarrollada con complejo botánico TRICOERBA, que aporta hidratación, brillo y suavidad.",
                                                "Ayuda a proteger la hebra capilar de los radicales libres y otros factores ambientales.",
                                                "Contiene TRICEL-R, complejo que protege el cabello contra los rayos UV."

                                   
                                            ],
                                            bullets:[
                                                "Lave el cabello con SHAMPOO PROFONDO.",
                                                "Mezcle R.I. ULTRA TRICONE FASE A y T.R.I. ULTRA TRICOERBA FASE B (consulte la ficha técnica del producto para ver proporciones).",
                                                "Aplique con calor y dé un tiempo de pose de 20 minutos.",
                                                "Enjuague por completo.",
                                                "No se recomienda utilizar antes de aplicar un tinte o decoloración.",
                                                
                                                     ],
                                        
                                            presentation:[
                                                "10 ml. / 300 ml."
                                                ],
                                            photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F1HD-TRI-ULTRA-TRICONE.png?alt=media&token=e79dfd88-57db-4c1f-83ba-af84a66bd76a",
                                            video: "https://www.youtube.com/watch?v=ETxWx--wBmc",
                                            dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_HD1_TRI_esp.pdf?alt=media&token=3a278a95-43db-416b-9e38-e3d80f96dc68"

                                            },
        

        




        ]







        ]
    },


    "scalpsolution": {
        title: "Scalp Solution",
        bg: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/BG%2Fscalpbanner.jpg?alt=media&token=62b019b9-78d1-40ba-8194-5ae0fbaf2b94",
        products: [
         //// Ingles:::::
            [    
               
         {
                                            title: "Shampoo Tonico",
                                            description: [
                                                "Shampoo to help strengthen, provide volume and resistance to hair.",
                                                "Shampoo for weak, fine hair. Helps recover strength and resistance. Provides volume and body to hair.",
                                                "Enriched with TRICONE and TRICOERBA, protein and botanical compounds that help improve hair’s appearance.",
                                                "Contains TRICEL-R, a complex that provides protection against UV rays."
                                         
                                                
                                                
                                            ],
                                            bullets:[
                                                "Apply a small amount with a light massage.",
                                                "Leave in for 3-5 minutes and rinse.",
                                                "For optimum strength, we recommend complementing the treatment with TONICO ANTI CADUTA.",


                                                     ],
                                        
                                            presentation:[
                                                    "10 ml. / 300 ml. / 2 lt."
                                                ],
                                            photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F5BD-SHAMPOO-TONICO.webp?alt=media&token=29745967-4b7b-4cd2-a79d-bc7835ee53ee",
                                                video: "https://www.youtube.com/watch?v=4NsNJAsQYOs",
                                                dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Ftec_italy_productos_ficha_tecnica_BD1_shampoo_tonico_ing.pdf?alt=media&token=cec3db9e-a81e-4b46-a324-e0c25347fac1"

                                            },
                                                {
                                                    title: "Anti Caduta",
                                                    description: [
                                                       "Intensive fortifying hair treatment with herbal complex.",
                                                       "A treatment developed to restore strength and resistance to fine and weak hair.",
                                                       "Its advanced formula contains ingredients that help prevent premature hair loss, achieving hair strengthening from its growth.",
                                                       "Enriched with TRICONE and TRICOERBA, protein and botanical compounds that help improve hair’s appearance."
                                                        
                                                        
                                                    ],
                                                    bullets:[
                                                        "Shampoo hair with SHAMPOO PROFONDO.",
                                                        "Towel dry and apply the vial directly onto the scalp.",
                                                        "Massage in a circular motion for 5 minutes.",
                                                        "Focusing on areas where hair is weaker.",
                                                        "DO NOT RINSE.",
                                                        "For optimum strengthening we recommend using SHAMPOO TONICO."
                                                                                         
                                                    
                                        
                                                             ],
                                                
                                                    presentation:[
                                                            "12 ampoules 10 ml. each"
                                                        ],
                                                    photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F5BD-AMPOLLETAS-TONICO-ANTICADUCA2.png?alt=media&token=13214e80-a746-4d4c-92c8-d988812c703a",
                                                        video: "https://www.youtube.com/watch?v=4NsNJAsQYOs",
                                                        dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Ftec_italy_productos_ficha_tecnica_ampolleta_tonico_anticaduta_ing.pdf?alt=media&token=99bec37f-c71d-4c0b-9be3-da1e711bd627"
                                                    },
                                
    





    ],
    
    
    



    //Espanol::::::
    
    [   
        
        {
            title: "Shampoo Tonico",
            description: [
               "Shampoo que ayuda a fortalecer, dar volumen y resistencia al cabello.",
               "Shampoo para cabello débil y fino que ayuda a devolver la fuerza y resistencia. Aporta volumen y cuerpo al cabello.",
               "Enriquecido con TRICONE y TRICOERBA, complejos proteínicos y botánicos que ayudan a mejorar la apariencia del cabello.",
               "Contiene TRICEL-R, complejo que brinda protección contra los rayos UV."
         
                
                
            ],
            bullets:[
                "Aplique una pequeña cantidad dando un ligero masaje.",
                "Deje actuar de 3-5 minutos y enjuague.",
                "Para un óptimo fortalecimiento, se recomienda complementar con el tratamiento TONICO ANTI CADUTA."


                     ],
        
            presentation:[
                    "10 ml. / 300 ml. / 2 lt."
                ],
            photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F5BD-SHAMPOO-TONICO.webp?alt=media&token=29745967-4b7b-4cd2-a79d-bc7835ee53ee",
            video: "https://www.youtube.com/watch?v=J7EFW8jAp2E",
            dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_BD1_shampoo_tonico_esp.pdf?alt=media&token=279f9705-3a9b-4ff0-a8fd-ea9d565d5abf"
            },

          
                {
                    title: "Anti Caduta",
                    description: [
                       "Tratamiento fortalecedor intensivo con complejo herbal para el cabello.",
                       "Es un tratamiento desarrollado para devolver la fuerza y resistencia del cabello fino y débil.",
                       "Su avanzada fórmula contiene ingredientes auxiliares en la caída prematura del cabello, logrando el fortalecimiento de la hebra capilar desde la raíz.",
                       "Enriquecido con TRICONE y TRICOERBA, complejos proteínicos y botánicos que ayudan a mejorar la apariencia del cabello."
                        
                    ],
                    bullets:[
                        "Lave el cabello con SHAMPOO PROFONDO.",
                        "Seque con una toalla y aplique la ampolleta sobre el cuero cabelludo.",
                        "Dé un masaje circular por 5 minutos, haciendo énfasis en las zonas donde el cabello sea más débil.",
                        "NO ENJUAGUE.",
                        "Para un óptimo fortalecimiento, se recomienda complementar con SHAMPOO TONICO.",
                                                         
                    
        
                             ],
                
                    presentation:[
                            "12 ampolletas 10 ml. c/u"
                        ],
                    photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F5BD-AMPOLLETAS-TONICO-ANTICADUCA2.png?alt=media&token=13214e80-a746-4d4c-92c8-d988812c703a",
                    video: "https://www.youtube.com/watch?v=H4USXCwfJJE",
                    dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_BD1_ampolleta_tonico_anticaduta_esp.pdf?alt=media&token=610fcd6a-47c5-460a-8793-5972b81481f1"

                    },


        




        ]







        ]
    },


    "colorcare": {
        title: "Color Care",
        bg: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/BG%2Fcolorcarebanner.jpg?alt=media&token=c439cacd-6cc4-4b2a-996a-7a52dfda9bf3",
        products: [
         //// Ingles:::::
            [    
               
                {
                    title: "Lumina Silver Shampoo",
                    description: [
                        "Shampoo for natural gray and white hair. Shampoo for natural gray and white hair, deposits pigments that gradually intensify the gray tones and counteract the yellow tones in gray, giving light silver reflections.",
                        "Its pigment applied in heights 6, 7 and 8 can neutralize orange backgrounds, resulting in chocolate tones.",
                        "Enriched with TRICONE and TRICOERBA, protein and botanical complexes that help improve the appearance of hair."

                    ],
                    bullets:[
                        "Review the instructions for use in the technical sheet."

                        ],
                
                    presentation:[
                            "10 ml. / 300 ml. / 1 lt."
                        ],
                    photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2FLUMINA-SILVER-300-ml.png?alt=media&token=64b757c2-70c7-41c1-a060-b2e62bff3118"
            
                    },

                    {
                        title: "Lumina Conditioner",
                        description: [
                            "Toning conditioner for blond or gray hair.",
                            "The perfect addition for LUMINA SHAMPOO. Its violet pigment neutralizes yellow tones.",
                            "Provides softness and shine.",
                            "Enriched with TRICONE and TRICOERBA, protein and botanical compounds that help improve hair’s appearance.",
                            "Protects the hair from UV rays thanks to its TRICEL-R complex."
    
                        ],
                        bullets:[
                            "Apply on clean, humid hair, leave in for 3-5 minutes and rinse.",
                            "We recommend using this product 2-3 times a week."

    
                            ],
                    
                        presentation:[
                                "10 ml. / 300 ml. / 1 lt."
                            ],
                        photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2FLumina%20conditioner%202021.png?alt=media&token=9e881b1e-2e42-40c9-86fe-e52599d88937",
                        dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Ftec_italy_productos_ficha_tecnica_CD1_lumina_conditioner_ing.pdf?alt=media&token=0b804714-ef46-40b9-8807-9bd13480fc1b"
                
                        },

                        {
                            title: "Lumina Shampoo",
                            description: [
                                "Toning shampoo for blond or gray hair.",
                                "Toning shampoo for gray, blond or platinum hair.",
                                "Its violet pigment neutralizes yellow tones.",
                                "Enriched with TRICONE and TRICOERBA, protein and botanical compounds that help improve hair’s appearance."
                
                            ],
                            bullets:[
                                "Apply a small amount, massaging gently.",
                                "Leave in for 3-5 minutes and rinse."
                
                
                                ],
                        
                            presentation:[
                                    "10 ml. / 300 ml. / 1 lt."
                                ],
                            photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2FLUMINA%20SHAMPOO.png?alt=media&token=89936f36-5af0-4c14-b32b-0b61d7dd6152",
                                dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Ftec_italy_productos_ficha_tecnica_CD1_lumina_shampoo_ing.pdf?alt=media&token=7ed3856f-209e-4c73-9f35-4ec36a86a5a5"
                            },
        
                            {
                                title: "Olio Vital Color",
                                description: [
                                    "Revitalizing treatment concentrate for color treated hair.",
                                    "Its exclusive formula contains natural oils which restore the moisture levels in hair, providing greater moisture and softness.",
                                    "Helps protect colored hair. Its light, silky texture is easily absorbed.",
                                    "Contains TRICEL-R complex that protects hair against UV rays."
                    
                                ],
                                bullets:[
                                    "Apply a small amount on clean, humid hair.",
                                    "Distribute evenly from mid-lengths to the tips.",
                                    "Style as desired.",
                                    "Do not apply before using heat styling tools.",
                    
                    
                                    ],
                            
                                presentation:[
                                        "125 ml."
                                    ],
                                photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F6CD-OLIO-VITAL-COLOR.png?alt=media&token=35bb82ef-1a90-49fe-84da-0610c40f1f83",
                                    dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Ftec_italy_productos_ficha_tecnica_CD1_olio_vital_color_ing.pdf?alt=media&token=9ff39f4b-1145-4439-a6f4-316fe8075a67"
                                },
                                {
                                    title: "Due Facetta Lunga Durata",
                                    description: [
                                       "Moisturizing color protecting treatment for colored hair.",
                                       "Two-phase treatment that moisturizes and conditions colored hair.",
                                       "Seals the cuticle avoiding the premature loss of color.",
                                       "Facilitates untangling and improves manageability.",
                                       "Enriched with TRICONE and TRICOERBA, protein and botanical compounds that help improve hair’s appearance.",
                                       "Developed with TRICOSIL, which provides softness, and TRICEL-R, to protect the hair from UV rays."



                                    ],
                                    bullets:[
                                        "Shake the contents until a homogenous mixture is achieved.",
                                        "Apply on clean, slightly humid hair.",
                                        "Style as desired.",
                                        "Daily use."
                        
                        
                                        ],
                                
                                    presentation:[
                                            "300 ml."
                                        ],
                                    photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F6CD-DUE-FACETTA-LUNGA-DURATA.png?alt=media&token=bc6e7d58-5d89-4178-89c5-c2a275d21c00",
                                        dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Ftec_italy_productos_ficha_tecnica_CD1_due_facetta_lunga_durata_ing.pdf?alt=media&token=3f2ff1fc-110b-45ad-b633-9fc6b724f2de"
                                    },
                                    {
                                        title: "Lumina Forza Colore",
                                        description: [
                                           "Revitalizing and color intensifying treatment for hair.",
                                           "Designed to prolong color intensity in colored hair. Revitalizes hair, giving it back its softness, manageability and shine.",
                                           "Enriched with Tahiti Monoi oil, which conditions and moisturizes hair.",
                                           "Contains TRICONE and TRICOERBA, protein and botanical compounds that help improve hair’s appearance.",
                                           "Provides protection form UV rays thanks to its TRICEL-R complex."
    
    
    
                                        ],
                                        bullets:[
                                            "Spread LUMINA FORZA COLORE evenly throughout clean, humid hair.",
                                            "We recommend the use of gloves.",
                                            "Leave in for 20-30 minutes and rinse completely.",
                                            "Style as desired.",
                            
                            
                                            ],
                                    
                                        presentation:[
                                                "10 ml. / 280 gr."
                                            ],
                                        photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F6CD-LUMINA-FORZA-COLORE2.png?alt=media&token=8f444b74-de10-4ec9-a221-29234bd8a369",
                                            video: "https://www.youtube.com/watch?v=ibs1o_io4TA",
                                            dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Ftec_italy_productos_ficha_tecnica_CD1_lumina_forza_colore_ing.pdf?alt=media&token=becfd81d-4688-41e5-8fcf-1d3b110bc44a"

                                        },

                                                {
                                                    title: "Shampoo Post Color",
                                                    description: [
                                                        "Shampoo that helps prolong colored hair.",
                                                        "Protective shampoo for colored hair. Helps prevent premature color fading by sealing the cuticle thanks to its slightly acid pH.",
                                                        "Developed with TRICONE and TRICOERBA, protein and botanical compounds that help improve hair’s appearance.",
                                                        "Protects the hair from UV rays thanks to its TRICEL-R complex. Ideal for washing your hair after coloring it."
                        
                                                    ],
                                                    bullets:[
                                                        "Apply a small amount, massaging gently.",
                                                        "Rinse.",
                                                        "We recommend using DUE FACCETTA LUNGA DURATA.",
                                                        "Daily use."
                
                                        
                                        
                                                        ],
                                                
                                                    presentation:[
                                                            "10 ml. / 300 ml. / 1 lt. / 2 lt."
                                                        ],
                                                    photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2FSHAMPOO%20POSTCOLOR.png?alt=media&token=e3eca389-fc4e-4036-b89d-cd4db0a2c4cf",
                                                    dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Ftec_italy_productos_ficha_tecnica_CD1_shampoo_post_color_ing.pdf?alt=media&token=aee5308f-6499-414b-97c4-5f4323e8cb14"

                                            
                                                    },

                                                    {
                                                        title: "Preciso",
                                                        description: [
                                                            "Cuticle sealing cream for hair.",
                                                            "Its acid pH seals the cuticle for longer color duration in color treated hair.",
                                                             "Enriched with TRICONE and TRICOERBA, protein and botanical compounds that help improve hair’s appearance.",
                                                              "Protects hair against UV rays thanks to its TRICEL-R complex.",  
                            
                                                        ],
                                                        bullets:[
                                                            "After a chemical process or hair coloring, apply on humid hair and distribute evenly.",
                                                            "Leave in for 3-5 minutes and rinse.",
                                                            "Do not use after a hair relaxing treatment.",
                                                            "Hair may feel slightly rigid due to the pH after using this treatment. We recommend using a conditioner afterwards."
                    
                                            
                                            
                                                            ],
                                                    
                                                        presentation:[
                                                                "1 lt."
                                                            ],
                                                        photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F6CD-PRECISO.png?alt=media&token=beeb62ab-786f-49a9-a05e-228e5c72c8cc",
                                                        dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Ftec_italy_productos_ficha_tecnica_CD1_preciso_ing.pdf?alt=media&token=503e1b70-f9ed-42ab-87ee-9fa5aa0a79e0"

                                                
                                                        },
    





    ],
    
    
    



    //Espanol::::::
    
    [   
        
        {
        title: "Lumina Silver Shampoo",
        description: [
            "Shampoo para cabello gris y blanco natural. Shampoo para cabello gris cano y blanco natural, deposita pigmentos que intensifican de manera gradual los tonos grises y contrarresta los tonos amarillos en cana, dando ligeros reflejos plateados.",
            "Su pigmento aplicado en alturas 6, 7 y 8 puede neutralizar fondos naranja, dando como resultado tonos chocolates.",
            "Enriquecido con TRICONE y TRICOERBA, complejos proteínicos y botánicos que ayudan a mejorar la apariencia del cabello."
        ],
        bullets:[
            "Revisar las instrucciones de uso en la ficha técnica."
            ],
    
        presentation:[
                "10 ml. / 300 ml. / 1 lt."
            ],
        photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2FLUMINA-SILVER-300-ml.png?alt=media&token=64b757c2-70c7-41c1-a060-b2e62bff3118"

        },

        {
            title: "Lumina Conditioner",
            description: [
                "Acondicionador matizador para cabello rubio o blanco. Complemento ideal de LUMINA SHAMPOO.",
                "Su pigmento violeta neutraliza las tonalidades amarillas. Aporta suavidad y brillo.",
                "Enriquecido con TRICONE y TRICOERBA, complejos proteínicos y botánicos que ayudan a mejorar la apariencia del cabello.",
                "Protege el cabello de los rayos UV gracias al complejo TRICEL-R."


            ],
            bullets:[
                "Aplique sobre el cabello húmedo y limpio, deje actuar de 3-5 minutos y enjuague.",
                "Se recomienda usar 2-3 veces por semana."


                ],
        
            presentation:[
                    "10 ml. / 300 ml. / 1 lt."
                ],
            photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2FLumina%20conditioner%202021.png?alt=media&token=9e881b1e-2e42-40c9-86fe-e52599d88937",
                video: "https://www.youtube.com/watch?v=zeiZ1RXqdhI",
                dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_CD1_lumina_conditioner_esp.pdf?alt=media&token=61443e2a-e29a-4c42-a4d9-b4f9a0819714"
            },

            {
                title: "Lumina Shampoo",
                description: [
                    "Shampoo matizador para cabello rubio o blanco. Shampoo matizador para cabello cano, rubio o plateado.",
                    "Su pigmento violeta neutraliza las tonalidades amarillas.",
                    "Enriquecido con TRICONE y TRICOERBA, complejos proteínicos y botánicos que ayudan a mejorar la apariencia del cabello."
    
    
                ],
                bullets:[
                    "Aplique una pequeña cantidad dando un ligero masaje.",
                    "Deje actuar de 3-5 minutos y enjuague.",
                    "Para un óptimo resultado y suavidad, se recomienda aplicar LUMINA CONDITIONER.",
                    "Usar 2-3 veces por semana."
    
    
                    ],
            
                presentation:[
                        "10 ml. / 300 ml. / 1 lt."
                    ],
                photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2FLUMINA%20SHAMPOO.png?alt=media&token=89936f36-5af0-4c14-b32b-0b61d7dd6152",
                    video: "https://www.youtube.com/watch?v=a3ZqAgrVskg",
                    dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_CD1_lumina_shampoo_esp.pdf?alt=media&token=e71ebb18-1112-4b40-8bb4-5b2be39fe389"

                },

                {
                    title: "Olio Vital Color",
                    description: [
                        "Tratamiento concentrado revitalizante para cabello teñido.",
                        "Su exclusiva fórmula contiene aceites de origen natural que restauran los niveles de hidratación en el cabello, aportando mayor humectación y suavidad.",
                        "Ayuda a proteger el color del cabello teñido. Su textura ligera y sedosa se absorbe con facilidad.",
                        "Contiene TRICEL-R, complejo que protege el cabello contra los rayos UV."
        
                    ],
                    bullets:[
                        "Aplique una pequeña cantidad sobre cabello limpio y húmedo.",
                        "Distribuya de medios a puntas.",
                        "Estilice como desee.",
                        "No se aplique antes de usar herramientas de calor.",
        
        
                        ],
                
                    presentation:[
                            "125 ml."
                        ],
                    photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F6CD-OLIO-VITAL-COLOR.png?alt=media&token=35bb82ef-1a90-49fe-84da-0610c40f1f83",
                        video: "https://www.youtube.com/watch?v=SUYtI9THB18",
                        dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_CD1_olio_vital_color_esp.pdf?alt=media&token=d0e3ee5c-1d9c-4d6e-b860-707da00d15d1"

                    },

                    {
                        title: "Due Facetta Lunga Durata",
                        description: [
                           "Tratamiento hidratante protector de color para cabello teñido.",
                           "Tratamiento en dos fases que hidrata y acondiciona el cabello teñido.",
                           "Sella la cutícula, evitando la pérdida prematura del color.",
                           "Facilita el desenredo y mejora la manejabilidad. Enriquecido con TRICONE y TRICOERBA, complejos proteínicos y botánicos que ayudan a mejorar la apariencia del cabello.",
                           "Desarrollado con TRICOSIL, que aporta suavidad, y TRICEL-R, que protege el cabello de los rayos UV."
            
                        ],
                        bullets:[
                            "Agite el contenido hasta obtener una mezcla homogénea.",
                            "Aplique sobre cabello limpio y ligeramente húmedo.",
                            "Estilice como desee.",
                            "Uso diario."
            
            
                            ],
                    
                        presentation:[
                                "300 ml."
                            ],
                        photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F6CD-DUE-FACETTA-LUNGA-DURATA.png?alt=media&token=bc6e7d58-5d89-4178-89c5-c2a275d21c00",
                            video: "https://www.youtube.com/watch?v=8x_lYJkoUVw",
                            dataSheet: "hhttps://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_CD1_due_facetta_lunga_durata_esp.pdf?alt=media&token=28fb98ec-c78a-430f-b177-9c746e42dfb5"

                        },

                        {
                            title: "Lumina Forza Colore",
                            description: [
                        "Tratamiento revitalizante e intensificador de color para cabello.",
                        "Diseñado para mantener la intensidad del color en el cabello teñido. Revitaliza el cabello, devolviendo la suavidad, manejabilidad y brillo.",
                        "Enriquecido con Aceite de Monoi Tahití que acondiciona y humecta el cabello.",
                        "Contiene TRICONE y TRICOERBA, complejos proteínicos y botánicos que ayudan a mejorar la apariencia del cabello.",
                        "Protege de los rayos UV gracias al complejo TRICEL-R."



                            ],
                            bullets:[
                                "Sobre el cabello limpio y húmedo.",
                                "Distribuya uniformemente LUMINA FORZA COLORE. Se recomienda usar guantes.",
                                "Deje actuar de 20-30 minutos y enjuague por completo.",
                                "Estilice como desee."  
                
                
                                ],
                        
                            presentation:[
                                    "10 ml. / 280 gr."
                                ],
                            photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F6CD-LUMINA-FORZA-COLORE2.png?alt=media&token=8f444b74-de10-4ec9-a221-29234bd8a369",
                                video: "https://www.youtube.com/watch?v=EzVXvfxBHkw",
                                dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_CD1_lumina_forza_colore_esp.pdf?alt=media&token=71d82f85-e759-4942-be55-d9e59038afae"
                            },

                            {
                                title: "Balsami Presto",
                                description: [
                       "Tratamiento revitalizante intensivo para el cabello.",
                       "Se deja puesto.Tratamiento intensivo para cabello teñido y normal.",
                       "Devuelve la suavidad y brillo. Mejora la manejabilidad y reduce el frizz.",
                       "Desarrollado con TRICONE y TRICOERBA, complejos proteínicos y botánicos que ayudan a mejorar la apariencia del cabello.",
                       "Gracias al complejo TRICEL-R protege el cabello de los rayos UV."
    
    
    
                                ],
                                bullets:[
                                    "Aplique una pequeña cantidad sobre cabello limpio y húmedo.",
                                    "Estilice como desee."
                    
                    
                                    ],
                            
                                presentation:[
                                        "10 ml. / 300 ml. / 1 lt."
                                    ],
                                photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F6CD-BALSAMI-PRESTO-TRATAMIENTO.png?alt=media&token=cbe296f7-8153-4078-9020-22b47f086838",
                                    video: "https://www.youtube.com/watch?v=HTEpCslH2Tk",
                                    dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_CD1_balsami_presto_tratamiento_esp.pdf?alt=media&token=013c01d5-e2a6-4f5b-94bb-5cdedb3a5cbf"

                                },
                                {
                                    title: "Balsami Presto Shampoo",
                                    description: [
                             "Shampoo fortalecedor y revitalizante para el cabello.",
                             "Su fórmula contiene ingredientes de origen natural que proporcionan fortaleza y protección al cabello dañado.",
                             "Brinda manejabilidad y brillo. Enriquecido con TRICONE y TRICOERBA, complejos proteínicos y botánicos que ayudan a mejorar la apariencia del cabello.",
                             "Contiene TRICEL-R, complejo que protege el cabello contra los rayos UV."
        
                                    ],
                                    bullets:[
                                        "Aplique una pequeña cantidad dando un ligero masaje.",
                                        "Enjuague.",
                                        "Después aplique BALSAMI PRESTO TRATAMIENTO QUE SE DEJA PUESTO."

                        
                        
                                        ],
                                
                                    presentation:[
                                            "10 ml. / 300 ml."
                                        ],
                                    photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F6CD-BALSAMI-PRESTO-SHAMPOO.png?alt=media&token=1bb06b27-1b6d-4664-9934-6d288f1c6cbf",
                                        video: "https://www.youtube.com/watch?v=DC9kbzyE_QE",
                                        dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_CD1_balsami_presto_shampoo_esp.pdf?alt=media&token=d60ed231-fe80-47fa-89d2-f7a37c43a87c"

                                    },



                                    {
                                        title: "Shampoo Post Color",
                                        description: [
                                 "Shampoo que ayuda a prolongar el color del cabello teñido.",
                                 "Shampoo protector para el cabello teñido. Ayuda a prevenir la pérdida prematura del color sellando la cutícula gracias a su pH ligeramente ácido.",
                                 "Desarrollado con TRICONE y TRICOERBA, complejos proteínicos y botánicos que ayudan a mejorar la apariencia del cabello.",
                                 "Protege el cabello de los rayos UV gracias al complejo TRICEL-R.",
                                 "Ideal para lavar el cabello después de una coloración."
            
                                        ],
                                        bullets:[
                                            "Aplique una pequeña cantidad dando un ligero masaje.",
                                            "Enjuague.",
                                            "Se recomienda aplicar DUE FACCETTA LUNGA DURATA.",
                                            "Uso diario.",
    
                            
                            
                                            ],
                                    
                                        presentation:[
                                                "10 ml. / 300 ml. / 1 lt. / 2 lt."
                                            ],
                                        photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2FSHAMPOO%20POSTCOLOR.png?alt=media&token=e3eca389-fc4e-4036-b89d-cd4db0a2c4cf",
                                            video: "https://www.youtube.com/watch?v=4ZBacOxV940",
                                            dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_CD1_shampoo_post_color_esp.pdf?alt=media&token=486008d7-c0de-45ef-ae5c-1da1b11aa6d1"
                                        },
                                        {
                                            title: "Preciso",
                                            description: [
                                             "Crema para sellar la cutícula del cabello.",
                                             "Su pH ácido sella la cutícula para una mayor duración del color en el cabello teñido.",
                                             "Enriquecido con TRICONE y TRICOERBA, complejos proteínicos y botánicos que ayudan a mejorar la apariencia del cabello.",
                                             "Protege el cabello de los rayos UV gracias al complejo TRICEL-R."
                
                                            ],
                                            bullets:[
                                                "Después de un proceso químico o coloración, aplique sobre cabello húmedo, distribuya uniformemente.",
                                                "Deje actuar de 3-5 minutos y enjuague.",
                                                "No usar después de un relajante.",
                                                "Después de la aplicación, el cabello tendrá una sensación ligeramente rígida por la acción del pH. Se recomienda acondicionar."
                                
                                
                                                ],
                                        
                                            presentation:[
                                                    "1 lt."
                                                ],
                                            photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F6CD-PRECISO.png?alt=media&token=beeb62ab-786f-49a9-a05e-228e5c72c8cc",
                                                video: "https://www.youtube.com/watch?v=p2I1rRKM1oI",
                                                dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_CD1_preciso_esp.pdf?alt=media&token=3d930654-36c1-44d1-a67f-b2f023acd677"
                                            },




        ]







        ]
    },

    "moisture": {
        title: "Moisture",
        bg: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/BG%2Fmoistbanner.jpg?alt=media&token=5e2d5b7e-0e30-4269-8f31-e207697b45e0",
        products: [
         //// Ingles:::::
            [    
               
         {
                                            title: "Hi Moisturizing Treatment",
                                            description: [
                                                "Deeply moisturizing hair mask.",
                                                "Its formula enriched with natural compounds based on sugar cane oligosaccharides, honey, aloe vera and cupuaçu oil deeply moisturizes each hair strand, giving it back its silkiness, moisture and shine."
                
                                         
                                                
                                                
                                            ],
                                            bullets:[
                                                "Distribute the HI-MOISTURIZING TREATMENT evenly throughout clean, humid hair. You may apply it with medium heat.",
                                                "Leave in for 20-30 minutes and rinse completely.",
                                                "Style as desired.",
                                                "Use once a week."  


                                                     ],
                                        
                                            presentation:[
                                                    "10 ml. / 280 gr."
                                                ],
                                            photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2FHIMOISTTREAT.png?alt=media&token=077bf62b-2e80-4530-8a42-f8773bbaacd3",
                                            dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Ftec_italy_productos_ficha_tecnica_MD1_hi_moisturizing_treatment_ing.pdf?alt=media&token=f52a0060-c999-44f8-bd54-a9ab4c375cc5"

                                
                                            },
        
                                            {
                                                title: "Hi Moisturizing Conditioner",
                                                description: [
                                                   "Moisturizing conditioner for damaged and dry hair.",
                                                   "Its highly moisturizing formula helps recover hair’s moisture.",
                                                   "Enriched with natural compounds based on oligosaccharides, which restore softness and manageability with immediate results."
                                                    
                                                ],
                                                bullets:[
                                                    "Apply on clean, humid hair, leave in for 3-5 minutes.",
                                                    "Rinse."
                                                    
                                    
                                    
                                                         ],
                                            
                                                presentation:[
                                                        "10 ml. / 300 ml."
                                                    ],
                                                photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2FHIMOISTCOND.png?alt=media&token=8a39a447-5562-4d30-934d-eb30d9c1bd8f",
                                                    video: "https://www.youtube.com/watch?v=NUCzIXI5dEY&t",
                                                    dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Ftec_italy_productos_ficha_tecnica_MD1_hi_moisturizing_conditioner_ing.pdf?alt=media&token=0bcb34e0-6acc-4721-91c8-09257a6cf847"
                                                },

                                                {
                                                    title: "Hi Moisturizing Shampoo",
                                                    description: [
                                                       "Highly moisturizing, sulfate-free shampoo for a soft cleanse.",
                                                       "Its formula enriched with natural compounds based on oligosaccharides helps restore moisture and hydration with immediate results."
                                                    ],
                                                    bullets:[
                                                        "Apply a small amount, massaging lightly.",
                                                        "Rinse.",
                                                        "Continue with HI-MOISTURIZING CONDITIONER.",
                                                        "We recommend using it daily.",
                                                        
                                        
                                        
                                                             ],
                                                
                                                    presentation:[
                                                            "10 ml. / 300 ml."
                                                        ],
                                                    photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F2MD-HI-MOISTURIZING-SHAMPOO.png?alt=media&token=684c1f54-f9ad-4bf4-abd2-fd42810ab78d",
                                                        video: "https://www.youtube.com/watch?v=NUCzIXI5dEY&t",
                                                        dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Ftec_italy_productos_ficha_tecnica_MD1_hi_moisturizing_shampoo_ing.pdf?alt=media&token=a894219e-cba6-4beb-a56c-085bc7a09290"

                                                    },

                                                    {
                                                        title: "Due Faccetta Giorno Per Giorno",
                                                        description: [
                                                          "Daily-use intensive hydrating treatmeant for hair.",
                                                          "wo-phase treatment that hydrates and conditions hair.",
                                                          "Recommended for normal to dry hair. Detangles and improves manageability.",
                                                          "Enriched with TRICONE and TRICOERBA, protein and botanical compounds that help improve hair’s appearance.",
                                                          "Developed with TRICOSIL to provide softness and TRICEL-R to protect hair from UV rays."

                                
                                                        ],
                                                        bullets:[
                                                            "Shake well before using.",
                                                            "Apply on clean and slightly damp hair.",
                                                            "Leave in and style.",
                                                            "For daily use."
                                                            
                                                            
                                            
                                            
                                                                 ],
                                                    
                                                        presentation:[
                                                                "300 ml."
                                                            ],
                                                        photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2FDFGPG.png?alt=media&token=b4c90ab9-ec59-4b79-81e1-e336293fa50e",
                                                        dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Ftec_italy_productos_ficha_tecnica_MD1_due_facceta_giorno_ing.pdf?alt=media&token=1b6a615f-e353-472e-afbe-3a21ab2d894d"
                                            
                                                        },

                                                        {
                                                            title: "Shampoo Totale",
                                                            description: [
                                                                "Shampoo conditioner for dry and damaged hair. Ideal for damaged hair.",
                                                
                                                                "Conditions and moisturizes the hair strand, giving it back its silkiness, manageability and shine.",
                                                
                                                                "Helps repair hair damage. Enriched with TRICONE and TRICOERBA, protein and botanical compounds that help improve hair’s appearance.",
                                                                "Enriched with TRICONE and TRICOERBA, protein and botanical compounds that help improve hair’s appearance.",
                                                                "Contains TRICEL-R complex that protects hair against UV rays."
                                                   
                                                            ],
                                                            bullets:[
                                                                    "Apply a small amount, massaging lightly.",
                                                                    "Rinse.",
                                                                    "Continue with BALSAMI TOTALE.",
                                                                    "Daily use.",
                                                                    
                                                                     ],
                                                        
                                                            presentation:[
                                                                    "10 ml. / 300 ml. / 1 lt. / 2 lt."
                                                                ],
                                                            photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2FSHAMPOO%20TOTALE.png?alt=media&token=fefb1871-5c55-434a-94b2-ba34a3f83632",
                                                            video: "https://www.youtube.com/watch?v=tdUOLTkr9kU",
                                                            dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Ftec_italy_productos_ficha_tecnica_HD1_shampoo_totale_ing.pdf?alt=media&token=c87049f6-dc0e-4a5b-b7e8-0a46942a7e07"
        
                                                            },

                                                        {
                                                            title: "Balsami Presto",
                                                            description: [
                                                   "Intense revitalizing treatment for hair.",
                                                   "Leave in. Intense treatment for colored and normal hair. Brings softness and shine back. Improves manageability and reduces frizz.",
                                                   "Developed with TRICONE and TRICOERBA, protein and botanical compounds that help improve hair’s appearance.",
                                                   "Protects hair from UV rays thanks to its TRICEL-R complex."
                                
                                
                                
                                                            ],
                                                            bullets:[
                                                                "Apply a small amount on clean, humid hair.",
                                                                "Style as desired."
                
                                                
                                                
                                                                ],
                                                        
                                                            presentation:[
                                                                    "10 ml. / 300 ml. / 1 lt."
                                                                ],
                                                            photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F6CD-BALSAMI-PRESTO-TRATAMIENTO.png?alt=media&token=cbe296f7-8153-4078-9020-22b47f086838",
                                                                video: "https://www.youtube.com/watch?v=lQa3Pis3sng",
                                                                dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Ftec_italy_productos_ficha_tecnica_CD1_balsami_presto_tratamiento_ing.pdf?alt=media&token=60f6f1c9-5169-4bf7-b8aa-4e69b2b27804"
                                                            },
                        
                                                            {
                                                                title: "Balsami Presto Shampoo",
                                                                description: [
                                                                "Fortifying and revitalizing shampoo for hair.",
                                                                "Its formula contains natural ingredients which provide strength and protection to damaged hair.",
                                                                "Gives shine and manageability.",
                                                                "Enriched with TRICONE and TRICOERBA, protein and botanical compounds that help improve hair’s appearance.",
                                                                "Contains TRICEL-R complex to protect hair from UV rays."
                                    
                                                                ],
                                                                bullets:[
                                                                    "Apply a small amount, massaging lightly.",
                                                                    "Rinse.",
                                                                    "Apply BALSAMI PRESTO LEAVE-IN TREATMENT."
                    
                                                    
                                                    
                                                                    ],
                                                            
                                                                presentation:[
                                                                        "10 ml. / 300 ml."
                                                                    ],
                                                                photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F6CD-BALSAMI-PRESTO-SHAMPOO.png?alt=media&token=1bb06b27-1b6d-4664-9934-6d288f1c6cbf",
                                                                    video: "https://www.youtube.com/watch?v=lQa3Pis3sng",
                                                                    dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Ftec_italy_productos_ficha_tecnica_CD1_balsami_presto_shampoo_ing.pdf?alt=media&token=12fbf292-9571-4e2b-b498-222a3951668a"
                                                                },

                                                                {
                                                                    title: "Balsami Totale",
                                                                    description: [
                                                                      "Conditioner for all hair types.",
                                                                      "his conditioner helps revitalize the hair strand, giving it back its moisture, softness and shine.",
                                                                      "Developed with TRICONE and TRICOERBA, protein and botanical compounds that help improve hair’s appearance.",
                                                                      "Contains TRICEL-R complex that protects hair against UV rays."
                                                                       
                                                           
                                                                    ],
                                                                    bullets:[
                                                                        "Apply on clean, humid hair.",
                                                                        "Leave in for 3-5 minutes, and rinse.",
                                                                        "Daily use.",
                                                                             ],
                                                                
                                                                    presentation:[
                                                                            "10 ml. / 300 ml. / 1 lt. / 2 lt."
                                                                        ],
                                                                    photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2Fbalsami%20totale%20final.png?alt=media&token=d277247e-8952-4efe-9a0e-1a09e29da93a",
                                                                        video: "https://www.youtube.com/watch?v=tdUOLTkr9kU",
                                                                        dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Ftec_italy_productos_ficha_tecnica_HD1_balsami_totale_ing.pdf?alt=media&token=4d96fc41-2fdd-40cd-ad69-c599e38bbe53"
                                                                    },
                                
        
    





    ],
    
    
    



    //Espanol::::::
    
    [   
        
        {
            title: "Hi Moisturizing Treatment",
            description: [
                "Mascarilla de hidratación profunda para el cabello.",
                "Su fórmula enriquecida con compuestos naturales a base de oligosacáridos de caña de azúcar, miel de abeja, aloe vera y aceite de cupuaçu; hidrata a profundidad cada hebra devolviendo la sedosidad, humectación y brillo."
         
                
                
            ],
            bullets:[
                "Sobre el cabello limpio y húmedo distribuya uniformemente HI-MOISTURIZING TREATMENT. Puede aplicar calor a temperatura media.",
                "Deje actuar de 20-30 minutos y enjuague por completo.",
                "Estilice como desee.",
                "Usar una vez por semana.",


                     ],
        
            presentation:[
                    "10 ml. / 280 gr."
                ],
            photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2FHIMOISTTREAT.png?alt=media&token=077bf62b-2e80-4530-8a42-f8773bbaacd3",
                vide0: "https://www.youtube.com/watch?v=KvpVIgGtjeE",
                dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_MD1_hi_moisturizing_treatment_esp.pdf?alt=media&token=c13ac38a-99bd-466a-9506-0f13d81820e7"
            },

            {
                title: "Hi Moisturizing Conditioner",
                description: [
                    "Acondicionador hidratante para cabello dañado y reseco.",
                    "Su fórmula altamente hidratante ayuda a recuperar la humectación del cabello.",
                    "Enriquecido con compuestos naturales a base de oligosacáridos, devuelve la suavidad y manejabilidad con resultados inmediatos."
                    
                    
                ],
                bullets:[
                    "Aplique sobre el cabello húmedo y limpio, deje actuar de 3-5 minutos.",
                    "Enjuague."
    
    
                         ],
            
                presentation:[
                        "10 ml. / 300 ml."
                    ],
                photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2FHIMOISTCOND.png?alt=media&token=8a39a447-5562-4d30-934d-eb30d9c1bd8f",
                    video: "https://www.youtube.com/watch?v=FiTTTCrY2S0",
                    dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_MD1_hi_moisturizing_conditioner_esp.pdf?alt=media&token=cb09e239-1c5c-42e5-88f1-0389ac825f82"

                },

                {
                    title: "Hi Moisturizing Shampoo",
                    description: [
                       "Shampoo hidratante para cabello dañado y reseco.",
                       "Shampoo altamente hidratante y libre de sulfatos para una limpieza suave.",
                       "Su fórmula enriquecida con compuestos naturales a base de oligosacáridos ayuda a restaurar la hidratación y humectación con resultados inmediatos."

                    ],
                    bullets:[
                        "Aplique una pequeña cantidad dando un ligero masaje.",
                        "Enjuague.",
                        "Continúe con HI-MOISTURIZING CONDITIONER.",
                        "Recomendable para uso diario.",
                        
        
        
                             ],
                
                    presentation:[
                            "10 ml. / 300 ml."
                        ],
                    photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F2MD-HI-MOISTURIZING-SHAMPOO.png?alt=media&token=684c1f54-f9ad-4bf4-abd2-fd42810ab78d",
                        video: "https://www.youtube.com/watch?v=FiTTTCrY2S0",
                        dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_MD1_hi_moisturizing_shampoo_esp.pdf?alt=media&token=7ad809bd-241c-4e48-be9a-9f935623e60e"
                    },
                    {
                        title: "Due Faccetta Giorno Per Giorno",
                        description: [
                          "Tratamiento hidratante intensivo de uso diario para el cabello.",
                          "Tratamiento en dos fases que hidrata y acondiciona el cabello.",
                          "Ideal para cabello normal o seco. Facilita el desenredo y mejora la manejabilidad.",
                          "Enriquecido con TRICONE y TRICOERBA, complejos proteínicos y botánicos que ayudan a mejorar la apariencia del cabello.",
                          "Desarrollado con TRICOSIL, que aporta suavidad, y TRICEL-R, que protege el cabello de los rayos UV."


                        ],
                        bullets:[
                            "Agite el contenido hasta obtener una mezcla homogénea.",
                            "Aplique sobre cabello limpio y ligeramente húmedo.",
                            "No enjuague.",
                            "Estilice como desee.",
                            "Uso diario."
                            
                            
            
            
                                 ],
                    
                        presentation:[
                                "300 ml."
                            ],
                        photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2FDFGPG.png?alt=media&token=b4c90ab9-ec59-4b79-81e1-e336293fa50e",
                            video: "https://www.youtube.com/watch?v=8x_lYJkoUVw&t",
                            dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_MD1_due_facceta_giorno_esp.pdf?alt=media&token=d42c6e35-2225-4ca8-b097-07929bc09377"

                        },

                        {
                            title: "Shampoo Totale",
                            description: [
                                "Shampoo acondicionador para cabello seco y maltratado.",
                
                                "Ideal para cabello maltratado. Acondiciona y humecta la hebra capilar, devolviendo la sedosidad, manejabilidad y brillo.",
                
                                "Ayuda a reparar el daño en el cabello.",
                                "Enriquecido con TRICONE y TRICOERBA, complejos proteínicos y botánicos que ayudan a mejorar la apariencia del cabello.",
                                "Contiene TRICEL-R, complejo que protege el cabello de los rayos UV."
                   
                            ],
                            bullets:[
                                "Aplique una pequeña cantidad dando un ligero masaje.",
                                "Enjuague.",
                                "Continúe con BALSAMI TOTALE.",
                                "Uso diario.",
                                    
                                     ],
                        
                            presentation:[
                                    "10 ml. / 300 ml. / 1 lt. / 2 lt."
                                ],
                            photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2FSHAMPOO%20TOTALE.png?alt=media&token=fefb1871-5c55-434a-94b2-ba34a3f83632",
                            video: "https://www.youtube.com/watch?v=x3j38yZU_n8",
                            dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_HD1_shampoo_totale_esp.pdf?alt=media&token=cbe7e74a-a095-4bbe-8b1c-9093472516d7"
                            },

                        {
                            title: "Balsami Presto",
                            description: [
                   "Intense revitalizing treatment for hair.",
                   "Leave in. Intense treatment for colored and normal hair. Brings softness and shine back. Improves manageability and reduces frizz.",
                   "Developed with TRICONE and TRICOERBA, protein and botanical compounds that help improve hair’s appearance.",
                   "Protects hair from UV rays thanks to its TRICEL-R complex."



                            ],
                            bullets:[
                                "Apply a small amount on clean, humid hair.",
                                "Style as desired."

                
                
                                ],
                        
                            presentation:[
                                    "10 ml. / 300 ml. / 1 lt."
                                ],
                            photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F6CD-BALSAMI-PRESTO-TRATAMIENTO.png?alt=media&token=cbe296f7-8153-4078-9020-22b47f086838",
                                video: "https://www.youtube.com/watch?v=lQa3Pis3sng",
                                dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Ftec_italy_productos_ficha_tecnica_CD1_balsami_presto_tratamiento_ing.pdf?alt=media&token=60f6f1c9-5169-4bf7-b8aa-4e69b2b27804"
                            },

                            {
                                title: "Balsami Presto Shampoo",
                                description: [
                                "Fortifying and revitalizing shampoo for hair.",
                                "Its formula contains natural ingredients which provide strength and protection to damaged hair.",
                                "Gives shine and manageability.",
                                "Enriched with TRICONE and TRICOERBA, protein and botanical compounds that help improve hair’s appearance.",
                                "Contains TRICEL-R complex to protect hair from UV rays."
    
                                ],
                                bullets:[
                                    "Apply a small amount, massaging lightly.",
                                    "Rinse.",
                                    "Apply BALSAMI PRESTO LEAVE-IN TREATMENT."

                    
                    
                                    ],
                            
                                presentation:[
                                        "10 ml. / 300 ml."
                                    ],
                                photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F6CD-BALSAMI-PRESTO-SHAMPOO.png?alt=media&token=1bb06b27-1b6d-4664-9934-6d288f1c6cbf",
                                    video: "https://www.youtube.com/watch?v=lQa3Pis3sng",
                                    dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Ftec_italy_productos_ficha_tecnica_CD1_balsami_presto_shampoo_ing.pdf?alt=media&token=12fbf292-9571-4e2b-b498-222a3951668a"
                                },
                                {
                                    title: "Balsami Totale",
                                    description: [
                                        "Acondicionador para todo tipo de cabello.",
                                        "Acondicionador que ayuda a revitalizar la hebra capilar, devolviendo la humectación, suavidad y brillo.",
                                        "Desarrollado con TRICONE y TRICOERBA, complejos proteínicos y botánicos que ayudan a mejorar la apariencia del cabello.",
                                        "Contiene TRICEL-R, complejo que protege el cabello contra los rayos UV."
                                       
                           
                                    ],
                                    bullets:[
                                        "Aplique sobre el cabello húmedo y limpio.",
                                        "Deje actuar de 3-5 minutos y enjuague.",
                                        "Uso diario.",
                                             ],
                                
                                    presentation:[
                                            "10 ml. / 300 ml. / 1 lt. / 2 lt."
                                        ],
                                    photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2Fbalsami%20totale%20final.png?alt=media&token=d277247e-8952-4efe-9a0e-1a09e29da93a",
                                    video: "https://www.youtube.com/watch?v=UIJDBvj_s7E",
                                    dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_HD1_balsami_totale_esp.pdf?alt=media&token=789296a8-f69a-4ef9-b45f-8722ccc8d88f"
            
                                    },

        




        ]







        ]
    },


    "styling": {
        title: "Styling",
        bg: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/BG%2Fstyling%20banner.jpg?alt=media&token=a02aa8a6-9bf3-4147-88a4-90d335ae6ab1",
        products: [
         //// Ingles:::::
            [    
               
         {
                                            title: "Speciale",
                                            description: [
                                                "Lightweight leave-in hair treatment.",
                                                "Lightweight leave-in treatment with exclusive benefits for all hair types; provides silkiness and shine.",
                                                "Protects from heat styling tools and allows control frizz, enhancing the texture and natural hair volume.",
                                                "Preserves the color with UVA and UVB protectors, preventing the damage caused by chlorine and sun.",
                                                "Facilitates detangling providing manageability and seals the cuticle to prevent split ends."
                
                                         
                                                
                                                
                                            ],
                                            bullets:[
                                                "Apply a small portion in the palm of your hand.",
                                                "Distribute evenly on damp hair.",
                                                "Do not rinse.",
                                                "Proceed to stylize.",
                                                "Daily use."
                                                     ],
                                        
                                            presentation:[
                                                    "125 ml."
                                                ],
                                            photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2Fspeciale.png?alt=media&token=b996c3cb-7906-42bf-9df5-ca144383de4e",
                                            dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Ftec_italy_productos_ficha_tecnica_DD1_speciale_ing.pdf?alt=media&token=27c9477f-93fd-4ccb-b54a-6850f7195c53"

                                
                                            },
                                            {
                                                title: "Scultore Fine",
                                                description: [
                                               "Liquid hair gel. Styles and defines curls.",
                                               "Liquid gel with a soft fix. Ideal for setting and defining curls with movement.",
                                               "Provides shape, texture and shine to hair.",
                                               "Enriched with Panthenol and TRICONE, protein complex that helps improve hair’s appearance.",
                                               "Protects the hair from UV rays thanks to its TRICEL-R complex."
                                    
                                             
                                                    
                                                    
                                                ],
                                                bullets:[
                                                    "Apply a small amount on humid, sectioned hair and style."
                                                         ],
                                            
                                                presentation:[
                                                        "10 ml. / 300 ml. / 1 lt."
                                                    ],
                                                photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2FSCULTORE%20FINE.png?alt=media&token=d40ea15f-f3f0-44b6-8b6d-9b2f4998308a",
                                                dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Ftec_italy_productos_ficha_tecnica_DD1_scultore_fine_ing.pdf?alt=media&token=3e2bc9db-cc64-4dfe-8266-1c599a8fb5bc"

                                    
                                                },

                                                {
                                                    title: "Riccioli Conditioner",
                                                    description: [
                                                "Conditioner for curly hair. Provides softness and definition.",
                                                "Contributes to define curls, giving them a natural look. Provides moisture and elasticity to hair.",
                                                "Developed with TRICONE and TRICOERBA, protein and botanical compounds that help improve hair’s appearance; TRICOSIL, which provides moisture and softness, and TRICEL-R complex to protect hair against UV rays."
                                                 
                                                        
                                                        
                                                    ],
                                                    bullets:[
                                                        "Apply on wet, clean hair.",
                                                        "Leave in for 3-5 minutes.",
                                                        "Rinse.",
                                                        "Daily use.",
                                                             ],
                                                
                                                    presentation:[
                                                            "300 ml."
                                                        ],
                                                    photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F8DD-RICCIOLI-CONDITIONER.png?alt=media&token=2f418383-e761-426e-9f4d-613467465520",
                                                        video: "https://www.youtube.com/watch?v=xCM9hCGhc2U",
                                                        dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_DD1_riccioli_system_conditioner_esp.pdf?alt=media&token=6ef7b10c-d876-410c-b118-7b47ac76e292"
                                                    },
                                
                                                    {
                                                        title: "Riccioli Shampoo",
                                                        description: [
                                                            "Shampoo for curly hair. Provides softness and definition.",
                                                            "Contributes to define curls, giving them a natural look.",,
                                                            "Developed with TRICONE and TRICOERBA, protein and botanical compounds that help improve hair’s appearance; TRICOSIL, which provides moisture and softness, and TRICEL-R complex to protect hair against UV rays."
                                                            
                                                        ],
                                                        bullets:[
                                                            "Apply a small amount, massaging lightly.",
                                                            "Rinse.",
                                                            "Continue with RICCIOLI CONDITIONER.",
                                                            "Daily use."
                                                                 ],
                                                    
                                                        presentation:[
                                                                "300 ml."
                                                            ],
                                                        photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F8DD-RICCIOLI-SHAMPOO.png?alt=media&token=6d3f6bcd-d31e-43ab-93b5-c0d539cb8232",
                                                            video: "https://www.youtube.com/watch?v=xCM9hCGhc2U",
                                                            dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Ftec_italy_productos_ficha_tecnica_DD1_riccioli_system_shampoo_ing.pdf?alt=media&token=52c039db-125d-4e31-8b92-de375f41fe38"

                                                        },


                                                    {
                                                        title: "Riccioli Leave In Cream",
                                                        description: [
                                                            "Cream for curly hair. Provides softness and definition.",
                                                            "Contributes to define curls with a natural look, without weighing down hair. ",,
                                                            "Developed with TRICONE and TRICOERBA, protein and botanical compounds that help improve hair’s appearance; TRICOSIL, which provides moisture and softness, and TRICEL-R complex to protect hair against UV rays."

                                                        ],
                                                        bullets:[
                                                            "Apply a small amount on humid, sectioned hair.",
                                                            "Style as desired.",
                                                            "Air dry or diffuse."
                                                                 ],
                                                    
                                                        presentation:[
                                                                "300 ml."
                                                            ],
                                                        photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2FRICCIOLILEAVEIN.png?alt=media&token=1d5137d1-ced1-4e8f-9a67-151ec745b095",
                                                            video: "https://www.youtube.com/watch?v=xCM9hCGhc2U",
                                                            dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Ftec_italy_productos_ficha_tecnica_DD1_riccioli_system_ing.pdf?alt=media&token=d601299c-c5f3-4f12-aa5a-f52551f632e1"

                                                        },
    
                                                        {
                                                            title: "Metamorfosi Conditioner",
                                                            description: [
                                                                    "Temporary straightening conditioner for hair.",
                                                                    "Developed with an exclusive straightening compound that detangles and provides softness and shine.",
                                                                    "Enriched with TRICONE and TRICOERBA, protein and botanical compounds that help improve hair’s appearance.",
                                                                    "Contains TRICOSIL to condition and moisturize hair, and TRICEL-R complex to protect hair against UV rays."

                                                            ],
                                                            bullets:[
                                                                "Apply on clean, humid hair.",
                                                                "Leave in for 3-5 minutes and rinse."
                                                                     ],
                                                        
                                                            presentation:[
                                                                    "10 ml. / 300 ml."
                                                                ],
                                                            photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2FMETACOND.png?alt=media&token=a857a4b2-9554-4452-a6c8-1efd45c33fd6",
                                                                video: "https://www.youtube.com/watch?v=gerqmU6e66k"
                                                            },

                                                            {
                                                                title: "Metamorfosi Shampoo",
                                                                description: [
                                                                        "Temporary straightening shampoo for hair.",
                                                                        "Developed with an exclusive straightening compound that detangles and provides softness and shine.",
                                                                        "Enriched with TRICONE and TRICOERBA, protein and botanical compounds that help improve hair’s appearance.",
                                                                        "Contains TRICOSIL to condition and moisturize hair, and TRICEL-R complex to protect hair against UV rays."
                                                                ],
                                                                bullets:[
                                                                    "Apply a small amount with a light massage.",
                                                                    "Rinse.",
                                                                    "Continue with METAMORFOSI CONDITIONER."
                                                                         ],
                                                            
                                                                presentation:[
                                                                        "10 ml. / 300 ml. / 1 lt."
                                                                    ],
                                                                photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F8DD-METAMORFOSI-SHAMPOO.png?alt=media&token=41535ee9-cc9e-415e-8812-3312c368d3b1",
                                                                video: "https://www.youtube.com/watch?v=gerqmU6e66k&t",
                                                                    dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Ftec_italy_productos_ficha_tecnica_DD1_metamorfosi_shampoo_ing.pdf?alt=media&token=c5fc8a36-07b8-4d91-ae76-9b6421eadab2"

                                                                },

                                                                {
                                                                    title: "Metamorfosi Leave In Cream",
                                                                    description: [
                                                                    "Temporary hair straightening cream. Leave-in.",
                                                                    "Cream designed to work with heat styling tools.",
                                                                    "Leaves hair feeling silky and with shiny.",
                                                                    "Provides thermal protection and protects the hair from humidity, keeping it straight for longer.",
                                                                    "Helps control volume and frizz."
                                
                                
                                                                    ],
                                                                    bullets:[
                                                                        "Distribute the necessary amount over clean, slightly humid hair.",
                                                                        "Style with a blow dryer and brush.",
                                                                        "Seal with a straightening iron.",
                                                                        "For better results, work in small sections.",
                                                                        
                                                                             ],
                                                                
                                                                    presentation:[
                                                                            "10 ml. / 300 ml. / 1 lt."
                                                                        ],
                                                                    photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F8DDMETAMORFOSI-LEAVE-IN-CREAM.png?alt=media&token=1e46fb3a-1a4b-4be6-ab29-6d61c6eeec79",
                                                                    video: "https://www.youtube.com/watch?v=gerqmU6e66k&t",
                                                                        dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Ftec_italy_productos_ficha_tecnica_DD1_metamorfosi_leave_in_cream_ing.pdf?alt=media&token=48d23019-e4cf-4cdf-bd08-cc469f42260a"

                                                                    },

                                                                    {
                                                                        title: "Modellare Gel Battuto",
                                                                        description: [
                                                                            "Alcohol-free whipped gel. Volume and flexible fixation for hair.",
                                                                            "Whipped emulsified alcohol-free hair gel.",
                                                                            "Developed to work alongside heat styling tools, creates hairstyles full of volume, body and movement.",
                                                                            "Enriched with TRICONE and TRICOERBA, protein and botanical compounds that help improve hair’s appearance."

                                    
                                    
                                                                        ],
                                                                        bullets:[
                                                                            "Apply on clean, slightly humid hair.",
                                                                            "Distribute evenly through each section and style with heat styling tools."
                                                                            
                                                                                 ],
                                                                    
                                                                        presentation:[
                                                                                "280 gr."
                                                                            ],
                                                                        photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F8DD-MODELLARE-GEL-BATTUTO2.png?alt=media&token=01361a96-0b3d-4f56-a2d5-ac8c3dc3bba9",
                                                                            dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Ftec_italy_productos_ficha_tecnica_DD1_modellare_gel_battuto_ing.pdf?alt=media&token=c36508cf-97f1-4f7b-9191-cf73b90f2682"
                                                                        },


                                            {
                                                title: "Gel Della Cera",
                                                description: [
                                                  "Hair gel wax with medium hold natural look.",
                                                  "Ideal for shaping, molding and creating modern styles.",
                                                  "Enriched with TRICONE, protein complex that helps improve hair’s appearance."
            
                                                ],
                                                bullets:[
                                                    "Apply a small amount on your hand and spread evenly on dry hair.",
                                                    "Style as desired."
                                                    
                                                         ],
                                            
                                                presentation:[
                                                        "280 gr."
                                                    ],
                                                photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F8DD-GEL-DELLA-CERA-EFFETTO-NORMALE2.png?alt=media&token=e132d8d8-509b-47bd-b5b8-261c50a39803",
                                                    video: "https://www.youtube.com/watch?v=xN7dL8E-x08",
                                                    dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Ftec_italy_productos_ficha_tecnica_DD1_gel_della_cera_effetto_normale_ing.pdf?alt=media&token=7521788d-b397-44ac-b866-d490865d8006"
                                                },
                                                {
                                                    title: "Pazzicera",
                                                    description: [
                                                      "Texturizing hair wax.",
                                                      "Soft fix wax. Designed to weigh down, control and texturize hair with a natural shiny finish."
                
                                                    ],
                                                    bullets:[
                                                        "Apply a small amount on your hand, rub and apply on hair.",
                                                        "Style as desired."
                                                        
                                                             ],
                                                
                                                    presentation:[
                                                            "56.6 gr."
                                                        ],
                                                    photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F8DD-PAZZICERA2.png?alt=media&token=75b5ee80-61cc-49f5-9c26-9f318ba17d25",
                                                        video: "https://www.youtube.com/watch?v=QpOM4L3V8tM",
                                                        dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Ftec_italy_productos_ficha_tecnica_DD1_pazzicera_ing.pdf?alt=media&token=11110beb-c482-47d0-9293-9e1d5b5f5e85"

                                                    },
                                                    {
                                                        title: "Pasta Della Aragña",
                                                        description: [
                                                          "Texturizing elastic hair wax. Medium to soft hold.",
                                                          "Elastic hair wax.",
                                                          "Its exclusive formula allows you to create volume and texture, provides great definition and helps shape hair to create modern hairstyles."


                                                        ],
                                                        bullets:[
                                                            "Apply a small amount on your hand, rub and move your hands like an applause, then apply on hair.",
                                                            "Style as desired."
                                                            



                                                                 ],
                                                    
                                                        presentation:[
                                                                "56.6 gr."
                                                            ],
                                                        photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F8DD-PASTA-DELLA-ARAGN%CC%83A2.png?alt=media&token=20f0adc8-4e18-487c-ba1b-54d9f4d67df2",
                                                            video: "https://www.youtube.com/watch?v=j8AaLH_a3m4",
                                                            dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Ftec_italy_productos_ficha_tecnica_DD1_pasta_della_aragna_ing.pdf?alt=media&token=d8b8ad45-a322-44d3-b858-e1440c080410"

                                                        },

                                                        {
                                                            title: "Gellini",
                                                            description: [
                                                             "Gel de alta fijación para el cabello.",
                                                             "Innovador gel de alta fijación y secado rápido.",
                                                                "Permite crear estilos con aspecto húmedo.",
                                                                "Su exclusiva formula es reactivable con el agua, no deja residuos y se retira fácilmente.",
                                                                "Enriquecido con TRICONE, complejo proteínico que ayuda a mejorar la apariencia del cabello, y TRICEL-R, complejo que protege el cabello de los rayos UV."


                                                            ],
                                                            bullets:[
                                                           
                                                                "Aplicar sobre cabello limpio y húmedo.",
                                                                "Estilice como desee. Una vez seco, no cepille.",
                                                                "Para reactivar, aplique un poco de agua y estilice."
    
    
    
                                                                     ],
                                                        
                                                            presentation:[
                                                                    "10 ml. / 300 ml."
                                                                ],
                                                            photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2FGELLINI.png?alt=media&token=c320f194-64b9-4fab-922b-531f879b6ed3",
                                                            dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Ftec_italy_productos_ficha_tecnica_DD1_gellini_ing.pdf?alt=media&token=a27a0778-e612-482a-8b43-5943b9264626"

                                                
                                                            },

                                                            {
                                                                title: "Tutto L'effetto",
                                                                description: [
                                                                 "Sensational strong-hold aerosol spray.",
                                                                 "Dries quickly to facilitate the most demanding and delicate hairstyles.",
                                                                 "Provides great shine and protects hair from the effects of humidity and moisture.",
                                                                 "Can be easily removed and leaves no residue.",
                                                                 "Gives the final touch to any creative hairstyle, enabling the client to hold shape and style throughout the day. Does not leave hair dull."


                                                                ],
                                                                bullets:[
                                                               
                                                                    "Shake product.",
                                                                    "Apply TUTTO L’EFFETTO onto dry hair evenly over the style at a distance of 8 inches (30 cm)."
        
        
                                                                         ],
                                                            
                                                                presentation:[
                                                                        "350 ml."
                                                                    ],
                                                                photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F8DD-TUTTO-LEFFETTO.png?alt=media&token=dd7995e9-dea9-48c0-ad95-26d0afd61e0c"
                                                    
                                                                },
                                                                
                                                                {
                                                                    title: "Silk System Shine",
                                                                    description: [
                                                                        "Serum for hair. Provides shine and softness.",
                                                                        "Finishing serum for hair. Enriched with silk protein which provides shine and softness.",
                                                                        "Helps control frizz and seal the cuticle, preventing split ends.",
                                                                        "Reduces the harming effects that heat styling tools have on the hair strand."
                                                                        
                                                                        
                                                                    ],
                                                                    bullets:[
                                                                        "Apply a small amount on damp or dry hair.",
                                                                        "Style as desired."
                                                                             ],
                                                                
                                                                    presentation:[
                                                                            "60 ml. / 125 ml. / 300 ml."
                                                                        ],
                                                                    photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F7SD-SILK-SYSTEM-SHINE.png?alt=media&token=5e5c803a-6ab6-47da-afc3-f06991219262",
                                                                        video: "https://www.youtube.com/watch?v=_qZ5Z2p9dzI",
                                                                        dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Ftec_italy_productos_ficha_tecnica_SD1_silk_system_shine_ing.pdf?alt=media&token=96c2114c-a92e-4c6e-bd0a-4f671c0f7b71"
                                                                    },
                                
                        
                        
                                                                    {
                                                                        title: "Silk System Shine Shampoo",
                                                                        description: [
                                                                            "Shampoo for dry and dull hair. Sulfate-free.",
                                                                            "Enriched with silk amino acids that provide softness and shine.",
                                                                            "Its sulfate-free formula grants a gentle cleanse that is safe for color treated hair.",
                                                                            "Contains TRICONE and TRICOERBA, protein and botanical compounds that help improve hair’s appearance.",
                                                                            "Developed with TRICOSIL, which provides softness, and TRICEL-R, to protect hair from UV rays."
                                                                        ],
                                                                        bullets:[
                                                                            "Apply a small amount, massaging lightly.",
                                                                            "Rinse.",
                                                                            "Continue with SILK SYSTEM SHINE CONDITIONER.",
                                                                            "Daily use."
                                                                                 ],
                                                                    
                                                                        presentation:[
                                                                                "10 ml. / 300 ml."
                                                                            ],
                                                                        photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2FSSSSHAMPOO.png?alt=media&token=8f91989f-1251-4739-9069-4a7066ca5041",
                                                                            video: "https://www.youtube.com/watch?v=_qZ5Z2p9dzI",
                                                                            dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Ftec_italy_productos_ficha_tecnica_SD1_silk_system_shine_shampoo_ing.pdf?alt=media&token=3870bc3d-3fb4-42b4-b93c-70ffcaab44e4"
                        
                                                                        },
                        
                                                                        {
                                                                            title: "Silk System Shine Conditioner",
                                                                            description: [
                                                                               "Conditioner for dry and dull hair.",
                                                                               "Complements SILK SYSTEM SHINE SHAMPOO’s action.",
                                                                               "Enriched with silk amino acids that provide softness and shine.",
                                                                               "Contributes to return hair’s moisture and manageability.",
                                                                               "Contains TRICONE and TRICOERBA, protein and botanical compounds that help improve hair’s appearance."
                                                                            ],
                                                                            bullets:[
                                                                              "Apply on humid, clean hair.",
                                                                              "Leave in for 3-5 minutes and rinse.",
                                                                              "Daily use."
                                                                                     ],
                                                                        
                                                                            presentation:[
                                                                                    "10 ml. / 300 ml."
                                                                                ],
                                                                            photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2FSSSCOND.png?alt=media&token=3e2bafde-d51f-415b-a4eb-e3428a1ea343",
                                                                                video: "https://www.youtube.com/watch?v=_qZ5Z2p9dzI",
                                                                                dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Ftec_italy_productos_ficha_tecnica_SD1_silk_system_shine_conditioner_ing.pdf?alt=media&token=2ab4564e-8c5a-4fb5-9caf-09ff84d0802b"
                                                                            },
                                                            
                                                                            {
                                                                                title: "Silk System Shine Aerosol",
                                                                                description: [
                                                                                  "Innovative spray shine treatment that helps seal and revitalize hair ends and smooth the cuticle.",
                                                                                  "Gives incredible shine, nutrition, hydration, and smoothness.",
                                                                                  "The silk protein formulation insulates the hair shaft from the damage caused by heating tools during blow drying, hair straightening and curling processes."
                                                        
                                                        
                                                                                ],
                                                                                bullets:[
                                                                                  
                                                                                    "Shake the container.",
                                                                                    "Apply SILK SHINE SYSTEM on dry hair at a distance of 8 inches (30 cm) uniformly throughout the hair."
                                                        
                                                                                         ],
                                                                            
                                                                                presentation:[
                                                                                        "200 gr."
                                                                                    ],
                                                                                photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F7SD-SILK-SYSTEM-SHINE-AEROSOL.png?alt=media&token=8a438334-9cf3-4594-a158-ef4dee2d1bc1",
                                                                                    video: "https://www.youtube.com/watch?v=_qZ5Z2p9dzI",
                                                                                    dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_SD1_silk_system_shine_aerosol_esp.pdf?alt=media&token=9458a1dd-0ab9-4e90-a7e9-b41eb9c6c69a"
                                                                                },
                            
                        
                        
                        





    ],
    
    
    



    //Espanol::::::
    
    [   
        
        {
            title: "Speciale",
            description: [
            "Tratamiento ligero para el cabello que se deja puesto.",
            "Tratamiento ligero con beneficios exclusivos, para todo tipo de cabello, otorga sedosidad y brillo.",
            "Protege de las herramientas de calor y permite controlar el frizz, realzando la textura y volumen natural del cabello.",
            "Preserva el color con protectores UVA y UVB, previniendo el daño causado por el cloro y el sol.",
            "Facilita el desenredo otorgando manejabilidad y sella la cutícula para evitar las puntas abiertas."

         
                
                
            ],
            bullets:[
                "Aplique una pequeña porción en la palma de la mano.",
                "Distribuya uniformemente sobre el cabello húmedo.",
                "No enjuague.",
                "Estilice a su gusto.",
                "Uso diario.",
                     ],
        
            presentation:[
                    "125 ml."
                ],
            photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2Fspeciale.png?alt=media&token=b996c3cb-7906-42bf-9df5-ca144383de4e",
                video: "https://www.youtube.com/watch?v=nVS4DOWjApI",
                dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_DD1_speciale_esp.pdf?alt=media&token=69beafbd-a48a-4c44-ab9a-e32160e976e4"
            },

            {
                title: "Scultore Fine",
                description: [
               "Gel líquido para el cabello. Moldea y marca rizos.",
               "Gel líquido de fijación suave. Ideal para marcar y definir rizos con movimiento. Brinda cuerpo, textura y brillo al cabello.",
               "Enriquecido con Pantenol y TRICONE, complejo proteínico que ayuda a mejorar la apariencia del cabello.",
               "Protege el cabello de los rayos UV gracias al complejo TRICEL-R."
    
             
                    
                    
                ],
                bullets:[
                    "Sobre cabello húmedo, aplique una pequeña cantidad por secciones y estilice."
                         ],
            
                presentation:[
                        "10 ml. / 300 ml. / 1 lt."
                    ],
                photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2FSCULTORE%20FINE.png?alt=media&token=d40ea15f-f3f0-44b6-8b6d-9b2f4998308a",
                video: "https://www.youtube.com/watch?v=FYfZf3SFO8Y",
                dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_DD1_scultore_fine_esp.pdf?alt=media&token=14526888-e4a9-4826-a8cf-5bc9c6bbecc6"
                },

                {
                    title: "Riccioli Conditioner",
                    description: [
              "Acondicionador para cabello rizado. Brinda suavidad y definición.",
              "Contribuye a definir los rizos brindando una apariencia natural.",
              "Aporta humectación y elasticidad al cabello. Desarrollado con TRICONE y TRICOERBA, complejos proteínicos y botánicos que ayudan a mejorar la apariencia del cabello; TRICOSIL, que aporta humectación y suavidad, y TRICEL-R, complejo que protege el cabello contra los rayos UV."
                        
                        
                    ],
                    bullets:[
                        "Aplique sobre el cabello húmedo y limpio.",
                        "Deje actuar de 3-5 minutos.",
                        "Enjuague.",
                        "Uso diario.",
                             ],
                
                    presentation:[
                            "300 ml."
                        ],
                    photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F8DD-RICCIOLI-CONDITIONER.png?alt=media&token=2f418383-e761-426e-9f4d-613467465520",
                        video: "https://www.youtube.com/watch?v=WT1o2Gx6MVw"
                    },

                    {
                        title: "Riccioli Shampoo",
                        description: [
                            "Shampoo para cabello rizado. Brinda suavidad y definición.",
                            "Contribuye a definir los rizos brindando una apariencia natural.",
                            "Desarrollado con TRICONE y TRICOERBA, complejos proteínicos y botánicos que ayudan a mejorar la apariencia del cabello; TRICOSIL, que aporta humectación y suavidad, y TRICEL-R, complejo que protege el cabello contra los rayos UV."
                            
                            
                        ],
                        bullets:[
                            "Aplique una pequeña cantidad dando un ligero masaje.",
                            "Enjuague.",
                            "Continúe con RICCIOLI CONDITIONER.",
                            "Uso diario."
                                 ],
                    
                        presentation:[
                                "300 ml."
                            ],
                        photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F8DD-RICCIOLI-SHAMPOO.png?alt=media&token=6d3f6bcd-d31e-43ab-93b5-c0d539cb8232",
                            video: "https://www.youtube.com/watch?v=4fhA8VOc8s4",
                            dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_DD1_riccioli_system_shampoo_esp.pdf?alt=media&token=874befbd-b309-4115-bc07-661203158bee"
                        },

                        {
                            title: "Riccioli Leave In Cream",
                            description: [
                                    "Crema para cabello rizado. Brinda suavidad y definición.",
                                    "Contribuye a definir los rizos con una apariencia natural, sin dejar el cabello pesado.",
                                    "Desarrollado con TRICONE y TRICOERBA, complejos proteínicos y botánicos que ayudan a mejorar la apariencia del cabello; TRICOSIL, que aporta humectación y suavidad, y TRICEL-R, complejo que protege el cabello contra los rayos UV."


                            ],
                            bullets:[
                                "Sobre cabello húmedo, aplicar una pequeña cantidad por secciones.",
                                "Estilice como desee.",
                                "Seque al aire o con difusor."
                                     ],
                        
                            presentation:[
                                    "300 ml."
                                ],
                            photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2FRICCIOLILEAVEIN.png?alt=media&token=1d5137d1-ced1-4e8f-9a67-151ec745b095",
                                video: "https://www.youtube.com/watch?v=jc9pdZPoCqQ",
                                dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Ftec_italy_productos_ficha_tecnica_DD1_riccioli_system_ing.pdf?alt=media&token=d601299c-c5f3-4f12-aa5a-f52551f632e1"
                            },

                            {
                                title: "Metamorfosi Conditioner",
                                description: [
                                        "Acondicionador alaciante temporal para el cabello.",
                                        "Desarrollado con un exclusivo complejo alaciante que brinda desenredo, suavidad y brillo.",
                                        "Enriquecido con TRICONE y TRICOERBA, complejos proteínicos y botánicos que ayudan a mejorar la apariencia del cabello; y TRICOSIL, que acondiciona y humecta. Contiene TRICEL-R, complejo que protege el cabello contra los rayos UV."
    
                                ],
                                bullets:[
                                    "Aplique sobre el cabello húmedo y limpio.",
                                    "Deje actuar de 3-5 minutos y enjuague."
                                         ],
                            
                                presentation:[
                                    "10 ml. / 300 ml."
                                    ],
                                photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2FMETACOND.png?alt=media&token=a857a4b2-9554-4452-a6c8-1efd45c33fd6",
                                    video: "https://www.youtube.com/watch?v=P4-nPZ7WZJk", 
                                    dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_DD1_metamorfosi_conditioner_esp.pdf?alt=media&token=6e84c6ad-ff67-4171-a2e0-ded69721a806"
                                },

                                {
                                    title: "Metamorfosi Shampoo",
                                    description: [
                                           "Shampoo alaciante temporal para el cabello.",
                                            "Desarrollado con un exclusivo complejo alaciante que brinda desenredo, suavidad y brillo.",
                                            "Enriquecido con TRICONE y TRICOERBA, complejos proteínicos y botánicos que ayudan a mejorar la apariencia del cabello; y TRICOSIL, que acondiciona y humecta.",
                                            "Contiene TRICEL-R, complejo que protege el cabello contra los rayos UV."



                                    ],
                                    bullets:[
                                        "Aplique una pequeña cantidad dando un ligero masaje.",
                                        "Enjuaque.",
                                        "Continúe con METAMORFOSI CONDITIONER."
                                             ],
                                
                                    presentation:[
                                            "10 ml. / 300 ml. / 1 lt."
                                        ],
                                    photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F8DD-METAMORFOSI-SHAMPOO.png?alt=media&token=41535ee9-cc9e-415e-8812-3312c368d3b1",
                                        video: "https://www.youtube.com/watch?v=UBmpXWHZ8LY",
                                        dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_DD1_metamorfosi_shampoo_esp.pdf?alt=media&token=402a738f-1809-415b-96d2-7f1de80feed5"
                                    },
        

                                    {
                                        title: "Metamorfosi Leave In Cream",
                                        description: [
                                             "Alaciante temporal en crema para el cabello. Se deja puesto.",
                                               "Crema diseñada para trabajar con las herramientas de calor.",
                                               "Deja el cabello sedoso y con brillo. Brinda protección térmica y protege el cabello de la humedad, manteniéndolo alaciado por más tiempo.",
                                               "Ayuda a controlar el volumen y frizz."
    
    
                                        ],
                                        bullets:[
                                            "Sobre cabello limpio y ligeramente húmedo, distribuya la cantidad necesaria.",
                                            "Estilice con secadora y cepillo.",
                                            "Sellar con plancha.",
                                            "Para mejores resultados, trabaje con secciones pequeñas."
                                                 ],
                                    
                                        presentation:[
                                                "10 ml. / 300 ml. / 1 lt."
                                            ],
                                        photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F8DDMETAMORFOSI-LEAVE-IN-CREAM.png?alt=media&token=1e46fb3a-1a4b-4be6-ab29-6d61c6eeec79",
                                            video: "https://www.youtube.com/watch?v=19cLWz-Gv4c",
                                            dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_DD1_metamorfosi_leave_in_cream_esp.pdf?alt=media&token=78bbd5a8-b146-46d4-8ea6-17e6504682a7"
                                        },

                                        {
                                            title: "Modellare Gel Battuto",
                                            description: [
                                              "Gel batido sin alcohol. Volumen y fijación flexible para el cabello.",
                                              "Gel batido y emulsionado sin alcohol.",
                                              "Desarrollado para trabajar con herramientas de calor, permite un estilizado con cuerpo, volumen y movimiento.",
                                              "Enriquecido con TRICONE y TRICOERBA, complejos proteínicos y botánicos que ayudan a mejorar la apariencia del cabello."

        
        
                                            ],
                                            bullets:[
                                                "Aplique sobre cabello limpio y ligeramente húmedo.",
                                                "Distribuya uniformemente en cada sección y estilice con herramienta de calor."
                                                
                                                     ],
                                        
                                            presentation:[
                                                    "280 gr."
                                                ],
                                            photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F8DD-MODELLARE-GEL-BATTUTO2.png?alt=media&token=01361a96-0b3d-4f56-a2d5-ac8c3dc3bba9",
                                                video: "https://www.youtube.com/watch?v=REtmtZZNf9I",
                                                dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_DD1_modellare_gel_battuto_esp.pdf?alt=media&token=d368ed87-e22d-4a24-840f-6014b7d0d46b"

                                            },

                                            {
                                                title: "Gel Della Cera",
                                                description: [
                                                  "Cera gel de fijación media con acabado natural para el cabello.",
                                                  "Ideal para dar forma, moldear y crear estilos modernos.",
                                                  "Enriquecido con TRICONE, complejo proteínico que ayuda a mejorar la apariencia del cabello."

            
            
                                                ],
                                                bullets:[
                                                    "Coloque una pequeña cantidad en su mano y distribuya sobre cabello seco.",
                                                    "Estilice como desee."
                                                    
                                                         ],
                                            
                                                presentation:[
                                                        "280 gr."
                                                    ],
                                                photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F8DD-GEL-DELLA-CERA-EFFETTO-NORMALE2.png?alt=media&token=e132d8d8-509b-47bd-b5b8-261c50a39803",
                                                    video: "https://www.youtube.com/watch?v=5dqrMmX5gOI",
                                                    dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_DD1_gel_della_cera_effetto_humedo_esp.pdf?alt=media&token=775ced9b-1dee-44bb-bc57-f52266404a08",

                                                },

                                                {
                                                    title: "Pazzicera",
                                                    description: [
                                                      "Cera texturizante para el cabello.",
                                                      "Cera de fijación suave. Diseñada para dar peso, control y textura al cabello y un acabado con brillo natural."
                                                    ],
                                                    bullets:[
                                                        "Coloque una pequeña cantidad en su mano, frote y aplique en el cabello.",
                                                        "Estilice como desee."
                                                             ],
                                                
                                                    presentation:[
                                                            "56.6 gr."
                                                        ],
                                                    photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F8DD-PAZZICERA2.png?alt=media&token=75b5ee80-61cc-49f5-9c26-9f318ba17d25",
                                                        video: "https://www.youtube.com/watch?v=1bUULUDaCOU",
                                                        dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_DD1_pazzicera_esp.pdf?alt=media&token=d3a11dcf-64c1-45af-8729-2bf5038b18ef"
                                                    },

                                                    {
                                                        title: "Pasta Della Aragña",
                                                        description: [
                                                          "Cera elástica para texturizar el cabello. Fijación media a suave.",
                                                          "Cera elástica para el cabello.",
                                                           "Su exclusiva fórmula permite crear volumen y textura, brinda gran definición y ayuda a dar forma al cabello para crear estilos modernos."



                                                        ],
                                                        bullets:[
                                                            "Coloque una pequeña cantidad en su mano, frote y mueva las manos como si estuviera aplaudiendo y aplique en el cabello.",
                                                            "Estilice como desee."
                                                                 ],
                                                    
                                                        presentation:[
                                                                "56.6 gr."
                                                            ],
                                                        photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F8DD-PASTA-DELLA-ARAGN%CC%83A2.png?alt=media&token=20f0adc8-4e18-487c-ba1b-54d9f4d67df2",
                                                            video: "https://www.youtube.com/watch?v=hhZiGlE7Xp0",
                                                            dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_DD1_pasta_della_aragna_esp.pdf?alt=media&token=37c2a737-44cd-4640-820e-c2438db06668"
                                                        },

                                                        {
                                                            title: "Gellini",
                                                            description: [
                                                             "Gel de alta fijación para el cabello.",
                                                             "Innovador gel de alta fijación y secado rápido.",
                                                                "Permite crear estilos con aspecto húmedo.",
                                                              "Su exclusiva formula es reactivable con el agua, no deja residuos y se retira fácilmente.",
                                                                "Enriquecido con TRICONE, complejo proteínico que ayuda a mejorar la apariencia del cabello, y TRICEL-R, complejo que protege el cabello de los rayos UV."


                                                            ],
                                                            bullets:[
                                                           
                                                                "Aplicar sobre cabello limpio y húmedo.",
                                                                "Estilice como desee. Una vez seco, no cepille.",
                                                                "Para reactivar, aplique un poco de agua y estilice."
    
    
    
                                                                     ],
                                                        
                                                            presentation:[
                                                                    "10 ml. / 300 ml."
                                                                ],
                                                            photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2FGELLINI.png?alt=media&token=c320f194-64b9-4fab-922b-531f879b6ed3",
                                                                video: "https://www.youtube.com/watch?v=wD-TTEGAlFo",
                                                                dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_DD1_gellini_esp.pdf?alt=media&token=355e351d-ae1e-4b81-a97a-eb6f723439fb"
                                                            },

                                                            {
                                                                title: "Tutto L'effetto",
                                                                description: [
                                                                 "Spray en aerosol de ﬁjación extrema, secado rápido y gran brillo, ideal para alto peinado.",
                                                                 "Protege contra la humedad y se puede quitar fácilmente sin dejar residuos. Es el toque ﬁnal para cualquier peinado."
    
                                                                ],
                                                                bullets:[
                                                               
                                                                    "Agitar el producto.",
                                                                    "Aplicar TUTO L’EFFETTO a una distancia de 30 cm. sobre el cabello seco de manera uniforme sobre el diseño de peinado ya terminado."
        
        
        
                                                                         ],
                                                            
                                                                presentation:[
                                                                        "350 ml."
                                                                    ],
                                                                photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F8DD-TUTTO-LEFFETTO.png?alt=media&token=dd7995e9-dea9-48c0-ad95-26d0afd61e0c",
                                                                    video: "https://www.youtube.com/watch?v=RU1NpiHGGfs"
                                                                },

                                                                {
                                                                    title: "Silk System Shine",
                                                                    description: [
                                                                     "Sérum para el cabello. Aporta brillo y suavidad.",
                                                                     "Sérum para finalizar el estilizado. Enriquecido con proteína de seda la cual aporta brillo y suavidad al cabello.",
                                                                     "Ayuda a controlar el frizz y sellar la cutícula, previniendo la formación de puntas abiertas.",
                                                                     "Disminuye el efecto de las herramientas de calor sobre la hebra capilar."
                                                                        
                                                                        
                                                                    ],
                                                                    bullets:[
                                                                        "Aplique una pequeña cantidad y distribuya sobre el cabello húmedo o seco.",
                                                                        "Estilice como desee."
                                                                             ],
                                                                
                                                                    presentation:[
                                                                            "60 ml. / 125 ml. / 300 ml."
                                                                        ],
                                                                    photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F7SD-SILK-SYSTEM-SHINE.png?alt=media&token=5e5c803a-6ab6-47da-afc3-f06991219262",
                                                                    dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_SD1_silk_system_shine_esp.pdf?alt=media&token=57f0becc-5ce2-4fb8-a781-781663b6b9f9"
                                                        
                                                        
                                                                    },
                                                        
                                                        
                                                                    {
                                                                        title: "Silk System Shine Shampoo",
                                                                        description: [
                                                                    "Shampoo para cabello reseco y sin brillo. Libre de sulfatos.",
                                                                    "Enriquecido con aminoácidos de seda que aportan suavidad y brillo.",
                                                                    "Su fórmula sin sulfatos brinda una limpieza suave y es segura para el cabello teñido.",
                                                                    "Contiene TRICONE y TRICOERBA, complejos proteínicos y botánicos que ayudan a mejorar la apariencia del cabello.",
                                                                    "Desarrollado con TRICOSIL, que aporta suavidad, y TRICEL-R, que protege el cabello de los rayos UV."
                                                                            
                                                                        ],
                                                                        bullets:[
                                                                            "Aplique una pequeña cantidad dando un ligero masaje.",
                                                                            "Enjuague.",
                                                                            "Continúe con SILK SYSTEM SHINE CONDITIONER.",
                                                                            "Uso diario."
                                                                                 ],
                                                                    
                                                                        presentation:[
                                                                                "10 ml. / 300 ml."
                                                                            ],
                                                                        photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2FSSSSHAMPOO.png?alt=media&token=8f91989f-1251-4739-9069-4a7066ca5041",
                                                                            video: "https://www.youtube.com/watch?v=8X2MT2DaCS4",
                                                                            dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_SD1_silk_system_shine_shampoo_esp.pdf?alt=media&token=6d107ba9-a565-433b-8943-a52f3ae4b7ec"
                                                                        },
                                                            
                                                                        {
                                                                            title: "Silk System Shine Conditioner",
                                                                            description: [
                                                                             "Acondicionador para cabello reseco y sin brillo.",
                                                                             "Complementa la función de SILK SYSTEM SHINE SHAMPOO.",
                                                                             "Enriquecido con aminoácidos de seda que aportan gran suavidad y brillo. Contribuye a devolver la hidratación y manejabilidad al cabello.",
                                                                             "Contiene TRICONE y TRICOERBA, complejos proteínicos y botánicos que ayudan a mejorar la apariencia del cabello."
                                                        
                                                                            ],
                                                                            bullets:[
                                                                            
                                                                                "Aplique sobre el cabello húmedo y limpio, deje actuar de 3-5 minutos.",
                                                                                "Enjuague.",
                                                                                "Uso diario."
                                                        
                                                        
                                                                                     ],
                                                                        
                                                                            presentation:[
                                                                                    "10 ml. / 300 ml."
                                                                                ],
                                                                            photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2FSSSCOND.png?alt=media&token=3e2bafde-d51f-415b-a4eb-e3428a1ea343",
                                                                                video: "https://www.youtube.com/watch?v=D7a3ENgbd8Y",
                                                                                dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Fspanish%2Ftec_italy_productos_ficha_tecnica_SD1_silk_system_shine_conditioner_esp.pdf?alt=media&token=8dc463a9-81e0-472e-b5fc-e27ac7cb2dc9"
                                                        
                                                                            },
                                                        
                                                                            {
                                                                                title: "Silk System Shine Aerosol",
                                                                                description: [
                                                                                  "Innovador tratamiento en spray para sellar y revitalizar las puntas y cutículas del cabello.",
                                                                                  "Brinda protección para antes de planchar el cabello, dejando un aspecto súper liso, con gran caída, brillo y movimiento."
                                                        
                                                        
                                                                                ],
                                                                                bullets:[
                                                                                  
                                                                                    "Lavar el cabello con un shampoo adecuado y prepararlo para el planchado.",
                                                                                    "Agitar el envase y aplicar SILK SYSTEM SHINE sobre el cabello seco a una distancia de 30 cm. de manera uniforme por todo el cabello."
                                                        
                                                        
                                                                                         ],
                                                                            
                                                                                presentation:[
                                                                                        "200 gr."
                                                                                    ],
                                                                                photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/products%2F7SD-SILK-SYSTEM-SHINE-AEROSOL.png?alt=media&token=8a438334-9cf3-4594-a158-ef4dee2d1bc1",
                                                                                    video: "https://www.youtube.com/watch?v=tVWenDJ8QV4",
                                                                                    dataSheet: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/datasheets%2Ftec_italy_productos_ficha_tecnica_SD1_silk_system_shine_aerosol_ing.pdf?alt=media&token=b9b4c5c8-43d8-4a22-a346-a9457c76d6d9"
                                                        
                                                                                },
    




        ]







        ]
    }
}

export default productsArray