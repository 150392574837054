import React from 'react';


const MonthPromo = () => {
    return (
        <div>
        <img src={"https://firebasestorage.googleapis.com/v0/b/smssubscribe-ceb82.appspot.com/o/CRBEAUTY%20PROMOS%20NOV-02.png?alt=media&token=708f72be-cdb8-43b1-a837-03c139f16a01"}
            style={{
                maxHeight: window.innerHeight<1400 ? "90vh": "90vh",
                maxWidth: window.innerWidth<800 ? "100vw": "100vw" 
              
              }}
        />

        <img src={"https://firebasestorage.googleapis.com/v0/b/smssubscribe-ceb82.appspot.com/o/CRBEAUTY%20PROMOS%20NOV-01.png?alt=media&token=8c129ac7-becd-46e1-a37d-f8b44eeb49c6"}
            style={{
                maxHeight: window.innerHeight<1400 ? "90vh": "90vh",
                maxWidth: window.innerWidth<800 ? "100vw": "100vw" 
              
              }}
        />
        
        
    
        </div>
    );
}

export default MonthPromo;
