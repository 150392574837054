import ReactPlayer from 'react-player/vimeo'
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';

import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
  root: {
      color: "#182028",
      height: 350,
    maxWidth: 345,
    fontFamily: "Barlow",
    // maxHeight: 400,
    // borderRadius: "20px",
    // WebkitBoxShadow: "2px 2px 2px 0px rgba(0,0,0,0.75)",
    // MozBoxShadow: "2px 2px 2px 0px rgba(0,0,0,0.75)",
    // BoxShadow: "2px 2px 2px 0px rgba(0,0,0,0.75)",
   background: "transparent"
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: "#182028",
    color: "white"
  },
}));

export default function Video(props) {
  const classes = useStyles();
 

  return (
    <div className="row" >
           <div className="col-sm-6">
        
    <Card className={classes.root} >
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            {props.vid.title.charAt(0)}
          </Avatar>
        }
      
        title={props.vid.title}
        // subheader="September 14, 2016"
        
      />


   <ReactPlayer url={props.vid.url} controls="true"  width= "345px" style={{marginTop: "-50px"}}/>
     
    </Card>
    </div>
</div>
  );
}
