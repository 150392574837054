

import React, { useState, useContext, useEffect } from "react";
import { useSpring, animated } from "react-spring";
import { AppContext } from "../src/AppContext";

import Blog from '../src/Blog'
import "../src/aboutStylesheet.css"
import Flip from 'react-reveal/Flip';



const files=[
{
  title: "New Permanent Pazzis",
  photo: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/blog%2Fnew%20pazzi%20colors.jpg?alt=media&token=060d059e-b617-44fc-b02d-d9c406d596dd",
  url: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/blog%2FNew%20Permanent%20Pazzis%201.26.2021.pdf?alt=media&token=2e52bf55-c2c3-4ff4-afbc-4fd82ceefae1",
  description: "Tec Italy has released 3 new Pazzi (fantasy) shades"
},





]


const Blogs = () => {





  const props = useSpring({ opacity: 1, from: { opacity: 0 } });
  const [dimesinoW, setDimensionW] = useState(window.innerWidth);
  const langContext = useContext(AppContext);
  const [titlesLang, setTitlesLang] = useState(0);

  const chooseLang = () => {
    if (chooselll(langContext.engEsp)) {
      setTitlesLang(0);
      // console.log("titles", titles);
    } else {
      setTitlesLang(1);
    }
  };

  const chooselll = (x) => {
    if (x === "trueee") {
      return true;
    } else if (x === "falseee");
    {
      return false;
    }
  };

  useEffect(() => {
    console.log(langContext.engEsp);
    chooseLang();
    // setLang(lang === "falseee" ? "trueee" : "falseee");
  }, [langContext.engEsp]);

  const updateWidthAndHeight = () => {
    setDimensionW(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  });








  return (
    <div>

<header
        className="w3-display-container w3-content w3-wide"
        style={{
          maxWidth: "3000px",
          // maxHeight: "1000px"
        }}
        id="home"
      >
        <animated.div style={props}>
          <img
            className="w3-image"
            src="https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/BG%2FMETA%20BG.Jpg?alt=media&token=c09e120c-d98b-4952-ade1-d67d0d99c802"
     
          />

          <h1
            style={{
              textAlign: "center",
              position: "absolute",
              top: dimesinoW < 680 ? "40%" : "50%",
              left: "50%",
              fontFamily: "Barlow",
              fontSize: dimesinoW < 750 ? "50px" : "120px",
              color: "white",
              transform: "translate(-50%, -50%)",
              textShadow: "2px 2px 4px black",
            }}
          >
            <Flip left>
           Blog
           </Flip>
          </h1>
          </animated.div>
      <div 
      className="w3-content"
      style={{  
        position: "absolute",
        marginTop: "10px",
        left: "50%",
        transform: "translate(-50%)",  zIndex: "1" }}
          >
            <div>
            
          <div
                style={{
 
                  margin: "auto",

                  display: "inline-flex",
                  justifyContent: "space-around",
                  flexWrap: "wrap",
                }}
              >
   {files.map((vid)=>{
    return(
     
     
     
     
     <div  style={{ maxWidth: "1100px" , position: "relative"}}>
      <div className="w3-row padding-recipeCards"  id="about">
    
    
  
    <Blog vid={vid}/>
 
    </div>
       </div>
    
    )

          
           
    
    
   
   
   })}
   
              </div>
    </div>
   </div>





   </header>
    </div>
  );
}

export default Blogs;
