import React, { useState, useEffect } from "react";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PromosTecNewFormat from '../src/PromosTecNewFormat'
import FlashDeals from '../src/FlashDeals'
import Blogs from '../src/Blogs'
import Videos from '../src/Videos'
import ColorChart from '../src/ColorChart'
import Products from '../src/Products'
import AllProducts from '../src/AllProducts'
import MonthPromo from "../src/MonthPromo";
import ProductPage from '../src/ProductPage'
import ContactPage from '../src/ContactPage'
import Home from '../src/Home'
import MapModalMonth from "./MapModalMonth";
import MapModalDay from "./MapModalDay";



import {
  CssBaseline,
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core";

const theme = createMuiTheme({
  typography: {
    button: {
      textTransform: "none",
    },
  },
});




function Routes() {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />

      <Router>
        <Switch>
        <Route exact path='/november2022' component={MonthPromo} />
        <Route exact path="/" component={Home} />

          <Route exact path="/salonpro" component={PromosTecNewFormat} />
          <Route exact path="/flashdeals" component={FlashDeals} />

          {/* <Route exact path="/products" component={Products} /> */}
          <Route exact path="/contact" component={ContactPage} />
          <Route exact path="/videos" component={Videos} />
        
          <Route exact path="/crbeautyday" component={MapModalDay} />
          <Route exact path="/crbeautymonth" component={MapModalMonth} />
          <Route exact path="/colorchart" component={ColorChart} />

          <Route exact path="/blog" component={Blogs} />
          <Route exact path="/all" component={AllProducts} />
          <Route exact path="/omnirestore" component={Products} />
          <Route exact path="/professional" component={Products} />
          <Route exact path="/blondeplex" component={Products} />
          <Route exact path="/colorcare" component={Products} />
          <Route exact path="/repair" component={Products} />
          <Route exact path="/scalpsolution" component={Products} />
          <Route exact path="/moisture" component={Products} />
          <Route exact path="/styling" component={Products} />
          <Route exact path="/:productpage" component={ProductPage} />
          <Route exact path="/crbeauty/:month" component={MapModalMonth} />
          
            

         
          
        </Switch>

      </Router>
    </MuiThemeProvider>
  );
}

export default Routes;
