
import React, { useState } from "react";

/// components::::

import NavBar2 from "./NavBar2";
import { AppContext } from "./AppContext";
import Routes from "./Routes";



function App() {
  const [engEsp, setengEsp] = useState(true);
  return (
    <div>
      <div>
        <AppContext.Provider value={{ engEsp, setengEsp }}>
          <NavBar2 />
          <br></br>
          <br></br>
          <br></br>

     
          <Routes />
   
        </AppContext.Provider>

      
        
        <div>
         
    
        


                
     
        </div>
      
      </div>
     

    </div>
  );
}

export default App;
