
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';

import Avatar from '@material-ui/core/Avatar';


import {  Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "30px",
      color: "#182028",
      backgroundColor: "transparent",
    maxWidth: 345,
    fontFamily: "Barlow",
  height: 400,
    borderRadius: "20px",
    WebkitBoxShadow: "2px 2px 2px 0px rgba(0,0,0,0.75)",
    MozBoxShadow: "2px 2px 2px 0px rgba(0,0,0,0.75)",
    BoxShadow: "2px 2px 2px 0px rgba(0,0,0,0.75)",
   
  },
  media: {
   maxHeight: 290,
   maxWidth: 300,
    // paddingTop: '56.25%', // 16:9
    position: "relative",
top:  150,
  left: "50%",
  /* bring your own prefixes */
  transform: "translate(-50%, -50%)"
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: "#182028",
    color: "white"
  },
  link:{
    textDecoration: "none",
    "&:hover": {
      color: "white",

      textDecoration: "none",
    },
  }
}));

export default function Product(props) {
  const classes = useStyles();
 
// console.log(props )
  return (
    <div className="row" >
           <div className="col-sm-6">
        <Link
       className={classes.link}
        to={{
          pathname:`${props.product["title"].toLowerCase().replace(/\s/g, '')}`,
          state:{
            prod: props.product,
            dimension: props.dimension,

            prodAll: [props.productAll[0][props.index],props.productAll[1][props.index]] 
          }

        }}
        onClick={() => {
          window.scrollTo(0, 0);
        }}
        >
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            {
            props.product["title"].charAt(0)
            }
          </Avatar>
        }
      
        title={props.product["title"]}
        // subheader="September 14, 2016"
      />

<img src={props.product["photo"]}
className={classes.media}
>
</img>

      
     
    </Card>
    </Link>
    </div>
</div>
  );
}
