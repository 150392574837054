import React, { useState, useContext, useEffect } from "react";
import { useSpring, animated } from "react-spring";
import { AppContext } from "../src/AppContext";
import Promo from '../src/Promo'
import Flip from 'react-reveal/Flip';
import "../src/aboutStylesheet.css"
import ImageFadeIn from "react-image-fade-in"
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';




const promosArray = [


  {
    title: "Kuul Reconstructor",
    list: ["Buy 3 Kuul Reconstructor 1 kg."],
    price: "$29.99",
   value: "Regular price: $49.99",
    img: require("../src/promos/fotos/kuul promo.png"),
  },



  
    {
      title: "Blonde Plex Promo",
      list: ["1 Blonde Plex 680 gr.", 
      "3 Blonde Plex System Shampoo 300 ml.", 
      "3 Blonde Plex System Conditioner 300 ml."
      
      
   ],
      price: "$79.99",
       value: "Regular price: $98.75",
      img: require("../src/promos/fotos/blonde plex  promo 2 pic.png"),
    },
  
  
  
  
    {
      title: "25 Colores",
      list: ["25 Designer Colors.", 
      "1 Speciale 125 ml.", 
      "1 Peroxide 1 lt.",
      
      
   ],
      price: "$132.50",
      // savings: "$100.60",  
      img: require("../src/promos/fotos/25 colors deal.png"),
    },

    {
      title: "60 Colores",
      list: ["60 Designer Colors.", 
      "2 Peroxide 1 lt.", 
      "5 Luminous Bleach 50 gr.",
      "1 Due Faccetta Massimo 300 ml.",
     
   ],
      price: "$309.00",
      // savings: "$156.65", 
      img: require("../src/promos/fotos/60 colors deal.png"),
    },
  
  
    
    {
      title: "100 Colores",
      list: [
      "100 Designer Colors.", 
      "2 Peroxide 1 lt.",
      "1 Peroxide 2 lt.", 
      "1 Kuul Reconstructor 1 kg.",
   ],
      price: "$490.00",
      // savings: "$156.65", 
      img: require("../src/promos/fotos/100 colores.png"),
    },
  
  
    {
      title: "Paquete Elixir",
      list: ["Special Price!", ],
      price: "$28.99",
     value: "Regular price: $36.45",
      img: require("../src/promos/fotos/tec_italy_arium_micrositio_home_elixir-300x293.png"),
    },
  
    {
      title: "Paquete Serum Plex",
      list: ["Special Price!", ],
      price: "$39.99",
     value: "Regular price: $49.99",
      img: require("../src/promos/fotos/paquete serum plex.png"),
    },

    {
      title: "6 + 1",
      list: [
      "Due Faccetta massimo.", 
      "Lumina Shampoo 300 ml.",
      "Balsami Presto 300 ml.", 
      "Olio Vital.",
      "Olio Vital Color.",
      "Vitalita Amp.",
      "Ristruttura.",
      "Silk System Shine 300 ml.",
      "Silk System Shine 125 ml."
     ],
      price: "6+1",
      value: <span> 
        <li>Buy 6 bottles of the same product </li>
        <li>& Get 1 bottles free</li>
        </span>,
      img: require("../src/promos/fotos/6+1.png"),
    },

    {
      title: "18 + 3",
      list: [
      "Lumina Shampoo 300 ml.", 
      "Due Faccetta Massimo.",
      "Silk System Shine 300 ml."
       ],
      price: "18 + 3",
      value: <span> 
        <li>Buy 18 bottles of the same product </li>
        <li>& Get 3 bottle free</li>
        </span>,
      img: require("../src/promos/fotos/18+3.png"),
    },
  
  
  
   

  
  
  ];


















const PromosTecNewFormat = () => {


// debugger



  const props = useSpring({ opacity: 1, from: { opacity: 0 } });
  const [dimesinoW, setDimensionW] = useState(window.innerWidth);
  const langContext = useContext(AppContext);
  const [titlesLang, setTitlesLang] = useState(0);

  const chooseLang = () => {
    if (chooselll(langContext.engEsp)) {
      setTitlesLang(0);
      // console.log("titles", titles);
    } else {
      setTitlesLang(1);
    }
  };

  const chooselll = (x) => {
    if (x === "trueee") {
      return true;
    } else if (x === "falseee");
    {
      return false;
    }
  };

  useEffect(() => {
    console.log(langContext.engEsp);
    chooseLang();
    // setLang(lang === "falseee" ? "trueee" : "falseee");
  }, [langContext.engEsp]);

  const updateWidthAndHeight = () => {
    setDimensionW(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  });








  return (
    <div>

<header
        className="w3-display-container w3-content w3-wide"
        style={{
          maxWidth: "3000px",
          // // maxHeight: "1000px"
          // zIndex:-10
        }}
        id="home"
      >
        <animated.div style={props}>
          <ImageFadeIn
            className="w3-image"
            src={"https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/BG%2Fbanner%20june.jpg?alt=media&token=74555c73-ed25-48ce-8d88-1b2d2a15becc"}
     
          />

          <h1
            style={{
              textAlign: "center",
              position: "absolute",
              top: dimesinoW < 680 ? "40%" : "50%",
              left: "50%",
              fontFamily: 'Permanent Marker, cursive',
              fontSize: dimesinoW < 750 ? "50px" : "120px",
              color: "#8f9cd0",
              transform: "translate(-50%, -50%)",
              textShadow: "2px 2px 4px white",
             
            }}
          >
            <Flip left>
        June Promos
        </Flip>
          </h1>
          </animated.div>
      <div 
      className="w3-content"
      style={{  position: "absolute",
                marginTop: "10px",
                left: "50%",
                transform: "translate(-50%)",  zIndex: "1" }}
          >
            <div>
            
          <div
                style={{
 
                  margin: "auto",

                  display: "inline-flex",
                  justifyContent: "space-around",
                  flexWrap: "wrap",
                }}
              >
                {/* <h3 style={{
                 color: "#ff0084",
                 fontFamily:"Barlow"
                }}>
                  
                     Promotions available until Friday April 30th 2021</h3> */}


   {promosArray.map((prod, i)=>{
    return(
      <div  style={{ maxWidth: "1100px" , position: "relative"}}>
      <div className="w3-row padding-recipeCards"  id="about">
    <Promo product={prod}  
    // productAll={productsArray[window.location.pathname.replace("/", "")]["products"]} 
    index={i}
    dimension={window.location.pathname.replace("/", "")}
    
    
    />
    
    </div>
       </div>
    
    )

          
           
    
    
   
   
   })}
   
              </div>
    </div>
   </div>





   </header>
    </div>
  );
}

export default PromosTecNewFormat;
