import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import PhoneIcon from '@material-ui/icons/Phone';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import MailIcon from '@material-ui/icons/Mail'
class MapVisits extends Component {



  static defaultProps = {
     
    center: {
      lat: 40.6782,
      lng: -73.9442
    },
    zoom: 7
  };
 

ColorForReps = (visit) => {
     if (visit["rep"]=== "Jeffrey Zavala") {
         return "red"
     } 
    
        else if(visit["rep"]=== "Nelson Zavala"){

        return "#0a0091"
            } 
        else if( visit["rep"]==="Nestor Vides"){
        return "#3ba100"
        }
        else if( visit["rep"]==="Yunior Alcantara"){
            return "purple"
            }
        else if( visit["rep"]==="Blanca Vallejo"){
              return "#f5954c"
            }
        else if( visit["rep"]==="Maria Hernandez"){
              return "#65f54c"
            }
        else if( visit["rep"]==="Leslie Vides"){
              return "#65f54c"
            }
        else if( visit["rep"]==="Christopher Snowden"){
              return "#11bdb4"
            }


            else if( visit["rep"]==="Vielka Fuentes"){
              return "#425af5"
            }
     else {
         return "black"
     }
}

hover = (visit) => {

     alert(
`Name: ${visit["name"]}
Rep: ${visit["rep"]}
Date: ${visit["date"].substr(0, 10)}
Hour: ${parseInt(visit["date"].substr(11, 2))+ 3}:${visit["date"].substr(14, 2)} 
Address: ${visit["address"]}
Type: ${visit["type"]}
${visit["comments"] ? `Comments: ${visit["comments"]}` : "" }
`
)
}











  render() {
      console.log(this.props.visits[0]["date"].substr(8, 2))


    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '85vh', width: '100%',

      
      }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyAmKeHSGyBQmM2Q14jMfcE199cqlqQ8SFM"}}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >

{this.props.visits.map(  (visit) => {
  return (visit["type"]==="Phone Call Incoming" ||visit["type"]==="Phone Call Outgoing" ? 
     
     <PhoneIcon
     lat={visit.lat}
     lng={visit.long}
     text={visit["Account Name"]}
     style={{color:this.ColorForReps(visit)}}
     onClick={  () => {
        this.hover(visit)
     }}
   />
     : 
  visit["type"]==="Text Message"  ? 
  <MailIcon
     lat={visit.lat}
     lng={visit.long}
     text={visit["Account Name"]}
     style={{color:this.ColorForReps(visit)}}
     onClick={  () => {
        this.hover(visit)
     }}
   />
  : 
  visit["type"]==="Sample" ?  
  <LocalActivityIcon
  lat={visit.lat}
  lng={visit.long}
  text={visit["Account Name"]}
  style={{color:this.ColorForReps(visit)}}
  onClick={  () => {
     this.hover(visit)
  }}
/>: 
  
     <EmojiPeopleIcon
     lat={visit.lat}
     lng={visit.long}
     text={visit["Account Name"]}
     style={{color:this.ColorForReps(visit)}}
     onClick={  () => {
        this.hover(visit)
     }}
   />)
})}


         
        </GoogleMapReact>
      </div>
    );
  }
}
 
export default MapVisits;