

import React, { useState, useContext, useEffect } from "react";
import { useSpring, animated } from "react-spring";
import { AppContext } from "../src/AppContext";
import Flip from 'react-reveal/Flip';
import Footer from './Footer';
import Video from '../src/Video'
import SlideVideos from '../src/SlideVideos'
import "../src/aboutStylesheet.css"




const vimeo=[
{
  title: "Neutralizando Tonos Amarillos Con Los .22 De Tec Italy",
  url: "https://vimeo.com/442743582/ee1305f229",
},

{
  title: "Crea Un Efecto Dimensional haciendo Highlights con 3 diferente niveles de peróxido",
  url: "https://vimeo.com/441027617/e93c33ee2b",
},
{
  title: "Como Tonificar Después De Una Extracción de Color con Estrattore Trattante",
  url: "https://vimeo.com/441022386/88f57cd471",
},
{
  title: "Corte Bob en Capas Pequeño",
  url: "https://vimeo.com/439293073/1ff53d3e72",
},

{
  title: "Esttratore trattante con barrido de color",
  url: "https://vimeo.com/438920312/af7151cc1d",
},

{
  title: "Como Obtener Rojos Perfectos con Tec Italy",
  url: "https://vimeo.com/437934160/842e98f404",
},
{
  title: "Limpieza De Color usando Luminous Bleach, Shampoo Profondo y 10 vl Peroxido",
  url: "https://vimeo.com/437243423/ab20ae0765",
},
{
  title: "Aplicación De Colores Violetas Permanentes",
  url: "https://vimeo.com/436804625/fbc2e70e0c",
},
{
  title: "Decoloración usando Luminous Bleach y Serum Plex",
  url: "https://vimeo.com/436101400/a845f4e66b",
}




]


const Videos = () => {





  const props = useSpring({ opacity: 1, from: { opacity: 0 } });
  const [dimesinoW, setDimensionW] = useState(window.innerWidth);
  const langContext = useContext(AppContext);
  const [titlesLang, setTitlesLang] = useState(0);
  
  const chooseLang = () => {
    if (chooselll(langContext.engEsp)) {
      setTitlesLang(0);
      // console.log("titles", titles);
    } else {
      setTitlesLang(1);
    }
  };

  const chooselll = (x) => {
    if (x === "trueee") {
      return true;
    } else if (x === "falseee");
    {
      return false;
    }
  };

  useEffect(() => {
    console.log(langContext.engEsp);
    chooseLang();
    // setLang(lang === "falseee" ? "trueee" : "falseee");
  }, [langContext.engEsp]);

  const updateWidthAndHeight = () => {
    setDimensionW(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  });








  return (
    <div>

<header
        className="w3-display-container w3-content w3-wide"
        style={{
          maxWidth: "3000px",
          // maxHeight: "1000px"
        }}
        id="home"
      >
       
          <SlideVideos/>



      <div 
      className="w3-content"
      style={{  
        position: "absolute",
        marginTop: "10px",
        left: "50%",
        transform: "translate(-50%)",  zIndex: "1" }}
          >
            <div>
            
          <div
                style={{
 
                  margin: "auto",

                  display: "inline-flex",
                  justifyContent: "space-around",
                  flexWrap: "wrap",
                }}
              >
   {vimeo.map((vid)=>{
    return(
     
     
     
     
     <div  style={{ maxWidth: "1100px" , position: "relative"}}>
      <div className="w3-row padding-recipeCards"  id="about">
    <Video vid={vid}/>
    
    </div>
       </div>
    
    )

          
           
    
    
   
   
   })}
   
              </div>
    </div>
   </div>
   



   <Footer/>

   </header>  

   

    </div>
  );
}

export default Videos;
