import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import EmailIcon from "@material-ui/icons/Email";

import LanguageIcon from "@material-ui/icons/Language";
import PhoneIcon from "@material-ui/icons/Phone";
import Link from "@material-ui/core/Link";
import { AppContext } from "../src/AppContext";
import { Card,  CardContent, Typography } from "@material-ui/core";


import { FaTiktok } from "react-icons/fa";
import { 
  IoLogoFacebook,
  IoLogoInstagram,
  IoLogoYoutube

} from "react-icons/io";
// console.log(localStorage.getItem("lex"));
const useStyles = makeStyles({
  root: {
    // width: `${window.innerWidth - window.innerWidth * 0.35}px`,
    // height: `${window.innerHeight - window.innerWidth * 0.25}px`,
    position: "absolute",
    top: "53.5%",
    left: "50%",
    backgroundColor: "#9e88a8",
    transform: "translate(-50%, -50%)",
    height: "450px",
    minWidth: "250px",
    webkitBoxShadow: "7px 10px 5px 0px rgba(0,0,0,0.75)",
    mozBoxShadow: "7px 10px 5px 0px rgba(0,0,0,0.75)",
    boxShadow: "7px 10px 5px 0px rgba(0,0,0,0.75)",
  },

  whiteText: {
    color: "white",
    textAlign: "center",
    fontFamily: "Barlow",
    fontSize: "45px",
  },

  blackText: {
    textAlign: "center",
    fontSize: "2vw",

    fontFamily: "Barlow",
    marginTop: "-10px",
  },
  blackIcons: {
    color: "white",
    fontSize: "20px",
    marginRight: "5px",
    display: "inline-block",
    fontFamily: "Barlow",
    marginTop: "5px",
  },

  location: {
    fontSize: "1.3vw",
    marginBottom: "-10px",
    fontFamily: "Barlow",
  },

  linkText: {
    fontSize: "4vw",
    textAlign: "center",
    display: "inline-block",

    color: "white",
    fontFamily: "Barlow",
    textDecoration: "none",
    "&:hover": {
      color: "#8cb1ab",

      textDecoration: "none",
    },
  },


  linkicons: {
    fontSize: "35px",
    textAlign: "center",
    display: "inline-block",
margin: "0.5vw",
    color: "white",
    fontFamily: "Barlow",
    textDecoration: "none",
    "&:hover": {
      color: "#8cb1ab",

      textDecoration: "none",
    },
  },
});
const titlesEng = [
  "Contact"
];

const titles = [
"Contacto"
];
export default function ContactPhone() {
  const classes = useStyles();

  const langContext = useContext(AppContext);

  const [lang, setLang] = useState(langContext.engEsp);

  useEffect(() => {
    console.log(langContext.engEsp);
    setLang(lang === "falseee" ? "trueee" : "falseee");
  }, [langContext.engEsp]);

  // const handleChange = () => {
  //   setLang(lang === "falseee" ? "trueee" : "falseee");
  // };
  const chooseLang = () => {
    if (chooselll(langContext.engEsp)) {
      const x = titlesEng;
      return x;
    } else {
      const x = titles;
      return x;
    }
  };

  const chooselll = (x) => {
    if (x === "trueee") {
      return true;
    } else if (x === "falseee");
    {
      return false;
    }
  };

  return (
    <Card className={classes.root}>
      <header style={{ top: "-50%", left: "-50%" }}>
       
      </header>

      <CardContent>
        <br />
      
        <Typography className={classes.whiteText}>
          {chooseLang()[0]}
          
        </Typography>
       

        <div style={{ marginTop: "50px", textAlign: "center" }}>
          <Typography>
            <div className={classes.blackIcons}>
              <EmailIcon className={classes.blackIcons} />
            </div>
            <Typography className={classes.linkText}>
              <div>
                <Link
                  className={classes.linkText}
                  href="mailto:g.garay@cr-beauty.com"
                >
                  <h5 className={classes.linkText}>g.garay@cr-beauty.com</h5>
                </Link>
              </div>
            </Typography>
          </Typography>
          <br />
          <div>
            <div className={classes.blackIcons}>
              <PhoneIcon className={classes.blackIcons} />
            </div>
            <div className={classes.linkText}>
              <div>
                <Link className={classes.linkText} href="tel: +1 631 667 1574">
                  <h5 className={classes.linkText}>+1 631 667 1574</h5>
                </Link>
              </div>
            </div>
          </div>
          <br />
          <div>
            <div className={classes.blackIcons}>
              <LanguageIcon className={classes.blackIcons} />
            </div>
            <div className={classes.linkText}>
              <div>
                <Link className={classes.linkText} href="www.tecitalyny.com">
                  <h5 className={classes.linkText}>tecitalyny.com</h5>
                </Link>
              </div>
            </div>
          </div>


          <div>
            <div className={classes.linkicons}>
            <div>
                <Link target="_blank" className={classes.linkicons} href="www.facebook.com/TecItalyNY">
                <IoLogoFacebook></IoLogoFacebook>
                </Link>
              </div>
            </div>
            <div className={classes.linkicons}>
              <div>
                <Link target="_blank"className={classes.linkicons} href="www.instagram.com/tecitalyny/?hl=en">
                <IoLogoInstagram></IoLogoInstagram>
                </Link>
              </div>

              
            </div>

            <div className={classes.linkicons}>
              <div>
                <Link className={classes.linkicons} 
                href= "https://www.tiktok.com/@tecitalynortheast"
                target="_blank"
                >
                <FaTiktok></FaTiktok>
                </Link>
              </div>

           
              
            </div>
            <div className={classes.linkicons}>
              <div>
                <Link target="_blank"className={classes.linkicons} href="www.youtube.com/channel/UCR1TWOON7SjE096ABsZZOmw">
                <IoLogoYoutube></IoLogoYoutube>
                </Link>
              </div>

              
            </div>
          </div>


         

          {/* <div>
            <div className={classes.blackIcons}></div>
            <div className={classes.linkText}></div>
          </div>
          <div>
            <div className={classes.blackIcons}></div>
            <div className={classes.linkText}></div>
          </div> */}
        </div>
      </CardContent>
    </Card>
  );
}
