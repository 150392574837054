import React from 'react';
import { FaTiktok } from "react-icons/fa";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import { 

  IoMdVideocam, 
  IoIosColorPalette, 
 
  IoLogoFacebook,
  IoLogoInstagram,
  IoLogoYoutube,
 

  IoMdFlask } from "react-icons/io";

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import {Link} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
    },
    linkText2: {
      marginTop: "auto",
      bottom: 0,
      color: "white",
      textDecoration: "none",
      "&:hover": {
        color: "#8cb1ab",
  
        textDecoration: "none",
      },
    },

    footer: {
        opacity: "70%",
        position: "fixed",
        left: "0",
        bottom: 0,
        width: "100%",
        backgroundColor: "#182028",
        color: "white",
        textAlign: "center"
    }
    
  }));

const Footer = () => {
    const classes = useStyles();


    return (
        <div>
             <BottomNavigation 
                className={classes.footer}
              >
                 
          <Link className={classes.linkText2}
      href= "https://www.facebook.com/TecItalyNorthEast"
      target="_blank"
      >
       <IoLogoFacebook size={"2.5em"} style={{marginRight: "8px"}}/>
       </Link> 
        
       <Link className={classes.linkText2}
      href= "https://www.instagram.com/tecitalynortheast"
      target="_blank"
      >
       <IoLogoInstagram size={"2.5em"} style={{marginRight: "8px"}}/>
       </Link> 

    

       <Link className={classes.linkText2}
      href= "https://www.tiktok.com/@tecitalynortheast"
      target="_blank"
      >
       <FaTiktok size={"2.5em"} style={{marginRight: "8px"}}/>
       </Link> 

       <Link className={classes.linkText2}
      href= "https://www.youtube.com/channel/UCR1TWOON7SjE096ABsZZOmw"
      target="_blank"
      >
       <IoLogoYoutube size={"2.5em"} style={{marginRight: "8px"}}/>
       </Link> 
          </BottomNavigation >
        </div>
    );
}

export default Footer;
