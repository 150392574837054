import ReactPlayer from 'react-player/vimeo'
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import {
    Link
    } from "@material-ui/core";
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
  root: {
      color: "#182028",
      height: 350,
    maxWidth: 345,
    fontFamily: "barlow",
    // maxHeight: 400,
    // borderRadius: "20px",
    // WebkitBoxShadow: "2px 2px 2px 0px rgba(0,0,0,0.75)",
    // MozBoxShadow: "2px 2px 2px 0px rgba(0,0,0,0.75)",
    // BoxShadow: "2px 2px 2px 0px rgba(0,0,0,0.75)",
   background: "transparent"
  },
  media: {
    maxHeight: 290,
   maxWidth: 300,
    // paddingTop: '56.25%', // 16:9
    position: "relative",
top:  "40%",
  left: "50%",
  /* bring your own prefixes */
  transform: "translate(-50%, -50%)"
  },


  link:{
    textDecoration: "none",
    "&:hover": {
      color: "white",

      textDecoration: "none",
    },
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: "#182028",
    color: "white"
  },
}));

export default function Blog(props) {
  const classes = useStyles();
 

  return (
    <div className="row" >
           <div className="col-sm-6">
           <Link href={props.vid.url} target="_blank"
           className={classes.link}
           
           
           >
    <Card className={classes.root} >
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            {props.vid.title.charAt(0)}
          </Avatar>
        }
      
        title={props.vid.title}
        subheader={props.vid.description}
        
      />


<img src={props.vid.photo}
className={classes.media}
>
</img>
  
     
    </Card>
    </Link>
    </div>
</div>
  );
}

