
import React, { useState, useContext, useEffect } from "react";
import { useSpring, animated } from "react-spring";
import { AppContext } from "../src/AppContext";
import Product from '../src/Product'
import "../src/aboutStylesheet.css"
import ImageFadeIn from "react-image-fade-in"
import productsArray from './productsArray'
import Flip from 'react-reveal/Flip';
import { TextField } from "@material-ui/core";







const AllProducts = () => {



// console.log(productsArray)

    const [search, setSearch] = useState("");
  const props = useSpring({ opacity: 1, from: { opacity: 0 } });
  const [dimesinoW, setDimensionW] = useState(window.innerWidth);
  const langContext = useContext(AppContext);
  const [titlesLang, setTitlesLang] = useState(0);
  const [prodArray, setProdArray] = useState([]);
  const [fetchData, setFetchData] = useState([]);




  const chooseLang = () => {
    if (chooselll(langContext.engEsp)) {
      setTitlesLang(0);
      // console.log("titles", titles);
    } else {
      setTitlesLang(1);
    }
  };



 //// helper funcs::::

 const createAllArray = (obj) => {
    let returnArray= []
    let engArr=[]
    let espArr=[]
     Object.keys(obj).map((dimension) => {
      //    console.log(obj[dimension]["products"])
      engArr.push(...obj[dimension]["products"][0])
      espArr.push(...obj[dimension]["products"][1])
         
     })
     
     returnArray.push(engArr, espArr)

    //  console.log(returnArray)

     return returnArray
}


 const filteredProducts = (newProducts) => {
   


//   console.log(newProducts)
    if (search === "") {
      setProdArray(newProducts);
    } else {
     
      setProdArray(
        newProducts.map((item) => {
           return item.filter(
            (product) =>
                product.title.toLowerCase().includes(search.toLowerCase()) 
                 
              )
        })
      
      );
    }
  };
// console.log("prodArr", prodArray)

  useEffect(() => {
  
    setFetchData(createAllArray(productsArray));

  }, []);



  useEffect(() => filteredProducts(fetchData), [search, fetchData]);





 const updateSearch = (e) => {
    setSearch(e.target.value);
  };


  




  const chooselll = (x) => {
    if (x === "trueee") {
      return true;
    } else if (x === "falseee");
    {
      return false;
    }
  };

  useEffect(() => {
    // console.log(langContext.engEsp);
    chooseLang();
    // setLang(lang === "falseee" ? "trueee" : "falseee");
  }, [langContext.engEsp]);

  const updateWidthAndHeight = () => {
    setDimensionW(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  });








  return (
    <div>

<header
        className="w3-display-container w3-content w3-wide"
        style={{
          maxWidth: "3000px",
          // maxHeight: "1000px"
          zIndex:-10
        }}
        id="home"
      >
        <animated.div style={props}>
          <ImageFadeIn
            className="w3-image"
            src={"https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/BG%2Ftec%20products.jpg?alt=media&token=f9df354d-a01c-4828-a129-0026091e9040"}
     
          />

          <h1
            style={{
              textAlign: "center",
              position: "absolute",
              top: dimesinoW < 680 ? "40%" : "50%",
              left: "50%",
              fontFamily: "Barlow",
              fontSize: dimesinoW < 750 ? "50px" : "120px",
              color: "white",
              transform: "translate(-50%, -50%)",
              textShadow: "2px 2px 4px black",
             
            }}
          >
            <Flip left>
     {chooselll(langContext.engEsp) ? "All products" : "Todos los productos" }
        </Flip>
          </h1>
          </animated.div>
      <div 
      className="w3-content"
      style={{  position: "absolute",
                marginTop: "10px",
                left: "50%",
                transform: "translate(-50%)",  zIndex: "1" }}
          >
            <div>
            <TextField
          placeholder={chooselll(langContext.engEsp) ? "Search products" : "Buscar producto" }
         style={{
          position: "fixed",
          top: "30px",
          left: "50%",
          /* bring your own prefixes */
          transform: "translate(-50%, -50%)",
            display: "flex",
            minWidth: "100px",
            // textAlign: "center",
            // maxWidth: window.innerWidth-(window.innerWidth*.1),
            // border: "1px solid grey",
            // borderRadius: "5px",
            height: "40px",
            // width: "100%",
            padding: "2px 23px 2px 30px",
            // outline: 0,
            // backgroundColor: "#f5f5f5",
            zIndex: 2
         }}
    type= "text"
    value={search}
          onChange={updateSearch}
        />




          <div
                style={{
 
                  margin: "auto",

                  display: "inline-flex",
                  justifyContent: "space-around",
                  flexWrap: "wrap",
                }}
              >
   {

       prodArray<=0 ? null: 


      prodArray[0].map((prod, i)=>{
        //    console.log(prod)
        return(
          <div  style={{ maxWidth: "1100px" , position: "relative"}} key={i+prod}>
          <div className="w3-row padding-recipeCards"  id="about">
          
        <Product product={prod}  
        productAll={prodArray} 
        index={i}
        dimension={window.location.pathname.replace("/", "")}
        
        
        />
        
        </div>
           </div>
        
        )
    
              
               
        
        
       
       
       })
   }
   
              </div>
    </div>
   </div>





   </header>
    </div>
  );
}

export default AllProducts;
