import React, {useState, useEffect, useContext} from 'react';
import Select from '@material-ui/core/Select'
import Color from '../src/Color';
import firebase from 'firebase'
import { AppContext } from "./AppContext";
import PacmanLoader from "react-spinners/PacmanLoader";
const config = {
  apiKey: "AIzaSyBUed9tWcsf6R_hCTS4IDvgyY8Y2FVXhrQ",
  authDomain: "tecmktg-363f7.firebaseapp.com",
  databaseURL: "https://tecmktg-363f7.firebaseio.com",
  projectId: "tecmktg-363f7",
  storageBucket: "tecmktg-363f7.appspot.com",
  messagingSenderId: "1082246745641",
  appId: "1:1082246745641:web:28a29d172b527f808bd638",
  measurementId: "G-230352HTJK"
};
firebase.initializeApp(config)
const storage = firebase.storage();




/// Colores::::





const chart ={

  "Naturales": ["Naturales", "Naturals"],
  
  
  "Cenizos": ["Cenizos", "Ash"],

  "Cenizos Profundo": ["Cenizos Profundo", "Deep Ash"],

  "Nacarados": ["Nacarados", "Pearl"],

  "Dorados": ["Dorados", "Blonds"],
 
  "Bronces": ["Bronces", "Bronze"],
  "Cobres": ["Cobres", "Copper"],

  "Cobres Intenso": ["Cobres Intenso", "Intense Copper"],

  "Caobas": ["Caobas", "Mahogany"],

  "Rojos": ["Rojos", "Reds"],

  "Violetas": ["Violetas", "Violet"],

  "Chocolates": ["Chocolates", "Chocolate"],

  "Chocolates Obscuro": ["Chocolates Obscuro", "Brown Chocolate"],

  "Dorados Suaves": ["Dorados Suaves", "Smooth Blonds"],

  "Super Aclarantes": ["Super Aclarantes", "Super Lift"],

  "Intensificador de Color": ["Intensificador de Color", "Color Booster"],
  
  
  }





const ColorChart = () => {


  const [chartVal, setChartVal] = useState("Naturales");
  const [files, setFiles]= useState([])


////lang

const langContext = useContext(AppContext);




const chooselll = (x) => {
  if (x === "trueee") {
    return true;
  } else if (x === "falseee");
  {
    return false;
  }
};





  const handleChange = (event) => {
    const name = event.target.value;
    console.log(name)
    setChartVal(name);
    window.scrollTo(0, 0);
  }


  const getURLS = (x) => {
    
  const arr= x["items"]

  const path= []
  const results = []

    arr.map(  (element) => {
       path.push([element["location"]["path"], element["location"]["path"].substring(element["location"]["path"].lastIndexOf('/')+1) ])
      //  console.log(element["location"]["path"].substring(element["location"]["path"].lastIndexOf('/')+1))
          // console.log(path)
      

    })

    path.map((item) => {
      storage.ref(item[0]).getDownloadURL().then((url) => {
        results.push([url, item[1]])
        results.sort(function(a, b) { 
          // console.log("aaaa", parseInt(a[1].split('.').slice(0, -1).join('.')))
          // console.log("bbb", parseInt(b[1].split('.').slice(0, -1).join('.')))
        let aInt= parseInt(a[1].split('.').slice(0, -1).join('.'))
        let bInt = parseInt(b[1].split('.').slice(0, -1).join('.'))
          return  aInt > bInt  ? 1 : -1;
      })
        setFiles([...results])
      

      })

   
    })

 
 }


    const getFiles = async() => {
    let newFiles= await storage.ref(`products/colorChart/${chartVal}`).list()
   
   

    getURLS(newFiles)
  }

 


  useEffect(() => {
    getFiles()

    // chooseLang();
    // console.log(chartVal)
  }, [chartVal]);



  

// console.log(files)



    return (
        <div 
       
            >





<div style={{  position: "absolute",
marginTop: "100px",
      
          left: "50%",
          /* bring your own prefixes */
          transform: "translate(-50%)",  zIndex: "1" }}>
<Select fullWidth  value={chartVal} 

 onChange={handleChange}
style={{
   position: "-webkit-sticky", /* Safari */
   position: "sticky", zIndex: "1" ,
   color: "#182028",
   backgroundColor: 'transparent',
   fontWeight: 900,
   fontSize: "30px",
   fontFamily: "Barlow"
 
}}>

{Object.keys(chart).map( (x) => {
// console.log(chart[x][titlesLang])
// console.log(titlesLang)
return (<h5 value={x} defaultValue={x}   style={{

marginLeft: "7px"

}} >{chooselll(langContext.engEsp) ? chart[x][1] : chart[x][0]}</h5>)
})}

{/* {console.log(files)} */}
</Select>
<div className="w3-content" style={{ maxWidth: "1100px" , position: "relative"}}>
          <div className="w3-row padding-recipeCards " id="about">
              <div
                style={{
 
                  margin: "auto",

                  display: "inline-flex",
                  justifyContent: "space-around",
                  flexWrap: "wrap",
                }}
              >
  


           {
        

        files.length<=0 ? 
        <PacmanLoader style={{color: "#182028"}}/>
        
        : files.map((item) => {
          return <Color ruta={item[0]} name={item[1]}/>
        })
        
    }
         
  




    </div>
            </div>
          </div>
          </div>
          </div>
  
  














  
    );
}

export default ColorChart;