import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  submit: {
    marginTop: theme.spacing.unit * 3,
    margin: 5,
    background: "#a447a4",
    fontFamily: "arial",
    color: "white",
    "&:hover": {
      color: "yellow",
      background: "#a447a4",
      textDecoration: "none",
    },
  },

  text: {
    textAlign: "center",
    fontSize: "30px",
    color: "#a447a4",
    textShadow: "1px 2px 4px black",

    fontFamily: "arial",
  },
}));
const ModalOneButton = (props) => {
 

  

  return (
<Dialog open={props.open} onClick={props.fc1}>
      <div>
  
  
  
  
  <img
              src={props.ruta}
             
              style={{margin: "auto",
            
              position: "fixed",
              top: "50%",
              left: "50%",
              /* bring your own prefixes */
              transform: "translate(-50%, -50%)",
             
              
            width: "400px"
            }}
            
               />  
      
  
    
      </div>
</Dialog>
  );
};

export default ModalOneButton;
