
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PaletteIcon from '@material-ui/icons/Palette'
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import PhoneIcon from "@material-ui/icons/Phone";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import FacebookIcon from "@material-ui/icons/Facebook";
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import InstagramIcon from "@material-ui/icons/Instagram";
import { Link } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "30px",
      color: "#e2d5d9",
      backgroundColor: "transparent",
    maxWidth: 345,
    fontFamily: "Barlow",
  height: 400,
    borderRadius: "20px",
    WebkitBoxShadow: "2px 2px 2px 0px rgba(0,0,0,0.75)",
    MozBoxShadow: "2px 2px 2px 0px rgba(0,0,0,0.75)",
    BoxShadow: "2px 2px 2px 0px rgba(0,0,0,0.75)",
   
  },
  media: {
   maxHeight: 290,
   maxWidth: 300,
    // paddingTop: '56.25%', // 16:9
    position: "relative",
top:  150,
  left: "50%",
  /* bring your own prefixes */
  transform: "translate(-50%, -50%)"
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: "#e2d5d9",
    color: "white"
  },
  link:{
    textDecoration: "none",
    "&:hover": {
      color: "white",

      textDecoration: "none",
    },
  }
}));

export default function Promo(props) {
  const classes = useStyles();
 
console.log(props)
  return (
    <div className="row" >
           <div className="col-sm-6">
    
           <Card
        style={{
          borderRadius: "20px",
          WebkitBoxShadow: "7px 10px 5px 0px rgba(0,0,0,0.75)",
          MozBoxShadow: "7px 10px 5px 0px rgba(0,0,0,0.75)",
          BoxShadow: "7px 10px 5px 0px rgba(0,0,0,0.75)",
        }}
      >
        <CardHeader
          avatar={
            <Link href="/colorchart" target="_blank">
              <PaletteIcon
                aria-label="recipe"
                style={{ color: "#7d486c" }}
              ></PaletteIcon>
            </Link>
          }
          action={
            <Link href="tel:+1-631-667-1574" target="_blank">
              <PhoneIcon style={{ color: "#7d486c" }} />
            </Link>
          }
          title={props.product["title"]}
        //   subheader="element"
          style={{ color: "#7d486c" }}
        />


    
            <img
              src={props.product["img"]}
              alt="element"
              height= {window.innerHeight > 850? "400" : props.product.list.length<=3 ? "230" : "180" }

              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                maxWidth: "100"
              }}
            />
      




        <CardContent style={{ backgroundColor: "#4d70a7",
  
       }}>
          {console.log(window.innerHeight)}
          {props.product.price=== "10 + 3" 
          || props.product.price=== "Buy: 12 + 2" 
          || props.product.price==="Balsami Presto 300 ml" 
          || props.product.price==="Shampoo Tonico 300 ml"
          || props.product.price==="Lumina Shampoo 300 ml"
          || props.product.price==="Due Faccetta Massimo"
          || props.product.price==="Arium Kit 1 or Arium Kit 2"
          || props.product.price=== "$28.99"
          || props.product.price=== "$39.99"
          || props.product.price=== "$49.99"
          || props.product.price=== "$4.75"
          ? null : (
            <h1
              style={{
                color: "#e2d5d9",
                fontSize: window.innerWidth > 450 && props.product.list.length<=3? "40px" : "20px",
                textAlign: "center",
                marginTop: "-8px",
                pointerEvents: "none",
              }}
            >
              Get:
            </h1>
          )}
          <Typography style={{ color: "#e2d5d9" }}>
            {props.product.list.map((item) => {
              return (
                <div
                  style={{
                    textAlign: "center",
                    margin: "-5px",
                    fontSize: window.innerWidth > 450 && props.product.list.length<=3 ? "30px" : 
                    
                    
                    window.innerHeight< 850? "14px" : "18px"
                    ,
                  }}
                >
                <ChangeHistoryIcon
                  style={{
                    color: "#7d486c",
                    width: window.innerWidth > 450 ? 15 : 10,
                  }}
                /> 
                {" "}
                {item}
                </div>
              );
            })}
          </Typography>
          <br></br>
          <div
            style={{
              border: "dashed  #7d486c",
              borderRadius: "10px"
            }}
          >
            <div
              style={{
                marginTop: 5,
                marginBottom: 5,
                paddingLeft: -2,
                paddingRight: -2,
                color: "#e2d5d9",
                textAlign: "center",
                fontSize: window.innerWidth > 450 ? "30px" : 
                
                props.product.list.length>5 ? '14px' : '18px' 
                
                ,
                pointerEvents: "none",
              }}
            >
              {props.product.price.includes("$") 
              // || props.product.price=== "12 + 2" 
              // || props.product.price=== "12 + 3"
              // || props.product.price==="Balsami Presto 300 ml" 
              // || props.product.price==="Shampoo Tonico 300 ml"
              // || props.product.price==="Lumina Shampoo 300 ml"
              // || props.product.price==="Due Faccetta Massimo"
              // || props.product.price==="Arium Kit 1 or Arium Kit 2"
                ? "Only: "
                : null}

              <span style={{ fontWeight: "900" }}>{props.product.price}</span>
            </div>
          </div>
          <div style={{height: window.innerHeight<650 ? "5px" : "15px" }}></div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              pointerEvents: "none",
             
            }}
          >
            {
         
            props.product.savings || 
            // props.product.price === "10 + 3 Free" || 
            props.product.price ==="$65.19" ||
            props.product.price ==="$62.84"
            
            ?
            null : (
              <div
                style={{
                  margin: "auto",
                  color: "#e2b2c9",
                  fontSize: window.innerWidth > 450 ? "18px" : "12px",
                  pointerEvents: "none",
                }}
              >
              {props.product.value}
              </div>
            )}


{!props.product.savings? 
            null : (
              <div
                style={{
                  margin: "auto",
                  color: "#e2b2c9",
                  fontSize: window.innerWidth > 450 ? "18px" : "12px",
                  pointerEvents: "none",
                }}
              >
                Savings: {props.product.savings}
              </div>
            )}
            <div style={{ marginLeft: "auto", pointerEvents: "auto" }}>
              <Link href="https://www.facebook.com/TecItalyNY/" target="_blank">
                <FacebookIcon
                  style={{
                    color: "#e2d5d9",
                   
                    width: window.innerWidth > 450 ? 40 : 25,
                  }}
                />
              </Link>
              <Link
                href="https://www.instagram.com/tecitalyny/channel/"
                target="_blank"
              >
                <InstagramIcon
                  style={{
                    color: "#e2d5d9",
                    width: window.innerWidth > 450 ? 40 : 25,
                  }}
                />
              </Link>
            </div>
          </div>
        </CardContent>
      </Card>
 
    </div>
</div>
  );
}
