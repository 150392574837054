import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "./AppContext";
import Dimension from './Dimension'
import Footer from "./Footer";

import Slides from './Slides'


  const dimesionObj =[
    {
      name: "Professional",
    path: "/professional",
    foto: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/dimensionimages%2Fprocole.png?alt=media&token=c3b816bf-b22f-41e5-9bc3-537fb7a8019d"
    
    },

    {
      name: "Omni Restore",
    path: "/omnirestore",
    foto: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/dimensionimages%2Fonmicole.png?alt=media&token=6d623c6a-185b-4bfc-b5aa-4e8f6455ec69"
    
    },



    {
      name: "Blonde Plex",
    path: "/blondeplex",
    foto: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/dimensionimages%2FBLONDEPLEXCOLE.png?alt=media&token=50dee579-a0a0-4cde-8506-6044c439ff6b"
    
    },
    
    {
      name: "Color Care",
    path: "/colorcare",
    foto: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/dimensionimages%2Fcolorcole.png?alt=media&token=f5858b30-c241-4875-8c29-7a2b079d8d74"
    
    },
    {
      name: "Repair",
    path: "/repair",
    foto: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/dimensionimages%2Frepair.png?alt=media&token=29a37a9f-482b-4f8a-8793-54c0cdc94cf7"
    
    },
    {
      name: "Scalp Solution",
    path: "/scalpsolution",
    foto: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/dimensionimages%2FSCALPCOLE.png?alt=media&token=7af7adc2-696d-4d43-bf3e-863b0574dc62"
    
    },
    {
      name: "Moisture",
    path: "/moisture",
    foto: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/dimensionimages%2Fmoistcole.png?alt=media&token=871cc325-4832-42f8-ae5f-d3545893f73a"
    
    },
    {
      name: "Styling",
    path: "/styling",
    foto: "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/dimensionimages%2Fstyle%20cole.png?alt=media&token=40e0dbcb-238b-4d1a-aac9-5d395948f691"
    
    }
    ]


    const titlesEng = [
      "Discover the New Families"
    ];
    
    const titles = [
      "Descubre las Familias"
    ];
const Home = () => {

  const langContext = useContext(AppContext);
  const [lang, setLang] = useState(langContext.engEsp); 

  useEffect(() => {
    console.log(langContext.engEsp);
    setLang(lang === "falseee" ? "trueee" : "falseee");
  }, [langContext.engEsp]);



  const chooseLang = () => {
    if (chooselll(langContext.engEsp)) {
      const x = titlesEng;
      return x;
    } else {
      const x = titles;
      return x;
    }
  };

  const chooselll = (x) => {
    if (x === "trueee") {
      return true;
    } else if (x === "falseee");
    {
      return false;
    }
  };


  



    return (
      <div  >
        <div  >
  
<span style={{
minHeight: "900px"
}}>

<Slides/>
</span>
<div 
 
      style={{  position: "absolute",
                marginTop: "10px",
                left: "50%",
                transform: "translate(-50%)",  zIndex: 0}}
          >



<h3 style={{
    textAlign: "center",
    backgroundColor: "#182028",
    borderRadius: 10,
    marginTop: "70px",
    // position: "absolute",
    // top: "1000px",
    // left: "50%",
    // fontFamily: "Barlow",
    // fontSize: window.innerWidth<1100 ? "2vh" : "5vh",
    color: "white",
    // // transform: "translate(-90%, -50%)",
    textShadow: "2px 2px 4px black", 
}}>
{chooseLang()[0]}



</h3>

            
   <div
                style={{
                  marginTop:"-5px",
                  margin: "auto",

                  display: "inline-flex",
                  justifyContent: "space-around",
                  flexWrap: "wrap",
                }}
              >
   {dimesionObj.map((dim, )=>{
    return(
      <div  style={{ maxWidth: "1100px" , position: "relative"}}>
      <div className="w3-row padding-recipeCards"  id="about">
    <Dimension 
    item= {dim}
    
    
    />
    
    </div>
       </div>
    
    )

          
           
    
    
   
   
   })}
    </div>
              </div>




          </div>


<Footer/>
          
        </div>
    );
}

export default Home;
