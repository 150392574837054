import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../src/AppContext";
import { Link } from "@material-ui/core";

import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import "../src/aboutStylesheet.css"
import Flip from 'react-reveal/Flip';

import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

const obj=[ 


  [   
    "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/BG%2FFACEBOOK%20LIVE%20BANNER.jpg?alt=media&token=37c61208-964f-4d65-b49c-f8460d3694a6",
    [
        " ",
        " "
    
       ],
       "https://www.facebook.com/TecItalyNorthEast/live_videos/"
    
  ],








  [   
    "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/BG%2FNEW%20IMAGE%202021.jpg?alt=media&token=ed6cde67-642d-4ddc-bf8e-a096c8b50ad8",
    [
        "",
        ""
    
       ],
      //  "/colorchart"
    
],

[   
    "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/BG%2FBanner%20nueva%20carta%20de%20color.jpg?alt=media&token=63ee7ddc-f5a7-4194-946f-841debca17c4",
    [
        "Browse the color chart",
        "Navega la carta de color"
    
       ],
       "/colorchart"
    
],



[   
    "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/BG%2Fbanneer%20New%20Designer%20Color.jpg?alt=media&token=184b8c8c-54da-4716-aed8-72f70034f957",
    [
        "",
        ""
    
       ],
       "/designercolor"
    
],

[
  "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/BG%2FOMNI%20RESTORE%20BANNER.jpg?alt=media&token=8bbfcdbc-99ab-40c1-87e2-3160611c4e6d",
  [
    "",
    ""

   ],
   "/omnirestore"
],

[   
    "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/BG%2Fblondeplex%20banner.jpg?alt=media&token=46692870-cbf7-47ab-a4ff-506711116e6e",
    [
        "Blonde Plex is here",
        "Blonde Plex esta aqui"
    
       ],
       "/blondeplex"
    
],

// [   
// "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/BG%2Flumina%20silver%20banner.jpg?alt=media&token=b81c3658-de7e-468d-ac7f-fc5f1208952c",
// [
//     "Lumina Silver",
//     "Lumina Silver"

//    ],
//    "/luminasilvershampoo"

// ],

// [   
//     "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/BG%2Fserum%20plex%20banner.jpg?alt=media&token=8975d49a-1caf-4ad8-9d1c-368291ee6176",
//     [
//         "Got Serum Plex?",
//         "¿Tienes Serum Plex?"
    
//        ],
//        "/serumplex"
    
// ],

// [   
//   "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/BG%2Fred%20curly%20banner.jpg?alt=media&token=62d9f64f-834d-4298-8f7f-c4c67018cee7",
//   [
//     "Extend the life of your color",
//       "Prolonga la vida de tu color"
      
  
//      ] ,
//      "/colordimension"
  
// ],

[   
      "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/BG%2FSSSS%20banner.jpg?alt=media&token=7eecfabb-53d3-4d37-8672-bac4f762f7d9",
      [
        "Extra shine",
            "Brillo extra"
        
           ],
           "/silksystemshineaerosol"
        
],

// [   
//           "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/BG%2Fluminaforza%20banner.jpg?alt=media&token=9825fee0-5ec9-454e-86cf-e940d02d6903",
//             [
//               "Color treatments",
//                 "Tratamientos de color"
            
//                ],
//                "/luminaforzacolore"
            
// ],


// [   
//           "https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/BG%2Fluminashapoobanner.jpg?alt=media&token=834ef464-81fe-420c-8dc6-334e8a33ed63",
//           [
//             "Exceptionally blonde",
//                 "Excepcionalmente rubio"
            
//                ],
//                "/luminashampoo"
            
// ]

]


const properties = {
  arrows: true,
  // indicators: true,
  pauseOnHover: false,
  canSwipe: true,
  duration: 3000
};

const Slides = () => {
  const classes = useStyles();
  const [dimesinoW, setDimensionW] = useState(window.innerWidth);
  const langContext = useContext(AppContext);
  const [titlesLang, setTitlesLang] = useState(0);

  const chooseLang = () => {
    if (chooselll(langContext.engEsp)) {
      setTitlesLang(0);
    
    } else {
      setTitlesLang(1);
    }
  };

  const chooselll = (x) => {
    if (x === "trueee") {
      return true;
    } else if (x === "falseee");
    {
      return false;
    }
  };

  useEffect(() => {
    chooseLang();
  }, [langContext.engEsp]);

  const updateWidthAndHeight = () => {
    setDimensionW(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  });

  return (
    <div>

<header
 className="w3-display-container w3-content w3-wide "
  style={{maxWidth: "3000px",}} id="home" >
   
   <div className="slide-container">
      <Fade {...properties}>

  {obj.map(  (item) => {
    return (
      <span>
        <img
          className="slide-container"
          key={item[0]}
          src={item[0]}
          style={{opacity: 1,
          maxHeight: window.innerHeight<1400 ? "90vh": "90vh",
          maxWidth: window.innerWidth<800 ? "100vw": "100vw" 
        
        }}
          />
  
          
            <h1
              style={{
                textAlign: "center",
                position: "absolute",
                top: dimesinoW < 680 ? "40%" : "50%",
                left: "50%",
                fontFamily: "Barlow",
                fontSize: window.innerWidth<1100 ? "4vh" : "10vh",
                color: "white",
                transform: "translate(-50%, -50%)",
                textShadow: "2px 2px 4px black", 
              }}
            >
               <Flip left  >
                   {item[1][titlesLang]}
               </Flip>




{item[1][titlesLang]==="" ? null:  
               <Link href= {item[2]}
              
               >    

            <div>

<div
        
        style={{
          border: "2px solid white",
      backgroundColor: "transparent",
    borderRadius: 12,
    boxShadow: "2px 2px 4px black", 
    // fontSize: "16px",
    width: "auto",
    "&:hover": {
      color: "#8cb1ab",

      textDecoration: "none",
    },
    
  }}
        >


          
       <div
       
      
       style={{
        "&:hover": {
          color: "#8cb1ab",
    
          textDecoration: "none",
        },
        
        fontFamily: "Barlow",
        fontSize: window.innerWidth<1100 ? "2vh" : "5vh",
        color: "white",
   
      }}
       >

         
 {
 
 
 titlesLang===0 ? "Enter" : "Entrar" }
       </div>
     
        </div>
        </div> 
            
          </Link>}

            </h1>
            

          
        </span>
        
    )
  })}
     
  
      </Fade>
      </div>
  

   </header>

   
    </div>
    
  );
}

export default Slides;