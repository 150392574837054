import React, { useState, useContext, useEffect } from "react";
import { useSpring, animated } from "react-spring";
import { AppContext } from "../src/AppContext";
import Promo from '../src/PromoFlash'
import Flip from 'react-reveal/Flip';
import "../src/aboutStylesheet.css"
import ImageFadeIn from "react-image-fade-in"




const promosArray = [


  


  
   
  
  
  

    {
        title: "$4.75 Color Tubes",
        list: [
        "Buy: 20 Designer Color or more.", 
        "For: $4.75 Each",
       
         ],
        price: "$4.75 ",
        value: <span> 
          <li>Buy 20 Designer Color or more. </li>
          <li>& Only for Natural, Double Ashes and Nacres shades</li>
          </span>,
        img: require("../src/promos/fotos/colordeal.png"),
      },
  
    
    
  
  
    
  
    
    {
      title: "5 + 1",
      list: [
      "Due Faccetta massimo.", 
      "Lumina Shampoo 300 ml.",
      "Lumina Shampoo 1 lt.",
      "Balsami Totale 300 ml.",
      "Shampoo Tonico 300 ml.",
      "Shampoo Totale 300 ml.",
      "Riccioli Leave In 300 ml.",
      "Gellini 300 ml.",
      "Amino Keratin 280 gr.",
      "Silk System Shine 300 ml.",
      "Silk System Shine 125 ml. (No caps available)"
     ],
      price: "5+1",
      value: <span> 
        <li>Buy 5 bottles of the same product </li>
        <li>& Get 1 bottles free</li>
        </span>,
      img: require("../src/promos/fotos/5 + 1 wetline.png"),
    },

    // {
    //   title: "Balsami Presto 2 Lt. 50% Off",
    //   list: [
    //   "Buy Balsami Presto 2 lt. At 50% Off.", 
      
    //    ],
    //   price: "50% Discount",
    
    //   img: require("../src/promos/fotos/bp 2lt.png"),
    // },
  
  
  
   

  
  
  ];


















const FlashDeals = () => {


// debugger



  const props = useSpring({ opacity: 1, from: { opacity: 0 } });
  const [dimesinoW, setDimensionW] = useState(window.innerWidth);
  const langContext = useContext(AppContext);
  const [titlesLang, setTitlesLang] = useState(0);

  const chooseLang = () => {
    if (chooselll(langContext.engEsp)) {
      setTitlesLang(0);
      // console.log("titles", titles);
    } else {
      setTitlesLang(1);
    }
  };

  const chooselll = (x) => {
    if (x === "trueee") {
      return true;
    } else if (x === "falseee");
    {
      return false;
    }
  };

  useEffect(() => {
    console.log(langContext.engEsp);
    chooseLang();
    // setLang(lang === "falseee" ? "trueee" : "falseee");
  }, [langContext.engEsp]);

  const updateWidthAndHeight = () => {
    setDimensionW(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  });








  return (
    <div>

<header
        className="w3-display-container w3-content w3-wide"
        style={{
          maxWidth: "3000px",
          // // maxHeight: "1000px"
          // zIndex:-10
        }}
        id="home"
      >
        <animated.div style={props}>
          <ImageFadeIn
            className="w3-image"
            src={"https://firebasestorage.googleapis.com/v0/b/tecmktg-363f7.appspot.com/o/BG%2Fjune%20flashdeals%20last%20week.jpg?alt=media&token=fae73164-b304-4fb9-8dad-026d0600546b"}
     
          />

          <h1
            style={{
              textAlign: "center",
              position: "absolute",
              top: dimesinoW < 680 ? "40%" : "50%",
              left: "50%",
              fontFamily: 'Permanent Marker, cursive',
              fontSize: dimesinoW < 750 ? "50px" : "120px",
              color: "#e76f51",
              transform: "translate(-50%, -50%)",
              textShadow: "4px 4px 4px white",
             
            }}
          >
            <Flip left>
        Flash Deals
        </Flip>
          </h1>
          </animated.div>
      <div 
      className="w3-content"
      style={{  position: "absolute",
                marginTop: "10px",
                left: "50%",
                transform: "translate(-50%)",  zIndex: "1" }}
          >
            <div>
            
          <div
                style={{
 
                  margin: "auto",

                  display: "inline-flex",
                  justifyContent: "space-around",
                  flexWrap: "wrap",
                }}
              >
                <h3 style={{
                 color: "#e76f51",
                 fontFamily:"Barlow"
                }}>
                  
                     Promotions available until Friday July 2nd 2021</h3>


   {promosArray.map((prod, i)=>{
    return(
      <div  style={{ maxWidth: "1100px" , position: "relative"}}>
      <div className="w3-row padding-recipeCards"  id="about">
    <Promo product={prod}  
    // productAll={productsArray[window.location.pathname.replace("/", "")]["products"]} 
    index={i}
    dimension={window.location.pathname.replace("/", "")}
    
    
    />
    
    </div>
       </div>
    
    )

          
           
    
    
   
   
   })}
   
              </div>
    </div>
   </div>





   </header>
    </div>
  );
}

export default FlashDeals;
